<template>
  <div class="newsList">
    <p class="pageTitle">{{isEdit?'修改':'添加'}}新闻</p>
    <Button class="defaultBtn" id="rightBtn" to="/admin/news_list">返回新闻列表</Button>
    <div class="pageContent">
      <Form :model="form" :rules="rules" ref="newsForm" label-position="left" :label-width="110">
        <Row :gutter="20">
          <Col span="14">
            <FormItem label="新闻标题" prop="title">
              <Input v-model="form.title" clearable></Input>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="20">
          <Col span="7">
            <FormItem label="新闻分类" prop="type">
              <Select v-model="form.type" clearable>
                <Option value="大奖头条">大奖头条</Option>
                <Option value="官方动态">官方动态</Option>
                <Option value="媒体报道">媒体报道</Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="20">
          <Col span="7">
            <FormItem label="新闻日期" prop="startDate">
              <DatePicker :clearable="false" :editable="false" format="yyyy-MM-dd" v-model="form.startDate" @on-change="setDate"/>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="20">
          <Col span="24">
            <FormItem label="新闻封面" prop="cover">
              <Upload
                ref="coverFile"
                :action="imgApi"
                :headers="{Authorization: token}"
                name="picture"
                :data="{applyType: 'COVER'}"
                :show-upload-list="false"
                :on-success="handleSuccess"
                :format="['jpg','jpeg', 'png']"
                :max-size="10240"
                :before-upload="handleBeforeUpload"
                :on-format-error="handleFormatError"
                :on-exceeded-size="handleMaxSize"
                :on-progress="handleProgress"
                accept="image/jpg, image/jpeg, image/png">
                <Button class="uploadBtn">{{form.cover?'重新上传':'选择文件'}}</Button>
                <span>文件格式：“.JPG”，“.PNG”；文件大小：不大于10M</span>
              </Upload>
              <div v-if="coverList.length>0">
                <div class="imgItem" v-for="(item, i) in coverList" :key="i">
                  <template v-if="item.status == 'finished' || item.status==null">
                    <img class="img" :src="getImageUrl(item.path, 'square-small')" alt="">
                  </template>
                  <template v-else>
                    <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
                  </template>
                </div>
              </div>
            </FormItem>
          </Col>
          <Col span="24" v-if="form.type=='媒体报道'">
            <FormItem label="新闻链接" prop="url">
              <Input v-model="form.url" clearable></Input>
            </FormItem>
          </Col>
          <Col span="24" v-else>
            <FormItem label="新闻内容" prop="content">
              <Editor :content="form.content" @updateContent="updateContent" />
            </FormItem>
          </Col>
          <Col span="24">
            <FormItem label="显示/隐藏">
              <RadioGroup v-model="form.isHide">
                <Radio :label="0">显示</Radio>
                <Radio :label="1">隐藏</Radio>
              </RadioGroup>
            </FormItem>
          </Col>
          <Col span="24">
            <FormItem label="是否置顶">
              <RadioGroup v-model="form.isTop">
                <Radio :label="1">是</Radio>
                <Radio :label="0">否</Radio>
              </RadioGroup>
            </FormItem>
          </Col>
        </Row>  
        <div class="btnWrap">
          <Button class="defaultBtn" type="primary" :loading="submitting"  @click="submit">提交</Button>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import Editor from "@/components/Editor.vue";
import NewsService from "@/api/news";
import { baseUrl } from "@/config/env";
export default {
  components: {
    Editor
  },
  data() {
    return {
      form: { isHide: 0, content: "", isTop: 0, cover: "" },
      rules: {
        title: [
          { required: true, message: "请输入新闻标题", trigger: "blur,change" }
        ],
        type: [
          { required: true, message: "请选择新闻分类", trigger: "blur,change" }
        ],
        startDate: [
          { required: true, message: "请选择新闻日期",
            validator: (rule, value) => {
              if (!value) {
                return false;
              }
              return true;
            }, trigger: "blur,change" }
        ],
        // cover: [
        //   { required: true, message: "请上传新闻封面", trigger: "blur,change" }
        // ],
        content: [
          { required: true, message: "请输入新闻内容", trigger: "blur,change" }
        ]
      },

      imgApi: `${baseUrl}picture/upload`,
      token: this.$store.getters.token,
      coverList: [],

      submitting: false,
      isEdit: false
    };
  },
  mounted() {
    let id = this.$route.query.id;
    if (id) {
      this.isEdit = true;

      this.getInfo(id);
    } else {
      this.coverList = [];
      this.$refs.coverFile.fileList = this.coverList;
    }
  },
  methods: {
    handleBeforeUpload() {
      this.coverList = [];
      this.$refs.coverFile.fileList = this.coverList;
      return true;
    },
    handleProgress(event, file, fileList) {
      this.coverList = fileList;
      // 调用监听 上传进度 的事件
      event.target.onprogress = event => {
        let uploadPercent = parseFloat(
          (event.loaded / event.total * 100).toFixed(2)
        );
        file.showProgress = true;
        file.percentage = uploadPercent;
      };
    },
    handleSuccess(res, file) {
      if (res.errorNo) {
        this.$Message.error(res.errorMessage);
      } else {
        let data = res.data;
        if (data.path) {
          file.path = data.path;
          this.form.cover = data.path;
        }
        this.$forceUpdate();
      }
    },
    handleFormatError(file) {
      this.$Message.error({
        content: `文件${file.name}格式不正确，请上传jpg,jpeg格式的图片。`,
        duration: 5
      });
    },
    handleMaxSize(file) {
      this.$Message.error({
        content: `文件${file.name}太大，上传图片大小不能超过10M。`,
        duration: 5
      });
    },
    // 更新新闻内容
    updateContent(data) {
      this.form.content = data;
      this.$refs.newsForm.validateField("content");
    },
    // 提交
    submit() {
      this.$refs.newsForm.validate(valid => {
        if (valid) {
          this.submitting = true;
          NewsService.saveNews(this.form)
            .then(res => {
              if (res.errorNo == 0) {
                let text = "",
                  _this = this;
                if (this.form.id) {
                  text = "新闻修改成功！点击确认按钮将跳转到新闻列表页。";
                } else {
                  text = "新闻发布成功！点击确认按钮将跳转到新闻列表页。";
                }
                this.$Modal.confirm({
                  title: "提示",
                  content: text,
                  onOk: function() {
                    _this.$router.push("/admin/news_list");
                  },
                  onCancel: function() {
                    if (!_this.form.id) {
                      _this.$refs.newsForm.resetFields();
                    }
                  }
                });
              } else {
                this.$Message.error(res.errorMessage);
              }
              this.submitting = false;
            })
            .catch(() => {
              this.$Message.error("连接服务器失败，请稍后再试");
              this.submitting = false;
            });
        } else {
          this.$Message.error("信息未正确填写完整");
        }
      });
    },
    // 获取列表
    getInfo(id) {
      NewsService.getNewsInfo(id)
        .then(res => {
          if (res.errorNo == 0) {
            this.form = res.data;
            if (this.form.cover) {
              this.coverList = [{ path: this.form.cover, status: "finished" }];
            } else {
              this.coverList = [];
            }
            this.$refs.coverFile.fileList = this.coverList;
          } else {
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    },
    setDate(val) {
      this.form.startDate = val;
    },
  }
};
</script>

<style lang="scss" scoped>
.newsList {
  position: relative;

  #rightBtn {
    position: absolute;
    right: 0;
    top: 10px;
    margin: 0;
  }
  .uploadBtn {
    background: #277a44;
    color: #ffffff;
    margin-right: 10px;
  }
  .imgItem {
    position: relative;
    top: 0;
    width: 80px;
    height: 80px;
    display: inline-block;
    margin: 10px 10px 0 0;
    background: rgba($color: #000000, $alpha: 0.1);
    vertical-align: top;
    .img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .btnWrap {
    text-align: center;
    padding: 30px 0;
  }
}
</style>