<template>
  <div class="distribution">
    <p class="pageTitle">项目管理</p>
    <router-link class="href" :class="{'hrefActive': getStage=='FIRST'}" to="/admin/distribution/result?stage=FIRST">初审项目分配结果</router-link>
    <router-link class="href" :class="{'hrefActive': getStage=='FINAL'}" to="/admin/distribution/result?stage=FINAL">终审项目分配结果</router-link>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  computed: {
    getStage(){
      return this.$route.query.stage || 'FIRST'
    }
  }
}
</script>

<style lang="scss" scoped>
.distribution{
  .href{
    color: #515a6e;
    padding: 5px 30px 10px;
    display: inline-block;
  }
  .hrefActive, .href:hover{
    color: #277a44;
    font-weight: bold;
    border-bottom: 2px solid #277a44;
  }
}
</style>