<template>
  <div>
    <TopTitle />
    <div class="navWrap myBar">
      <Menu ref="menus" width="189px" accordion :open-names="[openName]" :active-name="activeName">
        <Submenu name="1" v-if="userInfo.accountType=='CONTESTSUPERADMIN' || userInfo.accountType=='CONTESTADMIN' && (userInfo.competitionUser.permissions.indexOf('check')>=0 || userInfo.competitionUser.permissions.indexOf('super')>=0)">
          <template slot="title">预审管理</template>
          <MenuItem to="/admin/check_basic" name="CheckBasic"><span class="subMenuFlag">·</span>报名审核</MenuItem>
          <MenuItem to="/admin/check_project" name="CheckProject"><span class="subMenuFlag">·</span>项目审核</MenuItem>
        </Submenu>
        <Submenu name="3" v-if="userInfo.accountType=='CONTESTSUPERADMIN' || userInfo.accountType=='CONTESTADMIN' && (userInfo.competitionUser.permissions.indexOf('review')>=0 || userInfo.competitionUser.permissions.indexOf('super')>=0)">
          <template slot="title">评审管理</template>
          <MenuItem to="/admin/preliminary_distribution" name="PreliminaryDistribution"><span class="subMenuFlag">·</span>初审项目分配</MenuItem>
          <MenuItem to="/admin/preliminary_result" name="PreliminaryResult"><span class="subMenuFlag">·</span>初审结果管理</MenuItem>
          <MenuItem to="/admin/final_distribution" name="FinalDistribution"><span class="subMenuFlag">·</span>终审项目分配</MenuItem>
          <MenuItem to="/admin/final_result" name="FinalResult"><span class="subMenuFlag">·</span>终审结果管理</MenuItem>
          <MenuItem to="/admin/publish_result" name="PublishResult"><span class="subMenuFlag">·</span>获奖项目发布</MenuItem>
        </Submenu>
        <MenuItem to="/admin/judge_list" name="JudgeList" v-if="userInfo.accountType=='CONTESTSUPERADMIN' || userInfo.accountType=='CONTESTADMIN' && (userInfo.competitionUser.permissions.indexOf('judge')>=0 || userInfo.competitionUser.permissions.indexOf('super')>=0)">
          评委管理
        </MenuItem>
        <Submenu name="4" v-if="userInfo.accountType=='CONTESTSUPERADMIN' || userInfo.accountType=='CONTESTADMIN' && (userInfo.competitionUser.permissions.indexOf('user')>=0 || userInfo.competitionUser.permissions.indexOf('super')>=0)">
          <template slot="title">信息管理</template>
          <MenuItem to="/admin/user_list" name="UserList"><span class="subMenuFlag">·</span>用户管理</MenuItem>
          <MenuItem to="/admin/distribution/result?stage=FIRST" name="Distribution"><span class="subMenuFlag">·</span>项目管理</MenuItem>
          <MenuItem to="/admin/set_date" name="SetDate"><span class="subMenuFlag">·</span>报名设置</MenuItem>
          <MenuItem to="/admin/export/basic" name="Export"><span class="subMenuFlag">·</span>数据导出</MenuItem>
        </Submenu>
        <!-- <MenuItem to="/admin/award_service" name="AwardService" v-if="userInfo.accountType=='CONTESTSUPERADMIN' || userInfo.accountType=='CONTESTADMIN' && (userInfo.competitionUser.permissions.indexOf('after')>=0 || userInfo.competitionUser.permissions.indexOf('super')>=0)">
          <Icon type="ios-cloud" />
          赛后服务
        </MenuItem> -->
        <MenuItem to="/admin/news_list" name="NewsList" v-if="userInfo.accountType=='CONTESTSUPERADMIN' || userInfo.accountType=='CONTESTADMIN' && (userInfo.competitionUser.permissions.indexOf('news')>=0 || userInfo.competitionUser.permissions.indexOf('super')>=0)">
          新闻管理
        </MenuItem>
      </Menu>
    </div>
    <div class="pageWrap">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
      <!-- <router-view></router-view> -->
    </div>
  </div>
</template>
<script>
import TopTitle from "@/components/TopTitle.vue";
export default {
  components: {
    TopTitle
  },
  data() {
    return {
      userInfo: this.$store.getters.userInfo || {},
      openName: '',
      activeName: ''
    };
  },
  mounted(){
    this.updateMenu(this.$route.name)
  },
  methods:{
    updateMenu(name){
      this.activeName = name
      if(
        name=='CheckBasic' || 
        name=='BasicInfo' || 
        name=='CheckProject' || 
        name=='ProjectInfo'
      ){
        this.openName = '1'
      }else if(
        name=='PreliminaryDistribution' || 
        name=='PreliminaryResult' || 
        name=='FinalDistribution' || 
        name=='FinalResult' || 
        name=='PublishResult' || 
        name=='DistributionResult'
      ){
        this.openName = '3'
      }else if(
        name=='UserList' || 
        name=='Result' || 
        name=='SetDate' ||
        name=='ExportBasic' ||
        name=='ExportProject' ||
        name=='ExportResult'
      ){
        this.openName = '4'
        if(name=='Result'){
          this.activeName = 'Distribution'
        }else if(name=='ExportBasic' ||
          name=='ExportProject' ||
          name=='ExportResult'
        ){
          this.activeName = 'Export'
        }
      }else{
        this.openName = ''
      }
      this.$nextTick(()=>{
        this.$refs.menus.updateActiveName();
        this.$refs.menus.updateOpened();
      })
    }
  },
  watch:{
    $route(to){
      this.updateMenu(to.name);
    }
  },
};
</script>
<style lang="scss" scoped>
.navWrap {
  position: fixed;
  top: 110px;
  left: 0;
  bottom: 0;
  width: 190px;
  border-right: 1px solid #dcdee2;
  overflow-y: auto;
  overflow-x: hidden;
  background: #253A25;

  .subMenuFlag{
    font-size: 20px;
    line-height: 14px;
    padding-right: 10px;
    vertical-align: middle;
  }
}
.pageWrap {
  position: fixed;
  left: 190px;
  top: 110px;
  bottom: 0;
  right: 0;
  overflow: auto;
  padding: 15px;
  font-size: 16px;
}
</style>
