<template>
<div class="judgeList">
  <p class="pageTitle">评委管理</p>
  <Button class="defaultBtn" id="rightBtn" @click="newJudge">新增评委</Button>
  <div class="pageContent">
    <Table :data="list" :columns="columns" :loading="loading" stripe></Table>
    <div v-if="total>0" class="pageWrap">
      <Page :total="total" :page-size="30" :current="currentPage" show-total @on-change="changePage"></Page>
    </div> 
  </div>
  <Modal v-model="isNew" title="新增评委" :mask-closable="false" footer-hide>
    <NewJudge v-if="isNew" :hide="newJudge" @Update="updateInfo" />
  </Modal>
  <Modal v-model="isEdit" title="修改评委信息" :mask-closable="false" footer-hide>
    <EditJudge v-if="isEdit" :hide="editJudge" :index="index" :info="info" @Update="updateInfo" />
  </Modal>
  <Modal v-model="isReset" title="重置密码" :mask-closable="false" footer-hide>
    <ResetPassword v-if="isReset" :hide="resetPassword" :info="info" />
  </Modal>
</div>
</template>

<script>
import JudgeService from "@/api/judge";
import UserService from "@/api/user";
import NewJudge from '@/components/judge/NewJudge.vue'
import EditJudge from '@/components/judge/EditJudge.vue'
import ResetPassword from '@/components/judge/ResetPassword.vue'
export default {
  components: { NewJudge, EditJudge, ResetPassword },
  data(){
    return{
      loading: false,
      list: [],
      total: 0,
      currentPage: 1,
      columns: [
        {
          title: "序号",
          align: 'center',
          key: "id"
        },
        {
          title: "头像",
          align: 'center',
          key: "cover",
          render: (h, params) => {
            if(params.row.avatar){
              return h("img", {
                attrs: {
                    src: this.getImageUrl(params.row.avatar, 'square-small')
                },
                style: {
                    width: '50px',
                    height: '50px'
                }
              });
            }else{
              return h("div", '-');
            }
          }
        },
        {
          title: "账号",
          align: 'center',
          key: "loginName"
        },
        {
          title: "姓名",
          align: 'center',
          key: "trueName"
        },
        {
          title: "评委权限",
          align: 'center',
          key: "level",
          render: (h, params) => {
            return h("div", this.getLevelText(params.row.level));
          }
        },
        {
          title: "已分配项目数",
          align: 'center',
          key: "projectCount"
        },
        {
          title: "所属推荐单位",
          width: 120,
          align: 'center',
          key: "pushCompany",
          render: (h, params) => {
            return h("div", params.row.pushCompany||'无');
          }
        },
        {
          title: "任职机构",
          align: 'center',
          key: "company",
          render: (h, params) => {
            return h("div", params.row.company||'-');
          }
        },
        {
          title: "职务",
          align: 'center',
          key: "job",
          render: (h, params) => {
            return h("div", params.row.job||'-');
          }
        },
        {
          title: "操作",
          key: "action",
          align: 'center',
          width: 100,
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    type: "text",
                    size: "small"
                  },
                  class: 'textBtn',
                  on: {
                    click: () => {
                      this.editJudge(params.index, params.row)
                    }
                  }
                },
                "修改信息"
              ),
              h(
                "Button",
                {
                  props: {
                    type: "text",
                    size: "small"
                  },
                  class: 'textBtn',
                  on: {
                    click: () => {
                      this.remove(params.index);
                    }
                  }
                },
                "删除评委"
              ),
              h(
                "Button",
                {
                  props: {
                    type: "text",
                    size: "small"
                  },
                  class: 'textBtn',
                  on: {
                    click: () => {
                      this.resetPassword(params.row);
                    }
                  }
                },
                "重置密码"
              )
            ]);
          }
        }
      ],

      index: null,
      info: {},
      isNew: false,
      isEdit: false,
      isReset: false
    }
  },
  mounted() {
    this.getList();
    // this.list = [
    //   {
    //     id: 1,
    //     loginName: 'liuxiaojia',
    //     name: '刘晓佳',
    //     authType: '初审评委',
    //     pushCompany: '',
    //     company: '',
    //     avatar: 'COMPETITION/IMG/692078840215696.jpg'
    //   }
    // ]
  },
  methods:{
    getLevelText(level){
      if(level == "FIRST"){
        return "初审评委"
      }else if(level == "FINAL"){
        return "终审评委"
      }else{
        return "-"
      }
    },
    // 新增评委
    newJudge(){
      this.isNew = !this.isNew
    },
    // 修改评委信息
    editJudge(i, info){
      if(this.isEdit){
        this.index = null
        this.info = {}
        this.isEdit = false
      }else{
        this.index = i
        this.info = info
        this.isEdit = true
      }
    },
    // 更新
    updateInfo(data){
      let type = data.type
      if(type=='new'){  //新增评委成功
        this.currentPage = 1
        this.getList()  
      }else if(type=='edit'){  //修改信息成功
        this.$set(this.list, data.index, data.info)
      }
    },
    // 删除评委
    remove(i){
      let info = this.list[i], _this = this
      this.$Modal.confirm({
        title: '删除评委',
        content: `评委删除后将无法恢复，您确定要删除账号为“${info.loginName}”的评委吗？`,
        loading: true,
        onOk: function(){
          let id = info.id
          _this.deleteUser(i, id)
        }
      })
    },
    deleteUser(i, id){
      UserService
        .deleteCompetitionUser(id)
        .then(res => {
          if (res.errorNo == 0) {
            // 删除成功后执行
            this.list.splice(i, 1)
            this.total -= 1
            if(this.total == 0){
              this.getList()
            }
            this.$Message.success("评委删除成功");
            this.$Modal.remove()
          } else {
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    },
    // 重置密码
    resetPassword(info){
      if(this.isReset){
        this.info = {}
        this.isReset = false
      }else{
        this.info = info
        this.isReset = true
      }
    },
    // 获取列表
    getList() {
      this.loading = true;
      let condition = {
        page: this.currentPage - 1,
        size: 30,
        sort: 'id,desc',
        'competitionId.eq': 0,
        "userType.eq": "JUDGE"
      };
      JudgeService.getJudges(condition)
        .then(res => {
          if (res.errorNo == 0) {
            this.currentPage == 1 && (this.total = res.totalSize);
            this.list = res.data;
          } else {
            this.$Message.error(res.errorMessage);
          }
          this.loading = false;
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
          this.loading = false;
        });
    },
    changePage(p) {
      this.currentPage = p;
      this.getList();
      document.getElementsByClassName("pageWrap")[0].scrollTop = 0;
    }
  }
}
</script>

<style lang="scss" scoped>
.judgeList{
  position: relative;

  #rightBtn{
    position: absolute;
    right: 0;
    top: 10px;
    margin: 0;
  }
}
</style>