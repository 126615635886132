<template>
  <div class="export">
    <Form :model="form" label-position="right" :label-width="100">
      <p class="note">请选择/输入导出报名信息数据的筛选条件：</p>
      <Row :gutter="20">
        <Col span="8">
          <FormItem label="单位类型">
            <Select v-model="form.applicantType" @on-change="setCertType" clearable>
              <Option value="个人">个人</Option>
              <Option value="院校或科研机构">院校或科研机构</Option>
              <Option value="企业">企业</Option>
            </Select>
          </FormItem>
        </Col>
        <Col span="8">
          <FormItem :label="nameLabel">
            <Input v-model="form.name" clearable></Input>
          </FormItem>
        </Col>
        <Col span="8">
          <FormItem label="推荐单位">
            <Select v-model="form.pushCompany" clearable :disabled="isDisabled">
              <Option :value="p.value" v-for="p in getPushCompany()" :key='p.id'>{{p.label}}</Option>
              <Option value="其他">其他</Option>
            </Select>
          </FormItem>
        </Col>
        <Col span="8">
          <FormItem label="报名日期">
            <DatePicker v-model="dateList[0]" format="yyyy-MM-dd" placeholder="选择日期" style="width:100%"></DatePicker>
          </FormItem>
        </Col>
        <Col span="8">
          <FormItem label="至" :label-width="26">
            <DatePicker v-model="dateList[1]" format="yyyy-MM-dd" placeholder="选择日期" style="width:100%"></DatePicker>
          </FormItem>
        </Col>
        <Col span="8">
          <FormItem label="审核状态">
            <Select v-model="form.status" clearable>
              <Option value="CHECKING">待审核</Option>
              <Option value="SUCCESS">通过审核</Option>
              <Option value="FAILED">未通过审核</Option>
            </Select>
          </FormItem>
        </Col>
      </Row>
      <p class="note">请选择要导出数据的字段：</p>
      <div class="boxWrap">
        <CheckboxGroup v-model="form.key">
          <Row :gutter="20">
            <Col span="6"><Checkbox label="applicantType">参评单位类型</Checkbox></Col>
            <Col span="6"><Checkbox label="name">参评单位名称</Checkbox></Col>
            <Col span="6"><Checkbox label="address">联系地址</Checkbox></Col>
            <Col span="6"><Checkbox label="postcode">邮政编码</Checkbox></Col>
            <Col span="6"><Checkbox label="description">参评单位简介</Checkbox></Col>
            <Col span="6"><Checkbox label="certificateFile">参评证明</Checkbox></Col>
            <Col span="6"><Checkbox label="contactName">联系人</Checkbox></Col>
            <Col span="6"><Checkbox label="contactJob">职务</Checkbox></Col>
            <Col span="6"><Checkbox label="phone">联系电话</Checkbox></Col>
            <Col span="6"><Checkbox label="email">电子邮箱</Checkbox></Col>
            <Col span="6"><Checkbox label="pushCompany">推荐单位</Checkbox></Col>
          </Row>
        </CheckboxGroup>  
      </div>
      <div class="btnWrap">
        <Button class="defaultBtn" type="primary" @click="exportData" :loading="loading">导出数据</Button>
      </div>
    </Form>
  </div>
</template>

<script>
import ExportService from "@/api/exportExcel";
export default {
  data() {
    return {
      userInfo: this.$store.getters.userInfo || {},
      isDisabled: false,
      form: {key: ['applicantType', 'name', 'address']},
      nameLabel: "参评单位名称",
      dateList: [],
      loading: false
    }
  },
  mounted() {
    if(this.userInfo.accountType != "CONTESTSUPERADMIN" && this.userInfo.competitionUser.pushCompany){
      this.form.pushCompany = this.userInfo.competitionUser.pushCompany
      this.isDisabled = true
    }
  },
  methods:{
    // 导出数据
    exportData(){
      // this.$Message.info('功能正在开发中，请耐心等待...')
      let data = Object.assign({}, this.form),
        date = [this.getShortDate(this.dateList[0]), this.getShortDateNextDay(this.dateList[1])]
      if(data.pushCompany == "其他"){
        data.pushCompany = null;
        data.pushCode = "无";
      }
      let serveData = {
        "applicantType.eq": data.applicantType || null,
        "name.contains": data.name || null,
        "pushCompany.eq": data.pushCompany || null,
        "pushCode.eq" : data.pushCode || null,
        "status.eq": data.status || null,
        "createdDate.ge": date[0] || null,
        "createdDate.le": date[1] || null,
        "columns": data.key.join(",")
      }
      // console.log(serveData)
      this.loading = true;
      ExportService.exportRegistrations(serveData).then(res => {
        // console.log(res)
        this.loading = false;
        this.readFileDownload(res, "报名信息_" + this.getShortDate(new Date()) + ".xlsx");
      })
      .catch(() => {
        this.loading = false;
        this.$Message.error("连接服务器失败，请稍后再试");
      });
    },
    getShortDate(date){
      if (date && typeof date != "string") {
        let sYear = date.getFullYear() + "";
        let sMonth =
          date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1 + "";
        let sDate = date.getDate() < 10 ? "0" + date.getDate() : date.getDate() + "";
        let dNew = sYear + "-" + sMonth + "-" + sDate;
        return dNew;
      }
      return null
    },
    getShortDateNextDay(date, key){
      if (date && typeof date != "string") {
        let sYear = date.getFullYear() + "";
        let sMonth =
          date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1 + "";
        let sDate = date.getDate() + 1 < 10 ? "0" + (date.getDate() + 1) : (date.getDate() + 1) + "";
        if(key=='m'){
          return sYear + "-" + sMonth
        }
        return sYear + "-" + sMonth + "-" + sDate;
      }
      return null
    },
    // 根据参评单位类型赋值证明类型
    setCertType(val) {
      if (val == "个人") {
        this.nameLabel = "参评个人姓名";
      } else if (val == "院校或科研机构") {
        this.nameLabel = "参评单位名称";
      } else if (val == "企业") {
        this.nameLabel = "参评单位名称";
      } else {
        this.nameLabel = "参评单位名称";
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.export{
  .note{
    padding: 5px 0 10px;
  }
  .boxWrap{
    padding-left: 30px;
    line-height: 2em;
  }
  .btnWrap{
    text-align: center;
    padding: 40px 0 20px;
  }
}
</style>