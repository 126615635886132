<template>
  <div class="userModal">
    <Form ref="form" :model="form" :rules="rules" :label-width="100">
      <FormItem label="账号" prop="loginName">
        <Input v-model="form.loginName" placeholder="请输入英文或数字" disabled />
      </FormItem>
      <FormItem label="姓名" prop="trueName">
        <Input v-model="form.trueName" placeholder="请输入姓名" />
      </FormItem>
      <FormItem label="所属推荐单位" prop="pushCompany">
        <Select v-model="form.pushCompany" placeholder="请选择所属推荐单位" clearable>
          <Option :value="p.value" v-for="p in getPushCompany()" :key='p.id'>{{p.label}}</Option>
        </Select>
      </FormItem>
      <FormItem label="手机号">
        <Input v-model="form.mobilePhone" placeholder="请输入手机号" />
      </FormItem>
      <FormItem label="邮箱">
        <Input v-model="form.email" placeholder="请输入邮件地址" />
      </FormItem>
      <div class="btnWrap">
        <Button class="submitBtn" :loading="submitting" @click="submit">提交</Button>
        <Button @click="hide">取消</Button>
      </div>
    </Form>
  </div>
</template>

<script>
import UserService from "@/api/user";
export default {
  props: ['hide', 'index', 'info'],
  data(){
    return{
      submitting: false,
      form: {},
      rules: {
        loginName: [
          {required: true, message: '请输入账号', trigger: "blur,change"},
          {
            required: true,
            message: "需要输入英文或数字",
            pattern: /^[0-9A-Za-z]+$/
          }
        ],
        trueName: [{required: true, message: '请输入姓名', trigger: "blur,change"}],
      }
    }
  },
  mounted(){
    this.form = Object.assign({}, this.info)
  },
  methods:{
    submit(){
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitting = true;
          UserService.saveCompetitionUser(this.form)
            .then(res => {
              if (res.errorNo == 0) {
                // 提交成功执行
                this.$Message.success('用户信息修改成功')
                this.$emit('Update', {type: 'edit', index: this.index, info: this.form})
                this.hide()
              } else {
                this.$Message.error(res.errorMessage);
              }
              this.submitting = false;
            })
            .catch(() => {
              this.$Message.error("连接服务器失败，请稍后再试");
              this.submitting = false;
            });
        } else {
          this.$Message.error("信息未正确填写完整");
        }
      });
    }
  }
}
</script>