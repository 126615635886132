<template>
<div class="userList">
  <p class="pageTitle">信息管理</p>
  <div id="rightBtn">
    <Button class="defaultBtn" @click="newUser">新增用户</Button>
    <!-- <Button class="defaultBtn" to="/admin/set_date">报名设置</Button> -->
  </div>
  <div class="pageContent">
    <Table :data="list" :columns="columns" :loading="loading" stripe></Table>
    <div v-if="total>0" class="pageWrap">
      <Page :total="total" :page-size="30" :current="currentPage" show-total @on-change="changePage"></Page>
    </div> 
  </div>
  <Modal v-model="isNew" title="新增用户" :mask-closable="false" footer-hide>
    <NewUser v-if="isNew" :hide="newUser" @Update="updateInfo" />
  </Modal>
  <Modal v-model="isEdit" title="修改用户信息" :mask-closable="false" footer-hide>
    <EditUser v-if="isEdit" :hide="editUser" :index="index" :info="info" @Update="updateInfo" />
  </Modal>
  <Modal v-model="isReset" title="重置密码" :mask-closable="false" footer-hide>
    <ResetPassword v-if="isReset" :hide="resetPassword" :info="info" />
  </Modal>
  <Modal v-model="isPermission" title="设置用户权限" :mask-closable="false" footer-hide>
    <Permission v-if="isPermission" :hide="setPermission" :index="index" :info="info" @Update="updateInfo" />
  </Modal>
</div>
</template>

<script>
import UserService from "@/api/user";
import NewUser from '@/components/user/NewUser.vue'
import EditUser from '@/components/user/EditUser.vue'
import ResetPassword from '@/components/user/ResetPassword.vue'
import Permission from '@/components/user/Permission.vue'
export default {
  components: { NewUser, EditUser, ResetPassword, Permission },
  data(){
    return{
      loading: false,
      list: [],
      total: 0,
      currentPage: 1,
      columns: [
        {
          title: "序号",
          key: "id",
          align: 'center'
        },
        {
          title: "账号",
          key: "loginName",
          align: 'center'
        },
        {
          title: "姓名",
          key: "trueName",
          align: 'center'
        },
        {
          title: "所属推荐单位",
          key: "pushCompany",
          align: 'center',
          render: (h, params) => {
            return h("div", params.row.pushCompany||'无');
          }
        },
        {
          title: "手机号",
          key: "mobilePhone",
          align: 'center',
          render: (h, params) => {
            return h("div", params.row.mobilePhone||'-');
          }
        },
        {
          title: "邮箱",
          key: "email",
          align: 'center',
          render: (h, params) => {
            return h("div", params.row.email||'-');
          }
        },
        {
          title: "操作",
          key: "action",
          align: 'center',
          width: 100,
          render: (h, params) => {
            if(params.row.userType == "CONTESTSUPERADMIN"){
              return h("div", [
                h(
                  "Button",
                  {
                    props: {
                      type: "text",
                      size: "small"
                    },
                    class: 'textBtn',
                    on: {
                      click: () => {
                        this.editUser(params.index, params.row)
                      }
                    }
                  },
                  "修改信息"
                ),
                h(
                  "Button",
                  {
                    props: {
                      type: "text",
                      size: "small"
                    },
                    class: 'textBtn',
                    on: {
                      click: () => {
                        this.resetPassword(params.row);
                      }
                    }
                  },
                  "重置密码"
                )
              ]);
            }else{
              let accountType = this.$store.getters.userInfo.accountType,
                btn1 = h(
                  "Button",
                  {
                    props: {
                      type: "text",
                      size: "small"
                    },
                    class: 'textBtn',
                    on: {
                      click: () => {
                        this.setPermission(params.index, params.row)
                      }
                    }
                  },
                  "设置权限"
                ),
                btn2 = h(
                  "Button",
                  {
                    props: {
                      type: "text",
                      size: "small"
                    },
                    class: 'textBtn',
                    on: {
                      click: () => {
                        this.editUser(params.index, params.row)
                      }
                    }
                  },
                  "修改信息"
                ),
                btn3 = h(
                  "Button",
                  {
                    props: {
                      type: "text",
                      size: "small"
                    },
                    class: 'textBtn',
                    on: {
                      click: () => {
                        this.remove(params.index);
                      }
                    }
                  },
                  "删除用户"
                ),
                btn4 = h(
                  "Button",
                  {
                    props: {
                      type: "text",
                      size: "small"
                    },
                    class: 'textBtn',
                    on: {
                      click: () => {
                        this.resetPassword(params.row);
                      }
                    }
                  },
                  "重置密码"
                )              
              if(accountType=='CONTESTSUPERADMIN'){
                return h("div", [btn1, btn2, btn3, btn4]);
              }else{
                return h("div", [btn2, btn3, btn4]);
              }
            }
          }
        }
      ],

      index: null,
      info: {},
      isNew: false,
      isEdit: false,
      isReset: false,
      isPermission: false
    }
  },
  mounted() {
    this.getList();
  },
  methods:{
    // 设置权限
    setPermission(i, info){
      if(this.isPermission){
        this.index = null
        this.info = {}
        this.isPermission = false
      }else{
        this.index = i
        this.info = info
        this.isPermission = true
      }
    },
    // 新增用户
    newUser(){
      this.isNew = !this.isNew
    },
    // 修改用户信息
    editUser(i, info){
      if(this.isEdit){
        this.index = null
        this.info = {}
        this.isEdit = false
      }else{
        this.index = i
        this.info = info
        this.isEdit = true
      }
    },
    // 更新
    updateInfo(data){
      let type = data.type
      if(type=='new'){  //新增用户成功
        this.currentPage = 1
        this.getList()  
      }else if(type=='edit'){  //修改信息成功
        this.$set(this.list, data.index, data.info)
      }else if(type=='permission'){  //设置权限成功
        this.$set(this.list, data.index, data.info)
      }
    },
    // 删除用户
    remove(i){
      let info = this.list[i], _this = this
      this.$Modal.confirm({
        title: '删除用户',
        content: `用户删除后将无法恢复，您确定要删除账号为“${info.loginName}”的用户吗？`,
        loading: true,
        onOk: function(){
          let id = info.id
          _this.deleteUser(i, id)
        }
      })
    },
    deleteUser(i, id){
      UserService
        .deleteCompetitionUser(id)
        .then(res => {
          if (res.errorNo == 0) {
            // 删除成功后执行
            this.list.splice(i, 1)
            this.total -= 1
            if(this.total == 0){
              this.getList()
            }
            this.$Message.success("用户删除成功");
            this.$Modal.remove()
          } else {
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    },
    // 重置密码
    resetPassword(info){
      if(this.isReset){
        this.info = {}
        this.isReset = false
      }else{
        this.info = info
        this.isReset = true
      }
    },
    // 获取列表
    getList() {
      this.loading = true;
      let condition = {
        page: this.currentPage - 1,
        size: 30,
        sort: 'id,desc',
        "userType.ne": "JUDGE"
      };
      UserService.getCompetitionUser(condition)
        .then(res => {
          if (res.errorNo == 0) {
            this.currentPage == 1 && (this.total = res.totalSize);
            this.list = res.data;
          } else {
            this.$Message.error(res.errorMessage);
          }
          this.loading = false;
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
          this.loading = false;
        });
    },
    changePage(p) {
      this.currentPage = p;
      this.getList();
      document.getElementsByClassName("pageWrap")[0].scrollTop = 0;
    }
  }
}
</script>

<style lang="scss" scoped>
.userList{
  position: relative;

  #rightBtn{
    position: absolute;
    right: 0;
    top: 0px;
    margin: 0;
  }
  .defaultBtn{
    margin-left: 10px !important;
  }
}
</style>