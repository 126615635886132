<template>
  <div class="infoWrap">
    <p class="pageTitle">参评单位信息</p>
    <div class="itemWrap">
      <div class="label">参评单位类型</div>
      <div class="info">{{info.applicantType || '-'}}</div>
    </div>
    <div class="itemWrap">
      <div class="label">参评单位名称</div>
      <div class="info">{{info.name || '-'}}</div>
    </div>
    <div class="itemWrap">
      <div class="label">联系地址</div>
      <div class="info">{{info.address || '-'}}</div>
    </div>
    <div class="itemWrap">
      <div class="label">邮政编码</div>
      <div class="info">{{info.postcode || '-'}}</div>
    </div>
    <div class="itemWrap">
      <div class="label">参评单位简介</div>
      <div class="info">{{info.description || '-'}}</div>
    </div>
    <div class="itemWrap">
      <div class="label">参评证明类型</div>
      <div class="info">{{setCertType}}</div>
    </div>
    <div class="itemWrap">
      <div class="label">参评证明文件</div>
      <div class="info">
        <template v-if="info.certificateFiles.length>0">
          <div class="imgWrap" v-for="p in info.certificateFiles" :key="p.id">
            <a v-if="p.fileType=='FILE'" :href="getFileUrl(p.path)" target="_blank" class="colorGreen">{{p.name}}{{p.format}}</a>
            <img v-else-if="p.size<20480" class="img" :src="getImageUrl(p.path, 'big')" alt="">
            <img v-else class="img" :src="getImageUrl(p.path)" alt="">
          </div>
        </template>
        <template v-else>-</template>
      </div>
    </div>
    <div class="itemWrap">
      <div class="label">联系人</div>
      <div class="info">{{info.contactName || '-'}}</div>
    </div>
    <div class="itemWrap">
      <div class="label">职务</div>
      <div class="info">{{info.contactJob || '-'}}</div>
    </div>
    <div class="itemWrap">
      <div class="label">联系电话</div>
      <div class="info">{{info.phone || '-'}}</div>
    </div>
    <div class="itemWrap">
      <div class="label">电子邮箱</div>
      <div class="info">{{info.email || '-'}}</div>
    </div>
    <div class="itemWrap">
      <div class="label">推荐单位</div>
      <div class="info">{{info.pushCompany || '无'}}</div>
    </div>
    <div class="itemWrap">
      <div class="label">审核状态</div>
      <div class="info">{{setStatusText}}</div>
    </div>
    <div class="itemWrap" v-if="info.status=='SUCCESS'&&info.projectList&&info.projectList.length>0">
      <div class="label">参评项目</div>
      <div class="info">
        <p v-for="p in info.projectList" :key="p.id">
          <router-link class="colorGreen" :to="`/admin/project_info?id=${p.id}`">{{p.name}}</router-link>
        </p>
      </div>
    </div>
    <div class="btnWrap">
      <span class="colorGreen oneBtn" @click="goBack">返回</span>
      <Button v-if="info.status!='SUCCESS'" class="succBtn statusBtn" @click="checkBasic('SUCCESS')">通过审核</Button>
      <Button v-if="info.status!='FAILED'" class="failedBtn statusBtn" @click="checkBasic('FAILED')">不通过审核</Button>
      <Button v-if="info.status!='CHECKING'" class="checkBtn statusBtn" @click="checkBasic('CHECKING')">待审核</Button>
    </div>
  </div>
</template>

<script>
import CheckService from "@/api/check";
export default {
  data() {
    return {
      info: { certificateFiles: [] }
    };
  },
  mounted() {
    this.getInfo();
  },
  computed: {
    // 根据参评单位类型赋值证明类型
    setCertType() {
      let val = this.info.applicantType,
        certType;
      if (val == "个人") {
        certType = "个人身份证件";
      } else if (val == "院校或科研机构") {
        certType = "院校或科研机构盖章的《参评申报表》";
      } else if (val == "企业") {
        certType = "营业执照复印件或企业盖章的《参评申报表》";
      } else {
        certType = "-";
      }
      return certType;
    },
    // 根据参评单位类型赋值证明类型
    setStatusText() {
      let status = this.info.status,
        statusText;
      if (status == "CHECKING") {
        statusText = "待审核";
      } else if (status == "SUCCESS") {
        statusText = "已通过";
      } else if (status == "FAILED") {
        statusText = "未通过";
      }
      return statusText;
    }
  },
  methods: {
    // 返回
    goBack(){
      this.$router.go(-1);
    },
    // 审核
    checkBasic(status) {
      CheckService.checkRegistration({ id: this.info.id, checkStatus: status })
        .then(res => {
          if (res.errorNo == 0) {
            this.info.status = status;
            this.$Message.success("操作成功");
          } else {
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    },
    // 获取参评单位信息
    getInfo() {
      let id = this.$route.query.id;
      CheckService.getRegistrationInfo(id)
        .then(res => {
          if (res.errorNo == 0) {
            this.info = res.data;
          } else {
            this.info = { certificateFiles: [] };
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.infoWrap {
  font-size: 14px;
  line-height: 1.8em;
  .itemWrap {
    padding-bottom: 10px;
    .label {
      float: left;
      width: 90px;
      text-align: right;
    }
    .info {
      margin-left: 110px;
      color: #777;
      .imgWrap {
        padding-bottom: 10px;
        .img {
          max-width: 80%;
        }
      }
    }
  }
  .btnWrap {
    text-align: center;
    padding: 30px 0;
    .oneBtn {
      margin-right: 15px !important;
      cursor: pointer;
    }
    .failedBtn {
      background: #ed0606;
    }
    .succBtn{
      background: #FFAC00;
    }
    .checkBtn{
      background: #277a44;
    }
    .statusBtn{
      margin: 10px;
      color: #fff;
    }
  }
}
</style>