<template>
  <div class="userModal">
    <CheckboxGroup v-model="form">
      <p class="item">
        <Checkbox size="large" label="super">
          <span class="bigText">超级管理员<span class="note">（包含以下所有权限，且可查看所有评委打分）</span></span>
        </Checkbox>
      </p>
      <p class="item">
        <Checkbox size="large" label="user">
          <span class="bigText">信息管理<span class="note">（添加、管理用户）</span></span>
        </Checkbox>
      </p>
      <p class="item">
        <Checkbox size="large" label="check">
          <span class="bigText">预审管理<span class="note">（针对作品进行初步审核，不符合要求的作品可不予通过）</span></span>
        </Checkbox>
      </p>
      <p class="item">
        <Checkbox size="large" label="judge">
          <span class="bigText">评委管理<span class="note">（添加、管理评委信息）</span></span>
        </Checkbox>
      </p>
      <p class="item">
        <Checkbox size="large" label="review">
          <span class="bigText">评审结果管理<span class="note">（查看评审结果但不可查看评委打分，可对评审结果进行相关操作）</span></span>
        </Checkbox>
      </p>
      <p class="item">
        <Checkbox size="large" label="news">
          <span class="bigText">新闻管理<span class="note">（发布新闻到官网）</span></span>
        </Checkbox>
      </p>
      <p class="item">
        <Checkbox size="large" label="after">
          <span class="bigText">赛后服务<span class="note">（电子证书等服务）</span></span>
        </Checkbox>
      </p>
    </CheckboxGroup>
    <div class="btnWrap">
      <Button class="submitBtn" :loading="submitting" @click="submit">确定</Button>
      <Button @click="hide">取消</Button>
    </div>
  </div>
</template>
<script>
import UserService from "@/api/user";
export default {
  props: ['hide', 'index', 'info'],
  data(){
    return{
      submitting: false,
      form: [],
      userInfo: {}
    }
  },
  mounted(){
    this.userInfo = Object.assign({}, this.info)
    if(this.userInfo.permissions){
      this.form = this.userInfo.permissions.split(",")
    }
  },
  methods:{
    submit(){
      // console.log(this.form)
      let permissions = this.form.join()
      console.log(permissions)
      if(this.form.length > 0){
        this.submitting = true;
        UserService.setCompetitionUserPermissions({id:this.userInfo.id, permissions: permissions})
          .then(res => {
            if (res.errorNo == 0) {
              // 提交成功执行
              this.$Message.success('用户权限设置成功')
              this.userInfo.permissions = permissions
              this.$emit('Update', {type: 'permission', index: this.index, info: this.userInfo})
              this.hide()
            } else {
              this.$Message.error(res.errorMessage);
            }
            this.submitting = false;
          })
          .catch(() => {
            this.$Message.error("连接服务器失败，请稍后再试");
            this.submitting = false;
          });
      }else{
        this.$Message.error("至少选择一项权限");
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.item{
  padding-bottom: 15px;
  font-size: 16px;
}
.bigText{
  font-size: 16px;
  padding-left: 8px;
  color: #515a6e;
}
.note{
  color: #ccc;
  font-size: 12px;
}
</style>