<template>
  <div class="topTitle">
  <div class="userInfo">
    <span>{{userInfo.loginName || '-'}}，您好！ </span>
    <span class="logout" @click="logOut">[退出登录]</span>
  </div>
  </div>
</template>
<script>
import { officialWebsiteUrl } from "@/config/env";
export default {
  data() {
    return {
      userInfo: this.$store.getters.userInfo || {},
      websiteUrl: `${officialWebsiteUrl}index`
    };
  },
  methods: {
    // 退出登录
    logOut() {
      this.$store.commit("setToken", "");
      this.$store.commit("setUserInfo", {});
      window.localStorage.removeItem("login_time");
      this.$router.push("/login");
    }
  }
};
</script>

<style lang="scss" scoped>
.topTitle {
  position: relative;
  background: url(/img/bg2.png) no-repeat center;
  background-size: cover;
  height: 110px;
  .userInfo {
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 999;
  }
  .logout {
    color: #277a44;
    cursor: pointer;
    background-size: 12px;
  }
}
</style>
