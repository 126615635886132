<template>
  <div class="edit_container">
    <quill-editor 
      v-model="newContent" 
      ref="myQuillEditor" 
      :options="editorOption" 
      @blur="onEditorBlur($event)"
      @focus="onEditorFocus($event)"
      @change="onEditorChange($event)">
    </quill-editor>
  </div>
</template>
<script>
import { baseUrl, imgBaseUrl } from "@/config/env";

import { quillEditor, Quill } from "vue-quill-editor"; //调用编辑器
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

//设置字体样式
let Font = Quill.import('attributors/style/font') //引入这个后会把样式写在style上
let fonts = [false, 'KaiTi', 'SimSun', 'Microsoft-YaHei']
Font.whitelist = fonts //将字体加入到白名单
Quill.register(Font, true)

// 设置字体大小
let fontSizeStyle = Quill.import('attributors/style/size')
fontSizeStyle.whitelist = ['10px', '12px', '16px' ,'18px', '20px', '22px', '24px', '26px', '28px', '32px', '48px']
Quill.register(fontSizeStyle, true)

// 设置字体颜色
let fontColorStyle = Quill.import('attributors/style/color')
fontColorStyle.whitelist = ['#8c8c8c', '#000', '#e60000', '#ff9900', '#ffff00',
  '#008a00', '#0066cc', '#ffffff', '#9933ff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc',
  '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0',
  '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2',
  '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466']
Quill.register(fontColorStyle, true)

import { ImageExtend, QuillWatch } from 'quill-image-extend-module'
Quill.register('modules/ImageExtend', ImageExtend)

const imgApi = `${baseUrl}picture/upload`

export default {
  props: ["content"],
  components: {
    quillEditor
  },
  data() {
    return {
      token: this.$store.getters.token,
      newContent: "",
      editorOption: {
        placeholder: "请在这里输入",
        modules: {
          ImageExtend: {
            loading: true,
            name: 'picture',
            action: imgApi,
            headers: (xhr) => {
              xhr.setRequestHeader('Authorization', this.token)
            },
            change: (xhr, formData) => {
              formData.append('applyType', 'COVER')
            },
            response: (res) => {
              return `${imgBaseUrl}${res.data.path}`
            }
          },
          toolbar: {  // 如果不上传图片到服务器，此处不必配置
            container: [
              ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
              ["blockquote", "code-block"], //引用，代码块
              [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
              [{ list: "ordered" }, { list: "bullet" }], //列表
              [{ script: "sub" }, { script: "super" }], // 上下标
              [{ indent: "-1" }, { indent: "+1" }], // 缩进
              [{ direction: "rtl" }], // 文本方向
              [{ header: [1, 2, 3, 4, 5, 6, false] }], //几级标题
              [{ color: ['#8c8c8c', '#000', '#e60000', '#ff9900', '#ffff00', 
                '#008a00', '#0066cc', '#ffffff', '#9933ff', '#facccc', '#ffebcc',
                '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666',
                '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888',
                '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2',
                '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'
              ]}, { background: [] }], // 字体颜色，字体背景颜色
              // [{ size: ["small", false, "large", "huge"] }], // 字体大小
              [{size : ['10px', '12px', false, '16px' ,'18px', '20px', '22px', '24px', '26px', '28px', '32px', '48px']}],
              [{ 'font': [false, 'KaiTi', 'SimSun', 'Microsoft-YaHei'] }], // 字体 false默认微软雅黑，其他可删减
              [{ align: [] }], //对齐方式
              ["clean"], //清除字体样式
              ["image"] //上传图片、上传视频
            ],
            handlers: {
              'image': function () {
                QuillWatch.emit(this.quill.id)
              }
            }
          }
        }
      }
    };
  },
  mounted() {    
    this.newContent = this.content;
    this.addTitle();
  },
  methods: {
    addTitle() {
      const titleConfig = {
        "ql-bold": "加粗",
        "ql-font": "字体",
        "ql-code": "插入代码",
        "ql-italic": "斜体",
        "ql-link": "添加链接",
        "ql-color": "字体颜色",
        "ql-background": "背景颜色",
        "ql-size": "字体大小",
        "ql-strike": "删除线",
        "ql-script": "上标/下标",
        "ql-underline": "下划线",
        "ql-blockquote": "引用",
        "ql-header": "标题",
        "ql-indent": "缩进",
        "ql-list": "列表",
        "ql-align": "文本对齐",
        "ql-direction": "文本方向",
        "ql-code-block": "代码块",
        "ql-formula": "公式",
        "ql-image": "图片",
        "ql-video": "视频",
        "ql-clean": "清除字体样式"
      };
      const oToolBar = document.querySelector(".ql-toolbar"),
        aButton = oToolBar.querySelectorAll("button"),
        aSelect = oToolBar.querySelectorAll("select");
      aButton.forEach(function(item) {
        if (item.className === "ql-script") {
          item.value === "sub" ? (item.title = "下标") : (item.title = "上标");
        } else if (item.className === "ql-indent") {
          item.value === "+1"
            ? (item.title = "向右缩进")
            : (item.title = "向左缩进");
        } else {
          // console.log(item.classList[0])
          item.title = titleConfig[item.classList[0]];
        }
      });
      aSelect.forEach(function(item) {
        let className = item.classList[0],
          parentNode = item.parentNode;
        if (className == "ql-color" || className == "ql-background") {
          parentNode.getElementsByClassName(className)[0].title =
            titleConfig[className];
        } else {
          parentNode.title = titleConfig[className];
        }
      });
    },
    onEditorReady() {}, // 准备编辑器
    onEditorBlur() {}, // 失去焦点事件
    onEditorFocus() {}, // 获得焦点事件
    onEditorChange() {
      this.$emit("updateContent", this.newContent);
    } // 内容改变事件
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    }
  },
  watch: {
    content(newContent) {
      this.newContent = newContent;
    }
  }
};
</script>

<style lang="scss">
.ql-editor {
  white-space: normal !important;
  line-height: 1.8;
}
.ql-container {
  white-space: pre-wrap !important;
}
.ql-container .ql-editor {
  min-height: 200px;
  max-height: 400px;
  color: #8c8c8c;
}

.quill-editor .ql-picker.ql-font .ql-picker-label[data-value=KaiTi]::before,
.quill-editor .ql-picker.ql-font .ql-picker-item[data-value=KaiTi]::before {
  content: "楷体" ;
  font-family: "KaiTi" ;
}
.quill-editor .ql-picker.ql-font .ql-picker-label[data-value=SimSun]::before,
.quill-editor .ql-picker.ql-font .ql-picker-item[data-value=SimSun]::before {
  content: "宋体" ;
  font-family: "SimSun" ;
}
.quill-editor .ql-picker.ql-font .ql-picker-label[data-value=Microsoft-YaHei]::before,
.quill-editor .ql-picker.ql-font .ql-picker-item[data-value=Microsoft-YaHei]::before {
  content: "微软雅黑" ;
  font-family: "Microsoft YaHei ";
}

.quill-editor .ql-snow .ql-picker.ql-size .ql-picker-label::before,
.quill-editor .ql-snow .ql-picker.ql-size .ql-picker-item::before{
  content: '14px';
}
.quill-editor .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='10px']::before, 
.quill-editor .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='10px']::before {
  content: '10px' ;
}
.quill-editor .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='12px']::before, 
.quill-editor .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='12px']::before {
  content: '12px' ;
}
.quill-editor .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='16px']::before, 
.quill-editor .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='16px']::before {
  content: '16px' ;
}
.quill-editor .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='16px']::before, 
.quill-editor .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='16px']::before {
  content: '16px' ;
}
.quill-editor .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='18px']::before, 
.quill-editor .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='18px']::before {
  content: '18px' ;
}
.quill-editor .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='20px']::before, 
.quill-editor .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='20px']::before {
  content: '20px' ;
}
.quill-editor .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='22px']::before, 
.quill-editor .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='22px']::before {
  content: '22px' ;
}
.quill-editor .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='24px']::before, 
.quill-editor .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='24px']::before {
  content: '24px' ;
}
.quill-editor .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='26px']::before, 
.quill-editor .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='26px']::before {
  content: '26px' ;
}
.quill-editor .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='28px']::before, 
.quill-editor .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='28px']::before {
  content: '28px' ;
}
.quill-editor .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='32px']::before, 
.quill-editor .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='32px']::before {
  content: '32px' ;
}
.quill-editor .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='48px']::before, 
.quill-editor .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='48px']::before {
  content: '48px' ;
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before{
  content: '正文';
}
.quill-editor .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.quill-editor .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: '标题一';
}
.quill-editor .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.quill-editor .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: '标题二';
}
.quill-editor .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.quill-editor .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: '标题三';
}
.quill-editor .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.quill-editor .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: '标题四';
}
.quill-editor .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.quill-editor .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: '标题五';
}
.quill-editor .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.quill-editor .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: '标题六';
}

.ql-snow .ql-picker-label::before{
  vertical-align: top;
}
</style>