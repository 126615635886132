<template>
<div class="projectDistribution">
  <p class="mgBtm">为评委 “{{judge.trueName}}” 分配项目</p>
  <Form :model="form" :label-width="80">
    <Row :gutter="30">
      <Col span="12">
        <FormItem label="项目名称">
          <Input v-model="form.name" clearable @on-enter="searchProject"></Input>
        </FormItem>
      </Col>
      <Col span="12">
        <FormItem label="推荐单位">
          <Select v-model="form.pushCompany" clearable>
            <Option :value="p.value" v-for="p in getPushCompany()" :key='p.id'>{{p.label}}</Option>
            <Option value="其他">其他</Option>
          </Select>
        </FormItem>
      </Col>
      <Col span="12">
        <FormItem label="参评类别">
          <Select v-model="form.type" clearable @on-change="changeType">
            <Option value="建筑应用创新综合类">建筑应用创新综合类</Option>
            <Option value="建筑应用创新单项类">建筑应用创新单项类</Option>
          </Select>
        </FormItem>
      </Col>
      <Col span="12" v-if="form.type=='建筑应用创新单项类'">
        <FormItem label="" :label-width="0">
          <Select v-model="form.subType" clearable>
            <Option value="建筑材料应用创新类">建筑材料应用创新类</Option>
            <Option value="装配式建筑应用创新类">装配式建筑应用创新类</Option>
            <Option value="建筑部品部件应用创新类">建筑部品部件应用创新类</Option>
            <Option value="设备设施应用创新类">设备设施应用创新类</Option>
            <Option value="其它应用创新类">其它应用创新类</Option>
          </Select>
        </FormItem>
      </Col>
      <Col span="24" align="right">
        <Button class="moreBtn mgBtm" :loading="loading" @click="searchProject">筛选项目</Button>
      </Col>
    </Row>
  </Form>
  <Transfer
    :data="list"
    :target-keys="listTarget"
    :list-style="listStyle"
    :titles="['可分配项目', '选中项目']"
    :operations="['To left','To right']"
    reverse-operation
    not-found-text="暂无数据"
    @on-change="handleProject">
    <div class="moreBtnWrap">
      <template v-if="total==list.length">
        <span class="text">项目加载完成</span>
      </template>
      <template v-else>
        <span class="text">未加载项目数：{{total - list.length}}</span>
        <Button class="moreBtn" :loading="loading" @click="loadMoreProject">加载更多可分配项目</Button>  
      </template>
    </div>
  </Transfer>
  <div class="btnWrap">
    <Button class="moreBtn" @click="submit" :loading="submitting">提交</Button>
  </div>
</div>
</template>

<script>
import JudgeService from "@/api/judge";
import ResultService from "@/api/result";
export default {
  props:['level', 'judge', 'hide'],
  data(){
    return{
      listStyle: {
        width: '40%',
        height: '300px'
      },

      form: {},
      loading: false,
      currentPage: 1,
      total: 0,
      list: [],
      listTarget: [],  //要分配的项目id列表

      submitting: false
    }
  },
  mounted(){
    this.getList()
    let list = this.judge.projectList || []
    for(let i=0,l=list.length;i<l;i++){
      this.listTarget.push(list[i].projectId)
    }
  },
  methods:{
    // 选择参评类别
    changeType(val){
      if(val==undefined || val=='建筑应用创新综合类'){
        this.form.subType = null
      }
    },
    submit(){
      // if(this.listTarget.length==0){
      //   this.$Message.error('请选择要分配的项目。')
      //   return
      // }
      let condition = {
        projectIds: this.listTarget,
        judgeId: this.judge.userId, 
        stage: this.level
      }
      this.submitting = true;
      JudgeService.distributionProjectOneJudge(condition)
        .then(res => {
          if (res.errorNo == 0) {
            // 请求成功执行
            this.submitting = false
            this.$Message.success("修改成功");
            this.hide()
            this.$emit('Update')
          } else {
            this.submitting = false
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          this.submitting = false
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    },
    // 选中项目（to left / to right）
    handleProject(target){
      this.listTarget = target
    },
    // 加载更多项目
    loadMoreProject(){
      this.currentPage += 1
      this.getList()
    },
    // 搜索项目
    searchProject(){
      this.currentPage = 1
      this.getList()
    },
    // 筛选条件
    getConditions(){
      let pushCompany = null, pushCode = null
      if(this.form.pushCompany == "其他"){
        pushCompany = null;
        pushCode = "无";
      }else{
        pushCompany = this.form.pushCompany;
        pushCode = null;
      }
      let data = {
        page: this.currentPage - 1,
        size: 60,
        sort: "id,desc",
        "name.contains": this.form.name || null,
        "type.eq": this.form.type || null,
        "subType.eq": this.form.subType || null,
        "pushCompany.eq": pushCompany || null,
        "pushCode.eq" : pushCode || null,
        "checkStatus.eq": 'SUCCESS',
        "stage.eq": this.level
      }
      if(this.level=='FIRST'){
        // data['firstStatus.eq'] = 'CHECKING'
      }else if(this.level=='FINAL'){
        data['firstStatus.eq'] = 'FINAL'
        // data['finalStatus.eq'] = 'CHECKING'
      }
      return data
    },
    // 获取项目列表
    getList(){
      this.loading = true;
      let condition = this.getConditions()
      ResultService.getProjectResult(condition)
        .then(res => {
          if (res.errorNo == 0) {
            let data = res.data, list = []
            for(let i=0,l=data.length;i<l;i++){
              let temp = data[i]
              list[i] = {key: temp.id, label: `${temp.projectSn} - ${temp.name}`}
            }
            if(this.currentPage==1){
              this.list = list
              this.total = res.totalSize
            }else{
              this.list = this.list.concat(list)
            }
          } else {
            this.$Message.error(res.errorMessage)
          }
          this.loading = false
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
          this.loading = false;
        });
    },
  }
}
</script>


<style lang="scss">
.projectDistribution{
  .ivu-transfer-list-with-footer{
    padding-bottom: 45px;
  }
  .moreBtn{
    background: #277a44;
    color: #fff;
  }
  .moreBtnWrap{
    text-align: right;
    padding: 5px 10px;
    .text{
      display: inline-block;
      padding: 7px 15px 7px 0;
      color: #ccc;
    }
  }
  .btnWrap{
    text-align: center;
    padding: 50px 0 30px;
  }
  .mgBtm{
    margin-bottom: 15px;
    font-weight: bold;
  }
}
</style>
