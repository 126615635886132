<template>
  <div class="userModal">
    <Form ref="form" :model="form" :rules="rules" :label-width="85">
      <FormItem label="评委账号">{{form.loginName}}</FormItem>
      <FormItem label="评委姓名">{{form.trueName}}</FormItem>
      <FormItem label="新密码" prop="password">
        <Input type="password" password v-model="form.password" placeholder="8-16位字符，包含字母和数字" />
      </FormItem>
      <FormItem label="确认密码" prop="confirmPassword">
        <Input type="password" password v-model="form.confirmPassword" placeholder="8-16位字符，包含字母和数字" />
      </FormItem>
      <div class="btnWrap">
        <Button class="submitBtn" :loading="submitting" @click="submit">重置密码</Button>
        <Button @click="hide">取消</Button>
      </div>
    </Form>
  </div>
</template>

<script>
import UserService from "@/api/user";
export default {
  props: ['hide', 'info'],
  data(){
    return{
      submitting: false,
      form: {},
      rules: {
        password: [
          {
            required: true, 
            trigger: "blur,change",
            message: "密码需8-16位字符，包含字母和数字",
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/
          }],
        confirmPassword: [
          {required: true, message: '请再次输入密码', trigger: "blur,change"},
          {
            required: true,
            message: "需与新密码相同",
            validator: (rule, value) => {
              if (!value || value != this.form.password) {
                return false;
              }
              return true;
            }
          }
        ],
      }
    }
  },
  mounted(){
    this.form = Object.assign({}, this.info)
  },
  methods:{
    submit(){
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitting = true;
          UserService.resetPassword({userId:this.form.userId, newPassword: this.form.password})
            .then(res => {
              if (res.errorNo == 0) {
                // 提交成功执行
                this.$Message.success('密码重置成功')
                this.hide()
              } else {
                this.$Message.error(res.errorMessage);
              }
              this.submitting = false;
            })
            .catch(() => {
              this.$Message.error("连接服务器失败，请稍后再试");
              this.submitting = false;
            });
        } else {
          this.$Message.error("信息未正确填写完整");
        }
      });
    }
  }
}
</script>