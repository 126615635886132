<template>
  <div class="userWrap">
    <div class="user">
      <Row :gutter="15">
        <Col :lg="12" :xs="24">
          <p><img class="logo" src="/img/logo.png" alt=""></p>
          <p>
            <img class="title" src="/img/title.png" alt="">
            <span class="littleTitle">管理系统</span>
          </p>
        </Col>
        <Col :lg="12" :xs="24">
          <div class="formWrap">
            <div class="userFrom">
              <p class="wayText">管理员登录</p>
              <Form ref="login" :model="form" hide-required-mark :label-width="0" label-position="left">
                <div class="inputWrap">
                  <FormItem label="" prop="loginName" :rules="loginNameRules">
                    <Input v-model="form.loginName" placeholder="用户名" :border="false"></Input>    
                  </FormItem>  
                </div>
                <div class="inputWrap">
                  <FormItem label="" prop="password" :rules="keyRules">
                    <Input type="password" v-model="form.password" placeholder="密 码" :border="false" password @on-enter="login"></Input>    
                  </FormItem>
                </div>
                <div class="mgBtm"><Checkbox v-model="isRemember">记住密码</Checkbox></div>
                <Button class="defaultBtn" long shape="circle" :loading="submitting" @click="login">登&nbsp;&nbsp;&nbsp;&nbsp;录</Button>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  </div>
</template>

<script>
import userService from "@/api/user";
export default {
  data() {
    return {
      form: {},
      loginNameRules: [
        {
          required: true,
          // pattern: /^1[3456789]\d{9}$/,
          message: "请输入用户名",
          trigger: "blur"
        }
      ],
      keyRules: [
        { required: true, message: "内容不能为空", trigger: "blur,change" }
      ],
      submitting: false,
      isRemember: true
    };
  },
  mounted(){
    let userRemember = window.localStorage.getItem('adminUserRemember')
    if(userRemember){
      let form = JSON.parse(userRemember)
      form.password = window.atob(form.password)
      this.form = form
    }
  },
  methods: {
    // 登录
    login() {
      this.$refs.login.validate(valid => {
        if (valid) {
          this.submitting = true;
          let condition = {
            loginName: this.form.loginName,
            key: this.form.password
          };
          // condition.accountType = "CONTESTSUPERADMIN";
          // condition.accountType = "DESIGNER";
          userService
            .login(condition)
            .then(res => {
              if (res.errorNo == 0) {
                let user = res.data.user;
                // 跳转到什么页面
                if(user.accountType == "JUDGE"){
                  this.$Message.error("非管理员账号");
                }else {
                  if(this.isRemember){
                    let pwd = window.btoa(this.form.password)
                    let userRemember = Object.assign(this.form, {password: pwd})
                    window.localStorage.setItem('adminUserRemember', JSON.stringify(userRemember))
                  }else{
                    window.localStorage.removeItem("adminUserRemember");
                  }
                  user.competitionUser = res.data.competitionUser
                  this.$store.commit("setUserInfo", user);
                  this.$store.commit("setToken", res.data.accessToken);
                  window.localStorage.setItem("login_time", new Date().getTime());
                  this.$router.push("/index");
                }
              } else if (res.errorNo == 9013) {
                if (
                  res.errorMessage.split(",").length > 1 &&
                  res.errorMessage.split(",")[0] == 5054
                ) {
                  this.$Message.error("用户不存在");
                } else {
                  this.$Message.error("密码错误");
                }
              } else {
                this.$Message.error(res.errorMessage);
              }
              this.submitting = false;
            })
            .catch(() => {
              this.submitting = false;
              this.$Message.error("连接服务器失败，请稍后再试");
            });
        } else {
          this.$Message.error("请输入正确的手机号和密码(验证码)");
        }
      });
    }
  }
};
</script>
<style lang="scss">
.userWrap {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: url(/img/bg.jpg) no-repeat;
  background-size: cover;
  overflow: auto;
  padding: 0 80px;
  .user {
    max-width: 1200px;
    margin: auto;
    .logo {
      width: 100px;
      padding: 100px 0 15px;
    }
    .title {
      height: 35px;
      padding-right: 15px;
    }
    .littleTitle {
      font-family: Source Han Sans CN;
      vertical-align: text-bottom;
      color: #277a44;
      font-size: 18px;
    }
    .formWrap {
      margin: 90px 30px;
      padding: 40px 50px 60px;
      background: #fff;
      border-radius: 12px;
      box-shadow: 0 0 16px rgba($color: #fff, $alpha: 0.4);
      .userFrom {
        .wayText {
          border-bottom: 1px solid #277a44;
          color: #3f4641;
          margin-bottom: 30px;
          font-size: 20px;
          text-align: center;
          padding-bottom: 15px;
        }
        .inputWrap {
          background: #eaeaea;
          padding: 3px 15px;
          border-radius: 100px;
          margin-bottom: 24px;

          .ivu-form-item {
            margin-bottom: 0;
            .ivu-input {
              background: transparent;
            }
          }
          .ivu-form-item-error .ivu-input {
            border: transparent;
          }
        }
      }
      .mgBtm{
        margin-bottom: 10px;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .userWrap {
    padding: 0 15px;
    .user {
      .logo {
        width: 80px;
        padding: 50px 0 15px;
      }
      .formWrap {
        margin: 30px;
        padding: 40px 30px 60px;
      }
    }
  }
}
</style>