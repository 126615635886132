<template>
  <div class="distribution">
    <p class="pageTitle">初审项目分配</p>
    <div class="pageContent">
      <p class="center" style="padding: 0 0 20px; font-size:12px">温馨提示：如果选错条目，请选中右侧框中条目点击To left按钮将数据移回左侧对话框</p>
      <!-- <p class="colorGreen center" style="padding: 100px 0">功能正在开发中，敬请期待...</p> -->
      <p class="right"><Button class="moreBtn mgBtm" to="/admin/distribution_result?stage=FIRST">查看分配结果</Button></p>
      <div class="formBox">
        <Form :model="form" :label-width="80">
          <Row :gutter="30">
            <Col span="8">
              <FormItem label="项目名称">
                <Input v-model="form.name" clearable @on-enter="searchProject"></Input>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="参评类别">
                <Select v-model="form.type" clearable @on-change="changeType">
                  <Option value="建筑应用创新综合类">建筑应用创新综合类</Option>
                  <Option value="建筑应用创新单项类">建筑应用创新单项类</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="8" v-if="form.type=='建筑应用创新单项类'">
              <FormItem label="" :label-width="0">
                <Select v-model="form.subType" clearable>
                  <Option value="建筑材料应用创新类">建筑材料应用创新类</Option>
                  <Option value="装配式建筑应用创新类">装配式建筑应用创新类</Option>
                  <Option value="建筑部品部件应用创新类">建筑部品部件应用创新类</Option>
                  <Option value="设备设施应用创新类">设备设施应用创新类</Option>
                  <Option value="其它应用创新类">其它应用创新类</Option>
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="30">
            <Col span="8">
              <FormItem label="推荐单位">
                <Select v-model="form.pushCompany" clearable>
                  <Option :value="p.value" v-for="p in getPushCompany()" :key='p.id'>{{p.label}}</Option>
                  <Option value="其他">其他</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="16" align="right">
              <Button class="moreBtn mgBtm" :loading="loading" @click="searchProject">筛选项目</Button>
            </Col>
          </Row>
        </Form>  
      </div>
      <div class="projectWrap">
        <Transfer
          :data="projectList"
          :target-keys="projectListTarget"
          :list-style="listStyle"
          :titles="['可分配项目', '选中项目']"
          :operations="['To left','To right']"
          reverse-operation
          not-found-text="暂无数据"
          @on-change="handleProject">
          <div class="moreBtnWrap">
            <template v-if="projectTotal==projectList.length">
              <span class="text">项目加载完成</span>
            </template>
            <template v-else>
              <span class="text">未加载项目数：{{projectTotal - projectList.length}}</span>
              <Button class="moreBtn" :loading="loading" @click="loadMore">加载更多可分配项目</Button>  
            </template>
          </div>
        </Transfer>  
      </div>
      <Divider></Divider>
      <Row :gutter="30">
        <Col span="8">
          <div class="ivu-transfer-list ivu-transfer-list-with-footer" style="width:100%; height: 300px;">
            <div class="ivu-transfer-list-header">
              <Row>
                <Col span="12" align="center">已分配评委</Col>
                <Col span="12" align="center">分配项目数</Col>
              </Row>
            </div>
            <div class="ivu-transfer-list-body ivu-transfer-list-body-with-footer">
              <div v-if="judgeTotalAssigned>0" class="ivu-transfer-list-content">
                <Row class="ivu-transfer-list-content-item cursorDefault" v-for="(p, i) in judgeListAssigned" :key="p.id">
                  <Col span="12" align="center">{{p.trueName}}</Col>
                  <Col span="12" align="center"><span @click="showModal(i)" class="numBtn">{{p.projectCount}}</span></Col>
                </Row>  
              </div>
              <p v-else class="noData">暂无数据</p>
            </div>
            <div class="ivu-transfer-list-footer">
              <div class="moreBtnWrap">
                <template v-if="judgeTotalAssigned==judgeListAssigned.length">
                  <span class="text">评委加载完成</span>
                </template>
                <template v-else>
                  <span class="text">未加载评委数：{{judgeTotalAssigned - judgeListAssigned.length}}</span>
                  <Button class="moreBtn" :loading="loadingJudgeAssigned" @click="loadMoreJudgeAssigned">加载更多已分配评委</Button>  
                </template>
              </div>
            </div>
          </div>
        </Col>
        <Col span="16">
          <Transfer
            :data="judgeList"
            :target-keys="judgeListTarget"
            :list-style="listStyle"
            :titles="['可分配评委', '选中评委']"
            :operations="['To left','To right']"
            reverse-operation
            not-found-text="暂无数据"
            @on-change="handleJudge">
            <div class="moreBtnWrap">
              <template v-if="judgeTotal==judgeList.length">
                <span class="text">评委加载完成</span>
              </template>
              <template v-else>
                <span class="text">未加载评委数：{{judgeTotal - judgeList.length}}</span>
                <Button class="moreBtn" :loading="loadingJudge" @click="loadMoreJudge">加载更多可分配评委</Button>  
              </template>
            </div>
          </Transfer>
        </Col>
      </Row>
      <div class="btnWrap">
        <Button class="moreBtn" @click="submit" :loading="submitting">提交</Button>
      </div>
    </div>
    <Modal v-model="isShow" title="评委分配" :width="70" footer-hide :mask-closable="false">
      <ProjectDistribution v-if="isShow" :hide="showModal" level="FIRST" :judge="judgeInfo" @Update="getJudgeListAssigned" />
    </Modal>
  </div>
</template>

<script>
import ResultService from "@/api/result";
import JudgeService from "@/api/judge";
import ProjectDistribution from '@/components/result/Project.vue'
export default {
  components: { ProjectDistribution },
  data(){
    return{
      listStyle: {
        width: '40%',
        height: '300px'
      },

      loading:false,
      form: {},
      currentPage: 1,
      projectTotal: 0,
      projectList: [],
      projectListTarget: [],  //要分配评委的项目id列表

      loadingJudge: false,
      currentPageJudge: 1,
      judgeTotal: 0,
      judgeList: [],
      judgeListTarget: [],  //要分配的评委id列表

      submitting: false,

      // 已分配评委
      loadingJudgeAssigned: false,
      currentPageJudgeAssigned: 1,
      judgeTotalAssigned: 0,
      judgeListAssigned: [],

      isShow: false,
      judgeInfo: {},
    }
  },
  mounted(){
    this.getProjectList()
    this.getJudgeList()
    this.getJudgeListAssigned()
  },
  methods:{
    // 选择参评类别
    changeType(val){
      if(val==undefined || val=='建筑应用创新综合类'){
        this.form.subType = null
      }
    },
    // 修改分配项目
    showModal(index){
      if(this.isShow){
        this.judgeInfo = {}
        this.isShow = false
      }else{
        this.judgeInfo = this.judgeListAssigned[index]
        this.isShow = true
      }
    },
    // 加载更多已分配评委
    loadMoreJudgeAssigned(){
      this.currentPageJudgeAssigned += 1
      this.getJudgeListAssigned()
    },
    // 获取已分配评委列表
    getJudgeListAssigned(){
      this.loadingJudgeAssigned = true;
      let condition = {
        "userType.eq": "JUDGE",
        page: this.currentPageJudgeAssigned - 1,
        size: 60,
        // sort: "projectCount,desc",
        "level.eq": 'FIRST',
        // "projectCount.ne": 0
      }
      JudgeService.getJudges(condition)
        .then(res => {
          if (res.errorNo == 0) {
            if(this.currentPageJudgeAssigned==1){
              this.judgeListAssigned = res.data
              this.judgeTotalAssigned = res.totalSize
            }else{
              this.judgeListAssigned = this.judgeListAssigned.concat(res.data)
            }
          } else {
            this.$Message.error(res.errorMessage)
          }
          this.loadingJudgeAssigned = false
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
          this.loadingJudge = false;
        });
    },
    // 分配评委
    submit(){
      if(this.projectListTarget.length==0){
        this.$Message.error('请选择要分配的项目。')
        return
      }
      if(this.judgeListTarget.length==0){
        this.$Message.error('请选择要分配的评委。')
        return
      }
      // console.log(this.projectListTarget)
      // console.log(this.judgeListTarget)
      let condition = {projectIds: this.projectListTarget, judgeIds: this.judgeListTarget, stage: "FIRST"}
      this.submitting = true;
      JudgeService.distributionProjectAll(condition)
        .then(res => {
          if (res.errorNo == 0) {
            // 请求成功执行
            this.submitting = false
            this.$Message.success("分配成功");
            this.judgeListTarget = []
            this.projectListTarget = []
          } else {
            this.submitting = false
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          this.submitting = false
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    },
    // 搜索项目
    searchProject(){
      this.currentPage = 1
      this.getProjectList()
    },
    // 选中项目（to left / to right）
    handleProject(target){
      this.projectListTarget = target
    },
    // 加载更多项目
    loadMore(){
      this.currentPage += 1
      this.getProjectList()
    },
    // 获取项目列表
    getProjectList(){
      this.loading = true;
      let condition = this.getConditions()
      ResultService.getProjectResult(condition)
        .then(res => {
          if (res.errorNo == 0) {
            let data = res.data, list = []
            for(let i=0,l=data.length;i<l;i++){
              let temp = data[i]
              list[i] = {key: temp.id, label: `${temp.projectSn} - ${temp.name}`}
            }
            if(this.currentPage==1){
              this.projectList = list
              this.projectTotal = res.totalSize
            }else{
              this.projectList = this.projectList.concat(list)
            }
          } else {
            this.$Message.error(res.errorMessage)
          }
          this.loading = false
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
          this.loading = false;
        });
    },
    // 筛选条件
    getConditions(){
      let pushCompany = null, pushCode = null
      if(this.form.pushCompany == "其他"){
        pushCompany = null;
        pushCode = "无";
      }else{
        pushCompany = this.form.pushCompany;
        pushCode = null;
      }
      return {
        page: this.currentPage - 1,
        size: 20,
        sort: "id,desc",
        "name.contains": this.form.name || null,
        "type.eq": this.form.type,
        "subType.eq": this.form.subType,
        "pushCompany.eq": pushCompany || null,
        "pushCode.eq" : pushCode || null,
        "checkStatus.eq": 'SUCCESS',
        // "firstStatus.eq": 'CHECKING',
        "stage.eq": "FIRST"
      };
    },

    // 选中评委（to left / to right）
    handleJudge(target){
      this.judgeListTarget = target
    },
    // 加载更多评委
    loadMoreJudge(){
      this.currentPageJudge += 1
      this.getJudgeList()
    },
    // 获取评委列表
    getJudgeList(){
      this.loadingJudge = true;
      let condition = {
        "userType.eq": "JUDGE",
        'competitionId.eq': 0,
        page: this.currentPageJudge - 1,
        size: 20,
        sort: "id,desc",
        "level.eq": 'FIRST'
      }
      JudgeService.getJudges(condition)
        .then(res => {
          if (res.errorNo == 0) {
            let data = res.data, list = []
            for(let i=0,l=data.length;i<l;i++){
              list[i] = {key: data[i].userId, label: data[i].trueName}
            }
            if(this.currentPageJudge==1){
              this.judgeList = list
              this.judgeTotal = res.totalSize
            }else{
              this.judgeList = this.judgeList.concat(list)
            }
          } else {
            this.$Message.error(res.errorMessage)
          }
          this.loadingJudge = false
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
          this.loadingJudge = false;
        });
    }
  }
}
</script>

<style lang="scss">
.distribution{
  .titleBg{
    background: #eee;
  }
  .numBtn{
    color: #277a44;
    cursor: pointer;
    padding: 0 10px;
  }
  .noData{
    text-align: center;
    color: #c5c8ce;
    padding: 10px 0;
  }
  .cursorDefault{
    cursor: default;
  }
  .ivu-transfer-list-with-footer{
    padding-bottom: 45px;
  }
  .moreBtn{
    background: #277a44;
    color: #fff;
  }
  .projectWrap{
    padding-top: 20px;
  }
  .moreBtnWrap{
    text-align: right;
    padding: 5px 10px;
    .text{
      display: inline-block;
      padding: 7px 15px 7px 0;
      color: #ccc;
    }
  }
  .btnWrap{
    text-align: center;
    padding: 50px 0 30px;
  }
  .mgBtm{
    margin-bottom: 15px;
  }
}
</style>