<template>
  <div class="project">
    <p class="pageTitle">项目审核管理</p>
    <div class="pageContent">
      <div class="formBox">
        <Form :model="form" label-position="right" :label-width="80">
          <Row :gutter="20">
            <Col span="8">
              <FormItem label="项目名称">
                <Input v-model="form.name" clearable @on-enter="search"></Input>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="参评类别">
                <Select v-model="form.type" clearable @on-change="changeType">
                  <Option value="建筑应用创新综合类">建筑应用创新综合类</Option>
                  <Option value="建筑应用创新单项类">建筑应用创新单项类</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="8" v-if="form.type=='建筑应用创新单项类'">
              <FormItem label="" :label-width="0">
                <Select v-model="form.subType" clearable>
                  <Option value="建筑材料应用创新类">建筑材料应用创新类</Option>
                  <Option value="装配式建筑应用创新类">装配式建筑应用创新类</Option>
                  <Option value="建筑部品部件应用创新类">建筑部品部件应用创新类</Option>
                  <Option value="设备设施应用创新类">设备设施应用创新类</Option>
                  <Option value="其它应用创新类">其它应用创新类</Option>
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row :gutter="20">
            <Col span="8">
              <FormItem label="项目提交日期" :label-width="108">
                <DatePicker v-model="dateList[0]" format="yyyy-MM-dd" placeholder="选择日期" style="width:100%"></DatePicker>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="至" :label-width="26">
                <DatePicker v-model="dateList[1]" format="yyyy-MM-dd" placeholder="选择日期" style="width:100%"></DatePicker>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="参评单位">
                <Input v-model="form.regName" clearable @on-enter="search"></Input>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="完成时间">
                <DatePicker v-model="form.completionDate" type="month" format="yyyy-MM" placeholder="选择日期" style="width:100%"></DatePicker>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="推荐单位">
                <Select v-model="form.pushCompany" clearable :disabled="isDisabled">
                  <Option :value="p.value" v-for="p in getPushCompany()" :key='p.id'>{{p.label}}</Option>
                  <Option value="其他">其他</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="8" align="right">
              <Button class="defaultBtn noMg" type="primary" @click="search">查询</Button>
            </Col>
          </Row>  
        </Form>  
      </div>
      <Row>
        <Col span="14">
          <span class="tabTitle" :class="{'tabActive': index==0}" @click="changeTab(0)">全部({{total1+total2+total3}})</span>
          <span class="tabTitle" :class="{'tabActive': index==1}" @click="changeTab(1)">待审核({{total1}})</span>
          <span class="tabTitle" :class="{'tabActive': index==2}" @click="changeTab(2)">已通过({{total2}})</span>
          <span class="tabTitle" :class="{'tabActive': index==3}" @click="changeTab(3)">未通过({{total3}})</span>
        </Col>
        <Col span="10" align="right">
          <Button class="defaultBtn oneBtn" @click="checkTogether">批量审核</Button>
          <Button class="defaultBtn oneBtn" @click="changeShowWay">切换显示方式</Button>
          <Button class="defaultBtn" @click="resetForm">显示全部数据</Button>
        </Col>
      </Row>
      <div class="line">
        <template v-if="showWay=='img'">
          <CheckboxGroup v-model="selectionImg" @on-change="selectionImgChange">
            <Row :gutter="15" v-if="!loading">
              <Col class="mgTop" span="6" v-for="(p, i) in list" :key="p.id">
                <div class="item">
                  <div class="imgWrap">
                    <router-link class="block" :to="`/admin/project_info?id=${p.id}`">
                      <img v-if="p.cover" class="img" :src="getImageUrl(p.cover)" @load="gCenterImg" alt="">
                      <img v-else class="img" src="/img/1.png" @load="gCenterImg($event, 1)" alt="">
                    </router-link>
                    <Checkbox class="checkbox" :label="i"></Checkbox>
                    <div class="statusWrap">
                      <span class="statusBg" :class="{succBg: p.checkStatus=='SUCCESS', failBg: p.checkStatus=='FAILED'}"></span>
                      <span class="statusText">{{getStatusText(p.checkStatus)}}</span>
                    </div>
                  </div>
                  <div class="info">
                    <div class="clearfix">
                      <div class="label">项目名称：</div>
                      <div class="content"><router-link class="colorGreen" :to="`/admin/project_info?id=${p.id}`">{{p.name||'-'}}</router-link></div>
                    </div>
                    <div class="clearfix">
                      <div class="label">参评单位：</div>
                      <div class="content"><router-link class="colorGreen" :to="`/admin/basic_info?id=${p.regId}`">{{p.regInfo.name}}</router-link></div>
                    </div>
                    <div class="clearfix">
                      <div class="label">参评类别：</div>
                      <div class="content">{{p.type||'-'}}<template v-if="p.subType"> - {{p.subType}}</template></div>
                    </div>
                    <div class="btnWrap">
                      <Button v-if="p.checkStatus!='SUCCESS'" class="succBtn mgLRBtn" @click="checkProject(i, 'SUCCESS')">通过审核</Button>
                      <Button v-if="p.checkStatus!='FAILED'" class="failedBtn mgLRBtn" @click="checkProject(i, 'FAILED')">不通过审核</Button>
                      <Button v-if="p.checkStatus!='CHECKING'" class="checkBtn mgLRBtn" @click="checkProject(i, 'CHECKING')">待审核</Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </CheckboxGroup>
        </template>
        <template v-if="showWay=='table'">
          <Table :data="list" :columns="columns" @on-selection-change="selectionChange" :loading="loading" stripe></Table>
        </template>
        <div v-if="loading" class="note">
          <Icon class="loading" type="ios-loading" />
          <p>加载中...</p>
        </div>
        <div class="note" v-if="!loading&&showWay=='img'&&index==0&&(total1+total2+total3)==0||index==1&&total1==0||index==2&&total2==0||index==3&&total3==0">暂时没有符合筛选条件的项目</div>
      </div>
      <div class="pageWrap">
        <Page v-if="index==0&&(total1+total2+total3)>0" :total="total1+total2+total3" :page-size="16" :current="currentPage" show-total @on-change="changePage"></Page>
        <Page v-if="index==1&&total1>0" :total="total1" :page-size="16" :current="currentPage" show-total @on-change="changePage"></Page>
        <Page v-if="index==2&&total2>0" :total="total2" :page-size="16" :current="currentPage" show-total @on-change="changePage"></Page>
        <Page v-if="index==3&&total3>0" :total="total3" :page-size="16" :current="currentPage" show-total @on-change="changePage"></Page>
      </div>
    </div>
    <Modal v-model="isTogether" title="批量审核" footer-hide :mask-closable="false">
      <p>请对您所选择的{{getLength}}条数据进行以下批量操作：</p>
      <div v-if="getLength>0" class="modalBtnWrap">
        <Button v-if="index!=2" class="modalBtn succBtn" :loading="submitting" @click="checkProjects('SUCCESS')">通过审核</Button>
        <Button v-if="index!=3" class="modalBtn failedBtn" :loading="submitting" @click="checkProjects('FAILED')">不通过审核</Button>
        <Button v-if="index!=1" class="modalBtn checkBtn" :loading="submitting" @click="checkProjects('CHECKING')">待审核</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import CheckService from "@/api/check";
export default {
  data() {
    return {
      userInfo: this.$store.getters.userInfo || {},
      isDisabled: false,
      form: {},
      dateList: [],
      showWay: 'img',

      index: 0,
      loading: false,
      currentPage: 1,
      list: [],
      total1: 0,
      total2: 0,
      total3: 0,

      selectionImg: [],
      selection: [],
      isTogether: false,
      submitting: false,
      isAllow: true,
      
      columns: [
        {
          type: "selection",
          width: 60,
          align: "center",
        },
        {
          title: "项目编号",
          align: "center",
          key: 'projectSn'
        },
        {
          title: "项目名称",
          align: "center",
          render: (h, params) => {
            return h("router-link", {
              props: {
                to: `/admin/project_info?id=${params.row.id}`,
              },
              class: 'colorGreen'
            }, params.row.name||'-');
          }
        },
        {
          title: "参评单位",
          align: "center",
          render: (h, params) => {
            return h("router-link", {
              props: {
                to: `/admin/basic_info?id=${params.row.regId}`,
              },
              class: 'colorGreen'
            }, params.row.regInfo.name||'-');
          }
        },
        {
          title: "参评类别",
          align: "center",
          render: (h, params) => {
            let text = params.row.type;
            if (params.row.subType) {
              text += " - " + params.row.subType;
            }
            return h("div", text);
          }
        },
        {
          title: "完成时间",
          align: "center",
          key: 'completionDate'
        },
        {
          title: "审核状态",
          key: "checkStatus",
          align: "center",
          render: (h, params) => {
            let status = params.row.checkStatus,
              statusText, color;
            if (status == "CHECKING") {
              statusText = "待审核";
              color = ''
            } else if (status == "SUCCESS") {
              statusText = "已通过";
              color = '#FFAC00'
            } else if (status == "FAILED") {
              statusText = "未通过";
              color = '#ed0606'
            }
            return h("div", {
              style: {color}
            }, statusText);
          }
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          width: 100,
          render: (h, params) => {
            let btn1 = h(
                "Button",
                {
                  props: {
                    type: "text",
                    size: "small"
                  },
                  class: "textBtn",
                  on: {
                    click: () => {
                      this.$router.push(
                        `/admin/project_info?id=${params.row.id}`
                      );
                    }
                  }
                },
                "查看详情"
              ),
              btn2 = h(
                "Button",
                {
                  props: {
                    type: "text",
                    size: "small"
                  },
                  class: "textBtn",
                  on: {
                    click: () => {
                      this.checkProject(params.index, "SUCCESS");
                    }
                  }
                },
                "通过审核"
              ),
              btn3 = h(
                "Button",
                {
                  props: {
                    type: "text",
                    size: "small"
                  },
                  class: "textBtn",
                  on: {
                    click: () => {
                      this.checkProject(params.index, "FAILED");
                    }
                  }
                },
                "不通过审核"
              ),
              btn4 = h(
                "Button",
                {
                  props: {
                    type: "text",
                    size: "small"
                  },
                  class: "textBtn",
                  on: {
                    click: () => {
                      this.checkProject(params.index, "CHECKING");
                    }
                  }
                },
                "待审核"
              );
            let status = params.row.checkStatus;
            if (status == "CHECKING") {
              return h("div", [btn1, btn2, btn3]);
            }else if(status=='SUCCESS'){
              return h("div", [btn1, btn3, btn4]);
            } else if(status=='FAILED'){
              return h("div", [btn1, btn2, btn4]);
            } else {
              return h("div", [btn1]);
            }
          }
        }
      ]
    };
  },
  beforeRouteLeave(to, from, next) {
    from.meta.scrollTop = document.getElementsByClassName(
      "pageWrap"
    )[0].scrollTop;
    next();
  },
  mounted() {
    if(this.userInfo.accountType != "CONTESTSUPERADMIN" && this.userInfo.competitionUser.pushCompany){
      this.form.pushCompany = this.userInfo.competitionUser.pushCompany
      this.isDisabled = true
    }
    let regId = this.$route.query.regId
    if(regId){
      this.form.regId = regId
    }
    this.getList();
    this.getAllTotal();
  },
  computed:{
    getLength(){
      if(this.showWay=='img'){
        return this.selectionImg.length
      }else{
        return this.selection.length
      }
    }
  },
  methods: {
    // 选择参评类别
    changeType(val){
      if(val==undefined || val=='建筑应用创新综合类'){
        this.form.subType = null
      }
    },
    selectionImgChange(data){
      let status = null
      for(let i=0,l=data.length;i<l;i++){
        if(i==0){
          status = this.list[data[i]].checkStatus
          this.isAllow = true
        }else{
          if(status==this.list[data[i]].checkStatus){
            this.isAllow = true
          }else{
            this.isAllow = false
            return
          }
        }
      }
    },
    // 监测表格中内容选中变化
    selectionChange(data) {
      this.selection = data;
      let status = null
      for(let i=0,l=data.length;i<l;i++){
        if(i==0){
          status = data[i].checkStatus
          this.isAllow = true
        }else{
          if(status==data[i].checkStatus){
            this.isAllow = true
          }else{
            this.isAllow = false
            return
          }
        }
      }
    },
    // 切换显示方式
    changeShowWay(){
      if(this.showWay=='img'){
        this.showWay = 'table'
        this.selection = []
      }else{
        this.showWay = 'img'
        this.selectionImg = []
      }
    },
    // 显示全部数据
    resetForm() {
      this.form = {};
      this.dateList = [];
      this.currentPage = 1;
      this.getList();
      this.getAllTotal();
    },
    // 批量审核
    checkProjects(status) {
      let ids = []
      if(this.showWay=='img'){
        for(let i=0,l=this.selectionImg.length;i<l;i++){
          ids.push(this.list[this.selectionImg[i]].id)
        }
      }else{
        for(let i=0,l=this.selection.length;i<l;i++){
          ids.push(this.selection[i].id)
        }
      }
      let condition = {ids, checkStatus:status}
      this.submitting = true;
      CheckService.checkAllProject(condition)
        .then(res => {
          if (res.errorNo == 0) {
            this.getList()
            this.getAllTotal();
            this.submitting = false;
            this.isTogether = false
            this.$Message.success("操作成功");
          } else {
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    },
    // 批量审核
    checkTogether() {
      if (this.getLength > 0) {
        if(this.isAllow){
          this.isTogether = true;
        }else{
          this.$Modal.info({
            title: '提示',
            content: '您选择的数据中审核状态不一致，不能进行批量操作。请选择审核状态一致的数据进行批量审核。'
          })
        }
      } else {
        this.$Message.info("请先选择要操作的内容");
      }
    },
    // 获取项目审核状态文字
    getStatusText(s) {
      if (s == "SUCCESS") {
        return "已通过";
      }
      if (s == "FAILED") {
        return "未通过";
      }
      return "待审核";
    },
    // 审核
    checkProject(i, status) {
      CheckService.checkProject({
        projectId: this.list[i].id,
        checkStatus: status
      })
        .then(res => {
          if (res.errorNo == 0) {
            let oldstatus = this.list[i].checkStatus;
            if (oldstatus == "CHECKING" && status == "SUCCESS") {
              this.total1 -= 1;
              this.total2 += 1;
            } else if (oldstatus == "CHECKING" && status == "FAILED") {
              this.total1 -= 1;
              this.total3 += 1;
            } else if (oldstatus == "SUCCESS" && status == "FAILED") {
              this.total2 -= 1;
              this.total3 += 1;
            } else if (oldstatus == "FAILED" && status == "SUCCESS") {
              this.total2 += 1;
              this.total3 -= 1;
            }
            this.list[i].checkStatus = status;
            this.$Message.success("操作成功");
          } else {
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    },
    // 获取数量
    getAllTotal() {
      this.getTotal("CHECKING", 1);
      this.getTotal("SUCCESS", 2);
      this.getTotal("FAILED", 3);
    },
    getTotal(status, i) {
      let condition = this.getConditions(0, 1, status)
      CheckService.getProjects(condition)
        .then(res => {
          if (res.errorNo == 0) {
            this[`total${i}`] = res.totalSize;
          } else {
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    },
    // 切换标签页
    changeTab(val) {
      if (val == this.index) {
        return;
      }
      this.index = val;
      this.currentPage = 1;
      this.getList();
    },
    // 获取列表
    getList() {
      this.selection = [];
      this.selectionImg = [];
      this.loading = true;
      let status;
      if (this.index == 3) {
        status = "FAILED";
      } else if (this.index == 2) {
        status = "SUCCESS";
      } else if (this.index == 1) {
        status = "CHECKING";
      } else {
        status = null;
      }
      let condition = this.getConditions(this.currentPage-1, 16, status)
      if (status == null) {
        condition["checkStatus.ne"] = "EMPTY";
      }
      CheckService.getProjects(condition)
        .then(res => {
          if (res.errorNo == 0) {
            this.list = res.data;
          } else {
            this.$Message.error(res.errorMessage);
          }
          this.loading = false;
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
          this.loading = false;
        });
    },
    // 筛选条件
    getConditions(page, size, status){
      let completionDate = null
      if (
        this.form.completionDate &&
        typeof this.form.completionDate != "string"
      ) {
        let dt = this.form.completionDate;
        let sYear = dt.getFullYear() + "";
        let sMonth =
          dt.getMonth() + 1 < 10
            ? "0" + (dt.getMonth() + 1)
            : dt.getMonth() + 1 + "";
        completionDate = sYear + "-" + sMonth;
      }
      let pushCompany = null, pushCode = null
      if(this.form.pushCompany == "其他"){
        pushCompany = null;
        pushCode = "无";
      }else{
        pushCompany = this.form.pushCompany;
        pushCode = null;
      }
      return {
        page,
        size,
        sort: "id,desc",
        "name.contains": this.form.name || null,
        "type.eq": this.form.type || null,
        "subType.eq": this.form.subType || null,
        "completionDate.eq": completionDate,
        "pushCompany.eq": pushCompany || null,
        "pushCode.eq" : pushCode || null,
        "createdDate.ge": this.dateList[0] || null,
        "createdDate.le": this.dateList[1] || null,
        "checkStatus.eq": status,
        "regName.contains": this.form.regName || null,
        "regId.eq": this.form.regId || null
      };
    },
    // 查询
    search() {
      this.currentPage = 1;
      if (this.dateList[0] && typeof this.dateList[0] != "string") {
        let dt = this.dateList[0];
        // console.log(dt)
        // dt.setHours(dt.getHours() + 8)
        let sYear = dt.getFullYear() + "";
        let sMonth =
          dt.getMonth() + 1 < 10
            ? "0" + (dt.getMonth() + 1)
            : dt.getMonth() + 1 + "";
        let sDate = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate() + "";
        let dNew = sYear + "-" + sMonth + "-" + sDate;
        // let dNew = sYear + '-' + sMonth
        // this.info.completionDate = dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate()
        this.dateList[0] = dNew;
      }
      if (this.dateList[1] && typeof this.dateList[1] != "string") {
        let dt = this.dateList[1];
        // console.log(dt)
        // dt.setHours(dt.getHours() + 8)
        let sYear = dt.getFullYear() + "";
        let sMonth =
          dt.getMonth() + 1 < 10
            ? "0" + (dt.getMonth() + 1)
            : dt.getMonth() + 1 + "";
        let sDate = dt.getDate() + 1 < 10 ? "0" + (dt.getDate() + 1) : (dt.getDate() + 1) + "";
        let dNew = sYear + "-" + sMonth + "-" + sDate;
        // let dNew = sYear + '-' + sMonth
        // this.info.completionDate = dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate()
        this.dateList[1] = dNew;
      }
      this.getList();
      this.getAllTotal();
    },
    changePage(p) {
      this.currentPage = p;
      this.getList();
      document.getElementsByClassName("pageWrap")[0].scrollTop = 0;
    }
  },
  watch:{
    '$route':function(to, from){
      if(from.name=='CheckBasic' && to.name=='CheckProject'){
        this.form = {};
        this.dateList = [];
        this.index = 0;
        this.loading = false;
        this.currentPage = 1;
        this.list = [];
        this.total1 = 0;
        this.total2 = 0;
        this.total3 = 0;
        this.selection = [];
        this.selectionImg = [];
        this.showWay = 'img';
        this.isTogether = false;
        this.submitting = false;
        this.isAllow = true;

        if(this.userInfo.accountType != "CONTESTSUPERADMIN" && this.userInfo.competitionUser.pushCompany){
          this.form.pushCompany = this.userInfo.competitionUser.pushCompany
          this.isDisabled = true
        }
        let regId = this.$route.query.regId
        if(regId){
          this.form.regId = regId
        }
        this.getList();
        this.getAllTotal();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.project {
  .tabTitle {
    display: inline-block;
    padding: 15px 10px;
    margin-right: 15px;
    cursor: pointer;
  }
  .tabActive {
    cursor: default;
    position: relative;
    color: #277a44;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 3px;
      background: #277a44;
    }
  }
  .cancelBtn {
    background: #eeeeee;
    color: #3f4641;
    margin-right: 15px;
  }
  .mgTop {
    margin-top: 20px;
  }
  .item {
    box-shadow: 0 7px 15px rgba($color: #000000, $alpha: 0.2);
    border-radius: 10px;
    height: 100%;
    overflow: hidden;
    position: relative;
    padding-bottom: 52px;
    .imgWrap {
      position: relative;
      overflow: hidden;
      .block{
        display: block;
        position: relative;
        padding-bottom: 60%;
        overflow: hidden;
      }
      .img {
        position: absolute;
        left: 0;
        top: 0;
      }
      .checkbox {
        position: absolute;
        top: 10px;
        left: 10px;
      }
      .statusWrap {
        position: absolute;
        top: 0;
        right: 0;
        color: #fff;
        .statusText {
          position: absolute;
          top: 15px;
          right: 0;
          font-size: 12px;
          width: 38px;
        }
        .statusBg {
          position: absolute;
          top: 0;
          right: 0;
          border: 35px solid transparent;
          border-top: 35px solid #277a44;
          border-right: 35px solid #277a44;
        }
        .succBg{
          border-top-color: #FFAC00;
          border-right-color: #FFAC00;
        }
        .failBg{
          border-top-color: #ed0606;
          border-right-color: #ed0606;
        }
      }
    }
    .info {
      padding: 15px 20px;
      .label {
        float: left;
        width: 70px;
      }
      .content {
        margin-left: 70px;
        color: #777;
      }
    }
    .btnWrap {
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      bottom: 10px;
    }
  }
  .note {
    padding: 30px 0;
    text-align: center;
  }
  .loading {
    animation: k-loading 1000ms infinite;
  }
  @keyframes k-loading {
    0 {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
.modalBtnWrap {
  text-align: center;
  padding: 30px 0;
  .modalBtn {
    color: #fff;
    margin: 0 10px;
  }
}
.oneBtn {
  margin-right: 15px !important;
}
.succBtn {
  background: #FFAC00;
}
.failedBtn {
  background: #ed0606;
}
.checkBtn{
  background: #277a44;
}
.mgLRBtn{
  margin: 10px 5px;
  color: #fff;
}
.noMg{
  margin: 0;
}
</style>
