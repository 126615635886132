<template>
<div class="userList">
  <p class="pageTitle">报名设置</p>
  <Form ref="form" :model="form" :rules="rules" label-position="right" :label-width="150">
    <Row>
      <Col span="12">
        <FormItem label="报名提交截止日期" prop="addEndTime">
          <DatePicker v-model="form.addEndTime" format="yyyy-MM-dd" placeholder="选择日期" style="width:100%" :clearable="false" :editable="false" @on-change="form.addEndTime=$event"></DatePicker>
        </FormItem>
      </Col>
    </Row>
    <Row>
      <Col span="12">
        <FormItem label="信息修改截止日期" prop="editEndTime">
          <DatePicker v-model="form.editEndTime" format="yyyy-MM-dd" placeholder="选择日期" style="width:100%" :clearable="false" :editable="false" @on-change="form.editEndTime=$event"></DatePicker>
        </FormItem>
      </Col>
      <Col offset="2" span="10">
        <Button class="submitBtn" :loading="submitting" @click="submit">提交</Button>
      </Col>
    </Row>
  </Form>
</div>
</template>

<script>
import Service from "@/api/setEndDate";
export default {
  data(){
    return{
      form: {},
      rules: {
        addEndTime: [{required: true, message: '请选择报名提交截止日期',
            validator: (rule, value) => {
              if (!value) {
                return false;
              }
              return true;
            }, trigger: "blur,change"}],
        editEndTime: [{required: true, message: '请选择信息修改截止日期',
            validator: (rule, value) => {
              if (!value) {
                return false;
              }
              return true;
            }, trigger: "blur,change"}]
      },
      submitting: false
    }
  },
  mounted(){
    this.getEndDate()
  },
  methods: {
    // 获取时间
    getEndDate(){
      Service.getEndDate(0)
        .then(res => {
          if (res.errorNo == 0) {
            // 提交成功执行
            this.form = res.data || {}
          } else {
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    },
    // 提交
    submit(){
      this.$refs.form.validate(valid => {
        if (valid) {
          console.log(this.form.addEndTime)
          console.log(this.form.editEndTime)
          if (
            this.form.addEndTime &&
            typeof this.form.addEndTime != "string"
          ) {
            let dt = this.form.addEndTime;
            // console.log(dt)
            dt.setHours(dt.getHours() + 8)
            // let sYear = dt.getFullYear() + "";
            // let sMonth =
            //   dt.getMonth() + 1 < 10
            //     ? "0" + (dt.getMonth() + 1)
            //     : dt.getMonth() + 1 + "";
            // let sDate = dt.getDate() < 10 ? '0' + dt.getDate() : dt.getDate() + ''
            // let dNew = sYear + '-' + sMonth + '-' + sDate
            // let dNew = sYear + "-" + sMonth;
            // this.form.addEndTime = dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate()
            this.form.addEndTime = dt;
          }

          if (
            this.form.editEndTime &&
            typeof this.form.editEndTime != "string"
          ) {
            let dt = this.form.editEndTime;
            // console.log(dt)
            dt.setHours(dt.getHours() + 8)
            // let sYear = dt.getFullYear() + "";
            // let sMonth =
            //   dt.getMonth() + 1 < 10
            //     ? "0" + (dt.getMonth() + 1)
            //     : dt.getMonth() + 1 + "";
            // let sDate = dt.getDate() < 10 ? '0' + dt.getDate() : dt.getDate() + ''
            // let dNew = sYear + '-' + sMonth + '-' + sDate
            // let dNew = sYear + "-" + sMonth;
            // this.form.editEndTime = dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate()
            this.form.editEndTime = dt;
          }
          this.submitting = true;
          Service.setEndDate(this.form)
            .then(res => {
              if (res.errorNo == 0) {
                // 提交成功执行
                this.$Message.success('设置成功')
              } else {
                this.$Message.error(res.errorMessage);
              }
              this.submitting = false;
            })
            .catch(() => {
              this.$Message.error("连接服务器失败，请稍后再试");
              this.submitting = false;
            });
        } else {
          this.$Message.error("信息未正确填写完整");
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.submitBtn, .submitBtn:hover {
  background: #277a44;
  color: #fff;
}
</style>