<template>
  <div class="userModal">
    <Form ref="form" :model="form" :rules="rules" :label-width="100">
      <FormItem label="评委账号" prop="loginName">
        <Input v-model="form.loginName" placeholder="请输入英文或数字" />
      </FormItem>
      <FormItem label="评委姓名" prop="trueName">
        <Input v-model="form.trueName" placeholder="请输入评委姓名" />
      </FormItem>
      <FormItem label="评委头像" prop="avatar">
        <Upload
          ref="avatarFile"
          :action="imgApi"
          :headers="{Authorization: token}"
          name="picture"
          :data="{applyType: 'AVATAR'}"
          :show-upload-list="false"
          :on-success="handleSuccess"
          :format="['jpg','jpeg']"
          :max-size="10240"
          :before-upload="handleBeforeUpload"
          :on-format-error="handleFormatError"
          :on-exceeded-size="handleMaxSize"
          :on-progress="handleProgress"
          accept="image/jpg, image/jpeg">
          <Button class="defaultBtn">{{form.avatar?'重新上传':'选择文件'}}</Button>
        </Upload>
        <span class="note">文件格式：“.JPG”；文件大小：不大于10M</span>
        <div v-if="avatarList.length>0">
          <div class="imgItem" v-for="(item, i) in avatarList" :key="i">
            <template v-if="item.status == 'finished' || item.status==null">
              <img class="img" :src="getImageUrl(item.path, 'square-small')" alt="">
            </template>
            <template v-else>
              <Progress v-if="item.showProgress" :percent="item.percentage" hide-info></Progress>
            </template>
          </div>
        </div>
      </FormItem>
      <FormItem label="密码" prop="password">
        <Input type="password" password v-model="form.password" placeholder="8-16位字符，包含字母和数字" />
      </FormItem>
      <FormItem label="确认密码" prop="confirmPassword">
        <Input type="password" password v-model="form.confirmPassword" placeholder="8-16位字符，包含字母和数字" />
      </FormItem>
      <FormItem label="评审权限" prop="level">
        <Select v-model="form.level" placeholder="请选择评审权限" clearable>
          <Option value="FIRST">初审评委</Option>
          <Option value="FINAL">终审评委</Option>
        </Select>
      </FormItem>
      <FormItem label="所属推荐单位" prop="pushCompany">
        <Select v-model="form.pushCompany" placeholder="请选择所属推荐单位" clearable>
          <Option :value="p.value" v-for="p in getPushCompany()" :key='p.id'>{{p.label}}</Option>
        </Select>
      </FormItem>
      <FormItem label="任职机构">
        <Input v-model="form.company" placeholder="请输入任职机构名称" />
      </FormItem>
      <FormItem label="职称">
        <Input v-model="form.job" placeholder="请输入职称" />
      </FormItem>
      <FormItem label="手机号">
        <Input v-model="form.mobilePhone" placeholder="请输入手机号" />
      </FormItem>
      <FormItem label="邮箱">
        <Input v-model="form.email" placeholder="请输入邮件地址" />
      </FormItem>
      <div class="btnWrap">
        <Button class="submitBtn" :loading="submitting" @click="submit">提交</Button>
        <Button @click="hide">取消</Button>
      </div>
    </Form>
  </div>
</template>

<script>
import UserService from "@/api/user";
import { baseUrl } from "@/config/env";
export default {
  props: ['hide'],
  data(){
    return{
      submitting: false,
      form: { avatar:null },
      rules: {
        loginName: [
          {required: true, message: '请输入评委账号', trigger: "blur,change"},
          {
            required: true,
            message: "需要输入英文或数字",
            pattern: /^[0-9A-Za-z]+$/
          }
        ],
        trueName: [{required: true, message: '请输入评委姓名', trigger: "blur,change"}],
        avatar: [{required: true, message: '请上传评委头像', trigger: "blur,change"}],
        password: [
          {
            required: true, 
            trigger: "blur,change",
            message: "密码需8-16位字符，包含字母和数字",
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/
          }],
        confirmPassword: [
          {required: true, message: '请再次输入密码', trigger: "blur,change"},
          {
            required: true,
            message: "需与密码相同",
            validator: (rule, value) => {
              if (!value || value != this.form.password) {
                return false;
              }
              return true;
            }
          }
        ],
        level: [{required: true, message: '请选择评审权限', trigger: "blur,change"}]
      },
      imgApi: `${baseUrl}picture/upload`,
      token: this.$store.getters.token,
      avatarList: []
    }
  },
  mounted(){
    this.avatarList = []
    this.$refs.avatarFile.fileList = this.avatarList;
  },
  methods:{
    handleBeforeUpload() {
      this.avatarList = []
      this.$refs.avatarFile.fileList = this.avatarList;
      return true;
    },
    handleProgress(event, file, fileList) {
      this.avatarList = fileList;
      // 调用监听 上传进度 的事件
      event.target.onprogress = event => {
        let uploadPercent = parseFloat(
          (event.loaded / event.total * 100).toFixed(2)
        ); // 保留两位小数，具体根据自己需求做更改
        // 手动设置显示上传进度条 以及上传百分比
        file.showProgress = true;
        file.percentage = uploadPercent;
      };
    },
    handleSuccess(res, file) {
      if (res.errorNo) {
        this.$Message.error(res.errorMessage);
      } else {
        let data = res.data;
        if (data.path) {
          file.path = data.path;
          // file.name = data.name;
          // file.fileType = data.fileType;
          // file.size = data.size;
          this.form.avatar = data.path;
        }
        this.$forceUpdate();
      }
    },
    handleFormatError(file) {
      this.$Message.error({
        content: `文件${file.name}格式不正确，请上传jpg,jpeg格式的图片。`,
        duration: 5
      });
    },
    handleMaxSize(file) {
      this.$Message.error({
        content: `文件${file.name}太大，上传图片大小不能超过10M。`,
        duration: 5
      });
    },
    submit(){
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitting = true;
          this.form.competitionId = 0;
          this.form.accountType = "JUDGE";
          UserService.saveCompetitionUser(this.form)
            .then(res => {
              if (res.errorNo == 0) {
                // 提交成功执行
                this.$Message.success('新增评委成功')
                this.$emit('Update', {type: 'new'})
                this.hide()
              } else {
                this.$Message.error(res.errorMessage);
              }
              this.submitting = false;
            })
            .catch(() => {
              this.$Message.error("连接服务器失败，请稍后再试");
              this.submitting = false;
            });
        } else {
          this.$Message.error("信息未正确填写完整");
        }
        
      });
    }
  }
}
</script>