<template>
  <div>
    <p class="pageTitle">获奖项目发布管理</p>
    <div class="pageContent">
      <div class="formBox">
        <Form :model="form" label-position="right" :label-width="110">
          <Row :gutter="20">
            <Col span="6">
              <FormItem label="参评单位类型">
                <Select v-model="form.applicantType" clearable>
                  <Option value="个人">个人</Option>
                  <Option value="院校或科研机构">院校或科研机构</Option>
                  <Option value="企业">企业</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="6">
              <FormItem :label="form.applicantType=='个人'?'参评个人姓名':'参评单位名称'">
                <Input v-model="form.regName" clearable @on-enter="search"></Input>
              </FormItem>
            </Col>
            <Col span="6">
              <FormItem label="推荐单位">
                <Select v-model="form.pushCompany" placeholder="请选择所属推荐单位" clearable>
                  <Option :value="p.value" v-for="p in getPushCompany()" :key='p.id'>{{p.label}}</Option>
                  <Option value="其他">其他</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="6">
              <FormItem label="发布状态">
                <Select v-model="form.status" placeholder="请选择发布状态" clearable>
                  <Option value="PUBLISHED">已发布</Option>
                  <Option value="UNPUBLISHED">未发布</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="6">
              <FormItem label="参评类别">
                <Select v-model="form.type" clearable @on-change="changeType">
                  <Option value="建筑应用创新综合类">建筑应用创新综合类</Option>
                  <Option value="建筑应用创新单项类">建筑应用创新单项类</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="6" v-if="form.type=='建筑应用创新单项类'">
              <FormItem label="" :label-width="0">
                <Select v-model="form.subType" clearable>
                  <Option value="建筑材料应用创新类">建筑材料应用创新类</Option>
                  <Option value="装配式建筑应用创新类">装配式建筑应用创新类</Option>
                  <Option value="建筑部品部件应用创新类">建筑部品部件应用创新类</Option>
                  <Option value="设备设施应用创新类">设备设施应用创新类</Option>
                  <Option value="其它应用创新类">其它应用创新类</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="6">
              <FormItem label="排序方式">
                <Select v-model="form.sort" placeholder="请选择排序方式" clearable>
                  <Option value="finalScore,desc">最终得分降序</Option>
                  <Option value="finalScore,asc">最终得分升序</Option>
                </Select>
              </FormItem>
            </Col>
            <Col :span="form.type=='建筑应用创新单项类'?6:12" align="right">
              <Button class="succBtn" type="primary" @click="search">查询</Button>
            </Col>
          </Row>  
        </Form>  
      </div>
      <Row>
        <Col span="14">
          <Button class="defaultBtn" @click="resetForm">显示全部数据</Button>
        </Col>
        <Col span="10" align="right">
          <Button v-if="show" class="defaultBtn" @click="showTogether">批量操作</Button>
          <!-- <Button v-if="userInfo.accountType=='CONTESTSUPERADMIN'" class="dataBtn" @click="showExport">导出数据</Button> -->
        </Col>
      </Row>
      <div class="line">
        <Table :data="list" :columns="columns" @on-selection-change="selectionChange" :loading="loading" stripe></Table>
        <div class="pageWrap">
          <Page :total="total" :page-size="30" :current="currentPage" show-total @on-change="changePage"></Page>
        </div>
      </div>
    </div>
    <Modal v-model="isExport" title="将数据导出成Excel" footer-hide :mask-closable="false">
      <p class="center">您将导出评审数据共{{selection.length}}条</p>
      <div v-if="selection.length>0" class="btnWrap">
        <Button class="modalBtn succBtn" :loading="submitting" @click="exportData">导出</Button>
        <Button @click="hideExport">取消</Button>
      </div>
    </Modal>
    <Modal v-model="isTogether" title="批量操作" footer-hide :mask-closable="false">
      <p>请问您确定对已选择的{{selection.length}}条数据进行批量操作：</p>
      <div v-if="selection.length>0" class="btnWrap">
        <Button v-if="selection[0].status=='UNPUBLISHED'" class="modalBtn succBtn" :loading="submitting" @click="changeStatus('PUBLISHED')">发布</Button>
        <Button v-if="selection[0].status=='PUBLISHED'" class="modalBtn failedBtn" :loading="submitting" @click="changeStatus('UNPUBLISHED')">取消发布</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import ResultService from "@/api/result";
export default {
  data() {
    return {
      form: {},
      userInfo: this.$store.getters.userInfo || {},

      selection: [],
      isTogether: false,
      submitting: false,
      isExport: false,
      show: false,

      loading: false,
      currentPage: 1,
      list: [],
      total: 0,
      columns: [
        {
          type: "selection",
          width: 60,
          align: "center"
        },
        {
          title: "序号",
          align: "center",
          key: "projectSn"
        },
        {
          title: "参评项目名称",
          align: "center",
          key: "name"
        },
        {
          title: "参评单位/人名称",
          align: "center",
          render: (h, params) => {
            let text = params.row.regInfo.name;
            return h("div", text);
          }
        },
        {
          title: "推荐单位",
          align: "center",
          render: (h, params) => {
            let text = params.row.regInfo.pushCompany;
            return h("div", text);
          }
        },
        {
          title: "参评类别",
          align: "center",
          render: (h, params) => {
            let text = params.row.type;
            if (params.row.subType) {
              text += " - " + params.row.subType;
            }
            return h("div", text);
          }
        },
        {
          title: "最终得分",
          align: "center",
          key: "finalScore",
          width: 100
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          width: 100,
          render: (h, params) => {
            let btn1 = h(
                "Button",
                {
                  props: {
                    type: "text",
                    size: "small"
                  },
                  class: "textBtn",
                  on: {
                    click: () => {
                      this.publishProject(params.index, 'PUBLISHED');
                    }
                  }
                },
                "发布"
              ),
              btn2 = h(
                "Button",
                {
                  props: {
                    type: "text",
                    size: "small"
                  },
                  class: "textBtn",
                  on: {
                    click: () => {
                      this.publishProject(params.index, 'UNPUBLISHED');
                    }
                  }
                },
                "取消发布"
              );
            if (params.row.status == "UNPUBLISHED") {
              return h("div", [btn1]);
            } else {
              return h("div", [btn2]);
            }
          }
        }
      ]
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 选择参评类别
    changeType(val){
      if(val==undefined || val=='建筑应用创新综合类'){
        this.form.subType = null
      }
    },
    // 批量操作
    showTogether() {
      if (this.selection.length > 0) {
        this.isTogether = true;
      } else {
        this.$Message.info("请先选择要操作的内容");
      }
    },
    changeStatus(status) {
      // console.log(status);
      let ids = [];
      for (let i = 0; i < this.selection.length; i++) {
        ids[i] = this.selection[i].id;
      }
      
      let condition = {ids, status:status}
      this.submitting = true;
      ResultService.publishAllProject(condition)
        .then(res => {
          if (res.errorNo == 0) {
            this.getList();
            this.submitting = false;
            this.isTogether = false;
            this.$Message.success("操作成功");
          } else {
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    },
    // 发布项目
    publishProject(i, status){
      ResultService.publishProject(this.list[i].id)
        .then(res => {
          if (res.errorNo == 0) {
            this.list[i].status = status;
            this.$Message.success("操作成功");
          } else {
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    },
    // 导出数据
    showExport() {
      if (this.selection.length > 0) {
        this.isExport = true;
      } else {
        this.$Message.info("请先选择要导出的数据");
      }
    },
    hideExport() {
      this.isExport = false;
    },
    exportData() {
      this.$Message.info("相关功能正在加紧开发中...");
    },
    // 显示全部数据
    resetForm() {
      this.form = {};
      this.currentPage = 1;
      this.getList();
    },
    // 获取列表
    getList() {
      this.loading = true;
      let condition = {
        page: this.currentPage - 1,
        size: 30,
        sort: this.form.sort || "id,desc",
        "applicantType.eq": this.form.applicantType || null,
        "regName.contains": this.form.regName || null,
        "pushCompany.eq": this.form.pushCompany || null,
        "status.eq": this.form.status || null,
        "firstStatus.eq": "FINAL",
        "finalStatus.eq": "APPROVED",
        "stage.eq": "FINAL",
        "checkStatus.eq": "SUCCESS",
        "type.eq": this.form.type || null,
        "subType.eq": this.form.subType || null
      };
      this.selection = [];
      ResultService.getProjectResult(condition)
        .then(res => {
          if (res.errorNo == 0) {
            this.currentPage == 1 && (this.total = res.totalSize);
            this.list = res.data;
            if (this.total > 0 && this.form.status) {
              this.show = true;
            } else {
              this.show = false;
            }
          } else {
            this.$Message.error(res.errorMessage);
          }
          this.loading = false;
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
          this.loading = false;
        });
    },
    // 查询
    search() {
      this.currentPage = 1;
      this.getList();
    },
    changePage(p) {
      this.currentPage = p;
      this.getList();
      document.getElementsByClassName("pageWrap")[0].scrollTop = 0;
    },
    // 监测表格中内容选中变化
    selectionChange(data) {
      this.selection = data;
    }
  }
};
</script>

<style lang="scss" scoped>
.succBtn {
  background: #277a44;
}
.dataBtn {
  background: #eeeeee;
  color: #3f4641;
  margin: 10px 0 10px 15px;
}
.btnWrap {
  text-align: center;
  padding: 30px 0;
}
.modalBtn {
  color: #fff;
  margin: 0 10px;
}
.failedBtn {
  background: #ed0606;
}
</style>
