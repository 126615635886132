<template>
  <div class="export">
    <Form :model="form" label-position="right" :label-width="100">
      <p class="note">请选择/输入导出项目信息数据的筛选条件：</p>
      <Row :gutter="20">
        <Col span="8">
          <FormItem label="项目名称">
            <Input v-model="form.name" clearable></Input>
          </FormItem>
        </Col>
        <Col span="8">
          <FormItem label="参评类别">
            <Select v-model="form.type" clearable @on-change="changeType">
              <Option value="建筑应用创新综合类">建筑应用创新综合类</Option>
              <Option value="建筑应用创新单项类">建筑应用创新单项类</Option>
            </Select>
          </FormItem>
        </Col>
        <Col span="8" v-if="form.type=='建筑应用创新单项类'">
          <FormItem label="" :label-width="0">
            <Select v-model="form.subType" clearable>
              <Option value="建筑材料应用创新类">建筑材料应用创新类</Option>
              <Option value="装配式建筑应用创新类">装配式建筑应用创新类</Option>
              <Option value="建筑部品部件应用创新类">建筑部品部件应用创新类</Option>
              <Option value="设备设施应用创新类">设备设施应用创新类</Option>
              <Option value="其它应用创新类">其它应用创新类</Option>
            </Select>
          </FormItem>
        </Col>
        <Col span="8">
          <FormItem label="参评单位">
            <Input v-model="form.regName" clearable></Input>
          </FormItem>
        </Col>
        <Col span="8">
          <FormItem label="完成时间">
            <DatePicker v-model="dateList[0]" type="month" format="yyyy-MM" placeholder="选择日期" style="width:100%"></DatePicker>
          </FormItem>
        </Col>
        <Col span="8">
          <FormItem label="至" :label-width="26">
            <DatePicker v-model="dateList[1]" type="month" format="yyyy-MM" placeholder="选择日期" style="width:100%"></DatePicker>
          </FormItem>
        </Col>
        <Col span="8">
          <FormItem label="项目提交日期">
            <DatePicker v-model="form.createdDate" format="yyyy-MM-dd" placeholder="选择日期" style="width:100%"></DatePicker>
          </FormItem>
        </Col>
        <Col span="8">
          <FormItem label="推荐单位">
            <Select v-model="form.pushCompany" clearable :disabled="isDisabled">
              <Option :value="p.value" v-for="p in getPushCompany()" :key='p.id'>{{p.label}}</Option>
              <Option value="其他">其他</Option>
            </Select>
          </FormItem>
        </Col>
        <Col span="8">
          <FormItem label="审核状态">
            <Select v-model="form.checkStatus" clearable>
              <Option value="CHECKING">待审核</Option>
              <Option value="SUCCESS">通过审核</Option>
              <Option value="FAILED">未通过审核</Option>
            </Select>
          </FormItem>
        </Col>
        <Col span="8">
          <FormItem label="评审阶段">
            <Select v-model="form.stage" clearable>
              <Option value="FIRST">初审</Option>
              <Option value="FINAL">终审</Option>
            </Select>
          </FormItem>
        </Col>
      </Row>
      <p class="note">请选择要导出数据的字段：</p>
      <div class="boxWrap">
        <CheckboxGroup v-model="form.key">
          <Row :gutter="20">
            <Col span="6"><Checkbox label="id">项目序号</Checkbox></Col>
            <Col span="6"><Checkbox label="projectSn">项目编号</Checkbox></Col>
            <Col span="6"><Checkbox label="projectName">项目名称</Checkbox></Col>
            <Col span="6"><Checkbox label="typeName">参评类别</Checkbox></Col>
            <Col span="6"><Checkbox label="companyName">参评单位</Checkbox></Col>
            <Col span="6"><Checkbox label="contactName">联系人</Checkbox></Col>
            <Col span="6"><Checkbox label="contactPhone">联系电话</Checkbox></Col>
            <Col span="6"><Checkbox label="contactEmail">电子邮箱</Checkbox></Col>
            <Col span="6"><Checkbox label="pushCompany">推荐单位</Checkbox></Col>
            <Col span="6"><Checkbox label="completionDate">完成时间</Checkbox></Col>
            <Col span="6"><Checkbox label="acceptanceFiles">竣工验收文件</Checkbox></Col>
            <Col span="6"><Checkbox label="address">项目所在地</Checkbox></Col>
            <Col span="6"><Checkbox label="scale">项目规模</Checkbox></Col>
            <Col span="6"><Checkbox label="functionType">功能类型</Checkbox></Col>
            <Col span="6"><Checkbox label="description">项目综述</Checkbox></Col>
            <Col span="6"><Checkbox label="descriptionFiles">项目综述图片</Checkbox></Col>
            <Col span="6"><Checkbox label="creativity">创新性</Checkbox></Col>
            <Col span="6"><Checkbox label="creativityFiles">创新性图片</Checkbox></Col>
            <Col span="6"><Checkbox label="industryLeading">行业引领</Checkbox></Col>
            <Col span="6"><Checkbox label="industryLeadingFiles">行业引领图片</Checkbox></Col>
            <Col span="6"><Checkbox label="healthyProtection">健康环保</Checkbox></Col>
            <Col span="6"><Checkbox label="healthyProtectionFiles">健康环保图片</Checkbox></Col>
            <Col span="6"><Checkbox label="benefits">综合效益</Checkbox></Col>
            <Col span="6"><Checkbox label="benefitsFiles">综合效益图片</Checkbox></Col>
            <Col span="6"><Checkbox label="video">宣传视频</Checkbox></Col>
            <Col span="6"><Checkbox label="designPictures">设计图</Checkbox></Col>
            <Col span="6"><Checkbox label="realPictures">实景/实物图</Checkbox></Col>
            <Col span="6"><Checkbox label="innovatePictures">重点突出创新内容的图片</Checkbox></Col>
            <Col span="6"><Checkbox label="standardFiles">产品执行标准</Checkbox></Col>
            <Col span="6"><Checkbox label="patentFiles">专利技术</Checkbox></Col>
            <Col span="6"><Checkbox label="achievementsFiles">科研成果</Checkbox></Col>
            <Col span="6"><Checkbox label="rewardFiles">项目所获奖励</Checkbox></Col>
            <Col span="6"><Checkbox label="evaluateFiles">用户评价报告</Checkbox></Col>
            <Col span="6"><Checkbox label="recommendFiles">推荐单位意见</Checkbox></Col>
            <Col span="6"><Checkbox label="prizeFiles">行业内类似奖项</Checkbox></Col>
            <Col span="6"><Checkbox label="methodFiles">其它证明材料</Checkbox></Col>
            <Col span="6"><Checkbox label="checkStatus">审核状态</Checkbox></Col>
          </Row>
        </CheckboxGroup>  
      </div>
      <div class="btnWrap">
        <Button class="defaultBtn" type="primary" @click="exportData" :loading="loading">导出数据</Button>
      </div>
    </Form>
  </div>
</template>

<script>
import ExportService from "@/api/exportExcel";
export default {
  data() {
    return {
      userInfo: this.$store.getters.userInfo || {},
      isDisabled: false,
      form: {key: ['projectSn', 'projectName', 'typeName', 'companyName']},
      dateList: [],
      loading: false
    }
  },
  mounted() {
    if(this.userInfo.accountType != "CONTESTSUPERADMIN" && this.userInfo.competitionUser.pushCompany){
      this.form.pushCompany = this.userInfo.competitionUser.pushCompany
      this.isDisabled = true
    }
  },
  methods:{
    // 选择参评类别
    changeType(val){
      if(val==undefined || val=='建筑应用创新综合类'){
        this.form.subType = null
      }
    },
    // 导出数据
    exportData(){
      // this.$Message.info('功能正在开发中，请耐心等待...')
      let data = Object.assign({}, this.form),
        date = [this.getShortDate(this.dateList[0], 'm'), this.getShortDate(this.dateList[1], 'm')]
      if(data.pushCompany == "其他"){
        data.pushCompany = null;
        data.pushCode = "无";
      }
      let serveData = {
        "name.contains": data.name || null,
        "type.eq": data.type || null,
        "subType.eq": data.subType || null,
        "createdDate.ge": this.getShortDate(data.createdDate),
        "createdDate.le": this.getShortDateNextDay(data.createdDate),
        "pushCompany.eq": data.pushCompany || null,
        "pushCode.eq" : data.pushCode || null,
        "completionDates.ge": date[0] || null,
        "completionDates.le": date[1] || null,
        "checkStatus.eq": data.checkStatus,
        "regName.contains": data.regName || null,
        "stage.eq": data.stage || null,
        "columns": data.key.join(",")
      }
      if(data.stage=='FINAL'){
        serveData["firstStatus.eq"] = "FINAL"
        serveData["checkStatus.eq"] = "SUCCESS"
      }else if(data.stage=='FIRST'){
        serveData["checkStatus.eq"] = "SUCCESS"
      }
      // console.log(serveData)
      // console.log(JSON.stringify(serveData))
      this.loading = true;
      ExportService.exportProjects(serveData).then(res => {
        // console.log(res)
        this.loading = false;
        // let url = window.URL.createObjectURL(new Blob([res], {type: "application/vnd.ms-excel;charset=utf-8"}));
        // let link = document.createElement("a");
        // link.style.display = "none";
        // link.href = url;
        //   // link.download = "cbmea_project.xlsx";
        // link.setAttribute("download", "cbmea_project.xlsx"); //指定下载后的文件名，防跳转
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link)
        // window.URL.revokeObjectURL(url)
        this.readFileDownload(res, "项目信息_" + this.getShortDate(new Date()) + ".xlsx");
      })
      .catch(() => {
        this.loading = false;
        this.$Message.error("连接服务器失败，请稍后再试");
      });

    },
    getShortDate(date, key){
      if (date && typeof date != "string") {
        let sYear = date.getFullYear() + "";
        let sMonth =
          date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1 + "";
        let sDate = date.getDate() < 10 ? "0" + date.getDate() : date.getDate() + "";
        if(key=='m'){
          return sYear + "-" + sMonth
        }
        return sYear + "-" + sMonth + "-" + sDate;
      }
      return null
    },
    getShortDateNextDay(date, key){
      if (date && typeof date != "string") {
        let sYear = date.getFullYear() + "";
        let sMonth =
          date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1 + "";
        let sDate = date.getDate() + 1 < 10 ? "0" + (date.getDate() + 1) : (date.getDate() + 1) + "";
        if(key=='m'){
          return sYear + "-" + sMonth
        }
        return sYear + "-" + sMonth + "-" + sDate;
      }
      return null
    }
  }
}
</script>

<style lang="scss" scoped>
.export{
  .note{
    padding: 5px 0 10px;
  }
  .boxWrap{
    padding-left: 30px;
    line-height: 2em;
  }
  .btnWrap{
    text-align: center;
    padding: 40px 0 20px;
  }
}
</style>