<template>
  <div class="newsList">
    <p class="pageTitle">新闻管理</p>
    <Button class="defaultBtn" id="rightBtn" to="/admin/news_form">添加新闻</Button>
    <div class="pageContent">
      <div class="formBox">
        <Form :model="form" label-position="right" :label-width="100">
          <Row :gutter="20">
            <Col span="12">
              <FormItem label="新闻标题">
                <Input v-model="form.title" clearable @on-enter="search"></Input>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="新闻分类">
                <Select v-model="form.type" clearable>
                  <Option value="大奖头条">大奖头条</Option>
                  <Option value="官方动态">官方动态</Option>
                  <Option value="媒体报道">媒体报道</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="12">
              <FormItem label="显示/隐藏">
                <Select v-model="form.isHide" clearable>
                  <Option :value="0">显示</Option>
                  <Option :value="1">隐藏</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="12" align="right">
              <Button class="defaultBtn checkBtn" type="primary" @click="search">查询</Button>
            </Col>
          </Row>  
        </Form>  
      </div>
      <p><Button class="defaultBtn" @click="resetForm">显示全部数据</Button></p>
      <Table :data="list" :columns="columns" :loading="loading" stripe></Table>
      <div v-if="total>0" class="pageWrap">
        <Page :total="total" :page-size="30" :current="currentPage" show-total @on-change="changePage"></Page>
      </div> 
    </div>
  </div>
</template>

<script>
import NewsService from "@/api/news";
export default {
  data() {
    return {
      form: {},
      loading: false,
      list: [],
      total: 0,
      currentPage: 1,
      columns: [
        {
          title: "序号",
          key: "id"
        },
        {
          title: "新闻标题",
          key: "title"
        },
        {
          title: "新闻分类",
          key: "type"
        },
        {
          title: "新闻封面",
          key: "cover",
          // width: 120,
          render: (h, params) => {
            if (params.row.cover) {
              return h("img", {
                attrs: {
                  src: this.getImageUrl(params.row.cover, "square-small")
                },
                style: {
                  width: "80px",
                  height: "80px"
                }
              });
            } else {
              return h("div", "-");
            }
          }
        },
        {
          title: "新闻日期",
          key: "startDate",
          render: (h, params) => {
            return h("div", params.row.startDate);
          }
        },
        {
          title: "添加时间",
          key: "createdDate",
          render: (h, params) => {
            return h("div", params.row.createdDate.split("T")[0]);
          }
        },
        {
          title: "是否置顶",
          key: "isTop",
          render: (h, params) => {
            return h("div", params.row.isTop == 1 ? "是" : "否");
          }
        },
        {
          title: "显示/隐藏",
          key: "isHide",
          render: (h, params) => {
            return h("div", params.row.isHide == 0 ? "显示" : "隐藏");
          }
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          width: 90,
          render: (h, params) => {
            let btn1 = h(
                "Button",
                {
                  props: {
                    type: "text",
                    size: "small"
                  },
                  class: "textBtn",
                  on: {
                    click: () => {
                      this.$router.push(`/admin/news_form?id=${params.row.id}`);
                    }
                  }
                },
                "修改"
              ),
              btn2 = h(
                "Button",
                {
                  props: {
                    type: "text",
                    size: "small"
                  },
                  class: "textBtn",
                  on: {
                    click: () => {
                      this.remove(params.index);
                    }
                  }
                },
                "删除"
              ),
              btn3 = h(
                "Button",
                {
                  props: {
                    type: "text",
                    size: "small"
                  },
                  class: "textBtn",
                  on: {
                    click: () => {
                      this.setTop(params.index);
                    }
                  }
                },
                params.row.isTop == 1 ? "取消置顶" : "置顶"
              ),
              btn4 = h(
                "Button",
                {
                  props: {
                    type: "text",
                    size: "small"
                  },
                  class: "textBtn",
                  on: {
                    click: () => {
                      this.changeShow(params.index);
                    }
                  }
                },
                params.row.isHide == 1 ? "显示" : "隐藏"
              );
            return h("div", [btn1, btn2, btn3, btn4]);
          }
        }
      ]
    };
  },
  mounted() {
    this.getList()
  },
  methods: {
    // 删除
    remove(i) {
      console.log(i);
      // this.$Message.info("功能正在开发中，请您耐心等待！");
      let info = this.list[i], _this = this
      this.$Modal.confirm({
        title: '删除新闻',
        content: `新闻删除后将无法恢复，您确定要删除此新闻吗？`,
        loading: true,
        onOk: function(){
          let id = info.id
          _this.deleteNews(i, id)
        }
      })
    },
    deleteNews(i, id) {
      NewsService.deleteNews(id)
        .then(res => {
          if (res.errorNo == 0) {
            this.list.splice(i, 1);
            this.total -= 1;
            if (this.total == 0) {
              this.getList();
            }
            this.$Message.success("新闻删除成功");
            this.$Modal.remove();
          } else {
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    },
    // 置顶
    setTop(i) {
      let info = this.list[i],
        id = info.id,
        isTop = info.isTop;
      console.log(id, isTop);
      let status = isTop == 0 ? 1 : 0;
      console.log(status)
      // this.$Message.info("功能正在开发中，请您耐心等待！");
      NewsService.setTopNews({id:id, status: status})
        .then(res => {
          if (res.errorNo == 0) {
            // 成功后执行
            this.list[i].isTop = status
            // for(let j=0,l=this.list.length;j<l;j++){
            //   if(j!=i&&this.list[j].isTop==1){
            //     this.list[j].isTop = 0
            //   }
            // }
            this.$Message.success("操作成功");
          } else {
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    },
    // 显示/隐藏
    changeShow(i) {
      let info = this.list[i],
        id = info.id,
        isHide = info.isHide;
      console.log(id, isHide);
      let status = isHide == 0 ? 1 : 0;
      console.log(status)
      // this.$Message.info("功能正在开发中，请您耐心等待！");
      NewsService.changeShowNews({id:id, status: status})
        .then(res => {
          if (res.errorNo == 0) {
            // 成功后执行
            if(isHide==0){
              this.list[i].isHide = 1
            }else{
              this.list[i].isHide = 0
            }
            this.$Message.success("操作成功");
          } else {
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    },
    // 获取列表
    getList() {
      this.loading = true;
      let isHide = null;
      if (this.form.isHide == 0) {
        isHide = 0;
      } else if (this.form.isHide == 1) {
        isHide = 1;
      }
      let condition = {
        "title.contains": this.form.title || null,
        "type.eq": this.form.type || null,
        "isHide.eq": isHide,
        page: this.currentPage - 1,
        size: 30,
        sort: "id,desc"
      };
      NewsService.getNews(condition)
        .then(res => {
          if (res.errorNo == 0) {
            this.currentPage == 1 && (this.total = res.totalSize);
            this.list = res.data;
          } else {
            this.$Message.error(res.errorMessage);
          }
          this.loading = false;
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
          this.loading = false;
        });
    },
    // 显示全部数据
    resetForm() {
      this.form = {};
      this.currentPage = 1;
      this.getList();
    },
    // 查询
    search() {
      this.currentPage = 1;
      this.getList();
    },
    changePage(p) {
      this.currentPage = p;
      this.getList();
      document.getElementsByClassName("pageWrap")[0].scrollTop = 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.newsList {
  position: relative;

  .checkBtn {
    margin: 0 !important;
  }
  #rightBtn {
    position: absolute;
    right: 0;
    top: 10px;
    margin: 0;
  }
}
</style>