  /*
   * Copyright(c) 2021 architectural award, All Rights Reserved.
   * Author: lxj
   */
  import fetch from '@/utils/fetch';

  export default {
    // 导出项目信息
    exportProjects: params => {
      return fetch({
        url: '/competition-projects/export-excel',
        method: 'get',
        responseType: 'blob',
        params
      })
    },
    // 导出报名信息
    exportRegistrations: params => {
      return fetch({
        url: '/competition-registration/export-excel',
        method: 'get',
        responseType: 'blob',
        params
      })
    },
  }