import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  redirect: '/login'
}, {
  path: '/login',
  name: 'Login',
  component: () =>
    import ('../views/Login.vue')
}, {
  path: '/index',
  name: 'Index',
  component: () =>
    import ('../views/Index.vue')
}, {
  path: '/admin',
  name: 'Admin',
  component: () =>
    import ('../views/Admin.vue'),
  redirect: '/admin/check_basic',
  children: [{
    path: 'check_basic',
    name: 'CheckBasic',
    meta: {
      keepAlive: true
    },
    component: () =>
      import ('../views/check/Basic.vue')
  }, {
    path: 'basic_info',
    name: 'BasicInfo',
    component: () =>
      import ('../views/check/BasicInfo.vue')
  }, {
    path: 'check_project',
    name: 'CheckProject',
    meta: {
      keepAlive: true
    },
    component: () =>
      import ('../views/check/Project.vue')
  }, {
    path: 'project_info',
    name: 'ProjectInfo',
    component: () =>
      import ('../views/check/ProjectInfo.vue')
  }, {
    path: 'user_list',
    name: 'UserList',
    component: () =>
      import ('../views/user/UserList.vue')
  }, {
    path: 'judge_list',
    name: 'JudgeList',
    component: () =>
      import ('../views/judge/List.vue')
  }, {
    path: 'preliminary_distribution',
    name: 'PreliminaryDistribution',
    component: () =>
      import ('../views/result/PreliminaryDistribution.vue')
  }, {
    path: 'preliminary_result',
    name: 'PreliminaryResult',
    component: () =>
      import ('../views/result/Preliminary.vue')
  }, {
    path: 'final_distribution',
    name: 'FinalDistribution',
    component: () =>
      import ('../views/result/FinalDistribution.vue')
  }, {
    path: 'final_result',
    name: 'FinalResult',
    component: () =>
      import ('../views/result/Final.vue')
  }, {
    path: 'publish_result',
    name: 'PublishResult',
    component: () =>
      import ('../views/result/Publish.vue')
  }, {
    path: 'distribution_result',
    name: 'DistributionResult',
    component: () =>
      import ('../views/result/DistributionResult.vue')
  }, {
    path: 'news_list',
    name: 'NewsList',
    component: () =>
      import ('../views/news/List.vue')
  }, {
    path: 'news_form',
    name: 'NewsForm',
    component: () =>
      import ('../views/news/Form.vue')
  }, {
    path: 'set_date',
    name: 'SetDate',
    component: () =>
      import ('../views/user/SetEndDate.vue')
  }, {
    path: 'distribution',
    name: 'Distribution',
    component: () =>
      import ('../views/user/Distribution.vue'),
    redirect: '/admin/distribution/result',
    children: [{
      path: 'result',
      name: 'Result',
      component: () =>
        import ('../views/result/DistributionResult.vue')
    }]
  }, {
    path: 'export',
    name: 'Export',
    component: () =>
      import ('../views/export/Index.vue'),
    redirect: '/admin/export/basic',
    children: [{
      path: 'basic',
      name: 'ExportBasic',
      component: () =>
        import ('../views/export/Basic.vue')
    }, {
      path: 'project',
      name: 'ExportProject',
      component: () =>
        import ('../views/export/Project.vue')
    }, {
      path: 'result',
      name: 'ExportResult',
      component: () =>
        import ('../views/export/Result.vue')
    }]
  }, {
    path: 'award_service',
    name: 'AwardService',
    component: () =>
      import ('../views/awardService/Test.vue')
  }]
}]

const router = new VueRouter({
  routes
})

export default router