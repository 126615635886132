/*
 * Copyright(c) 2021 architectural, All Rights Reserved.
 * Author: lxj
 */

import axios from 'axios';
import { baseUrl } from '@/config/env'
import store from '../store';
import router from '../router';
import { Modal } from 'view-design';

// 创建axios实例
const service = axios.create({
  baseURL: baseUrl,
  timeout: 120000                 // 请求超时时间
});

// request拦截器
service.interceptors.request.use(config => {
  if (store.getters.token) {
    config.headers.Authorization = store.getters.token; // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  return config;
}, error => {
  // Do something with request error
  console.log('===== request拦截器 Error===============');
  console.log(error); // for debug
})

// respone拦截器
service.interceptors.response.use(
  response => {
    const res = response.data;
    // return res

    if(res.errorNo==5049){//|| res.errorNo==5054
      Modal.confirm({
        title: '提示',
        content: '您还没有登录，或者登录信息已过期，请先登录',
        okText: '去登录',
        onOk: function(){
          store.commit('setToken', '')
          store.commit('setUserInfo', {})
          window.localStorage.removeItem('login_time')
          router.push({ path: '/login' });
        },
        onCancel: function(){
          store.commit('setToken', '')
          store.commit('setUserInfo', {})
          window.localStorage.removeItem('login_time')
          // router.push('/dragon_star/index')
        }
      })
      res.errorNo = 0;
      return res;
    } else {
      return res;
    } 
  },
  error => {
    console.log('===== respone拦截器 Error===============');
    console.log(error);// for debug
  }
)

export default service;
