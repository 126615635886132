  /*
   * Copyright(c) 2021 architectural award, All Rights Reserved.
   * Author: lxj
   */
  import fetch from '@/utils/fetch';

  export default {
    // 获取时间
    getEndDate: id => {
      return fetch({
        url: `/inquiry/competitions/${id}`,
        method: 'get'
      })
    },
    // 设置时间
    setEndDate: data => {
      return fetch({
        url: '/competitions/save',
        method: 'post',
        data
      })
    }
  }