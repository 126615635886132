<template>
  <div class="export">
    <Form :model="form" label-position="right" :label-width="100">
      <p class="note">请选择/输入导出项目评分信息数据的筛选条件：</p>
      <Row :gutter="20">
        <Col span="8">
          <FormItem label="项目名称">
            <Input v-model="form.name" clearable></Input>
          </FormItem>
        </Col>
        <Col span="8">
          <FormItem label="参评类别">
            <Select v-model="form.type" clearable @on-change="changeType">
              <Option value="建筑应用创新综合类">建筑应用创新综合类</Option>
              <Option value="建筑应用创新单项类">建筑应用创新单项类</Option>
            </Select>
          </FormItem>
        </Col>
        <Col span="8" v-if="form.type=='建筑应用创新单项类'">
          <FormItem label="" :label-width="0">
            <Select v-model="form.subType" clearable>
              <Option value="建筑材料应用创新类">建筑材料应用创新类</Option>
              <Option value="装配式建筑应用创新类">装配式建筑应用创新类</Option>
              <Option value="建筑部品部件应用创新类">建筑部品部件应用创新类</Option>
              <Option value="设备设施应用创新类">设备设施应用创新类</Option>
              <Option value="其它应用创新类">其它应用创新类</Option>
            </Select>
          </FormItem>
        </Col>
        <Col span="8">
          <FormItem label="参评单位">
            <Input v-model="form.regName" clearable></Input>
          </FormItem>
        </Col>
        <Col span="8">
          <FormItem label="推荐单位">
            <Select v-model="form.pushCompany" clearable :disabled="isDisabled">
              <Option :value="p.value" v-for="p in getPushCompany()" :key='p.id'>{{p.label}}</Option>
              <Option value="其他">其他</Option>
            </Select>
          </FormItem>
        </Col>
        <Col span="8">
          <FormItem label="核准结果">
            <Select v-model="form.finalStatus" clearable>
              <Option value="CHECKING">待核准</Option>
              <Option value="APPROVED">已核准</Option>
              <!-- <Option value="FINAL">进入终审</Option> -->
            </Select>
          </FormItem>
        </Col>
      </Row>
      <p class="note">请选择要导出数据的字段：</p>
      <div class="boxWrap">
        <CheckboxGroup v-model="form.key">
          <Row :gutter="20">
            <Col span="6"><Checkbox label="projectSn">项目编号</Checkbox></Col>
            <Col span="6"><Checkbox label="projectName">项目名称</Checkbox></Col>
            <Col span="6"><Checkbox label="firstScore">初评得分</Checkbox></Col>
            <Col span="6"><Checkbox label="finalScore">终评得分</Checkbox></Col>
            <!-- <Col span="6"><Checkbox label="judgeList">专家评审打分</Checkbox></Col> -->
            <Col span="6"><Checkbox label="typeName">参评类别</Checkbox></Col>
            <Col span="6"><Checkbox label="companyName">参评单位</Checkbox></Col>
            <Col span="6"><Checkbox label="pushCompany">推荐单位</Checkbox></Col>
            <Col span="6"><Checkbox label="completionDate">完成时间</Checkbox></Col>
            <Col span="6"><Checkbox label="acceptanceFiles">竣工验收文件</Checkbox></Col>
            <Col span="6"><Checkbox label="address">项目所在地</Checkbox></Col>
            <Col span="6"><Checkbox label="scale">项目规模</Checkbox></Col>
            <Col span="6"><Checkbox label="functionType">功能类型</Checkbox></Col>
            <Col span="6"><Checkbox label="description">项目综述</Checkbox></Col>
            <Col span="6"><Checkbox label="descriptionFiles">项目综述图片</Checkbox></Col>
            <Col span="6"><Checkbox label="creativity">创新性</Checkbox></Col>
            <Col span="6"><Checkbox label="creativityFiles">创新性图片</Checkbox></Col>
            <Col span="6"><Checkbox label="industryLeading">行业引领</Checkbox></Col>
            <Col span="6"><Checkbox label="industryLeadingFiles">行业引领图片</Checkbox></Col>
            <Col span="6"><Checkbox label="healthyProtection">健康环保</Checkbox></Col>
            <Col span="6"><Checkbox label="healthyProtectionFiles">健康环保图片</Checkbox></Col>
            <Col span="6"><Checkbox label="benefits">综合效益</Checkbox></Col>
            <Col span="6"><Checkbox label="benefitsFiles">综合效益图片</Checkbox></Col>
            <Col span="6"><Checkbox label="video">宣传视频</Checkbox></Col>
            <Col span="6"><Checkbox label="designPictures">设计图</Checkbox></Col>
            <Col span="6"><Checkbox label="realPictures">实景/实物图</Checkbox></Col>
            <Col span="6"><Checkbox label="innovatePictures">重点突出创新内容的图片</Checkbox></Col>
            <Col span="6"><Checkbox label="standardFiles">产品执行标准</Checkbox></Col>
            <Col span="6"><Checkbox label="patentFiles">专利技术</Checkbox></Col>
            <Col span="6"><Checkbox label="achievementsFiles">科研成果</Checkbox></Col>
            <Col span="6"><Checkbox label="rewardFiles">项目所获奖励</Checkbox></Col>
            <Col span="6"><Checkbox label="evaluateFiles">用户评价报告</Checkbox></Col>
            <Col span="6"><Checkbox label="recommendFiles">推荐单位意见</Checkbox></Col>
            <Col span="6"><Checkbox label="prizeFiles">行业内类似奖项</Checkbox></Col>
            <Col span="6"><Checkbox label="methodFiles">其它证明材料</Checkbox></Col>
          </Row>
        </CheckboxGroup>  
      </div>
      <div class="btnWrap">
        <Button class="defaultBtn" type="primary" @click="exportData" :loading="loading">导出数据</Button>
      </div>
    </Form>
  </div>
</template>

<script>
import ExportService from "@/api/exportExcel";
export default {
  data() {
    return {
      userInfo: this.$store.getters.userInfo || {},
      isDisabled: false,
      form: {key: ['projectSn', 'projectName', 'firstScore', 'finalScore']},
      loading: false
    }
  },
  mounted() {
    if(this.userInfo.accountType != "CONTESTSUPERADMIN" && this.userInfo.competitionUser.pushCompany){
      this.form.pushCompany = this.userInfo.competitionUser.pushCompany
      this.isDisabled = true
    }
  },
  methods:{
    // 选择参评类别
    changeType(val){
      if(val==undefined || val=='建筑应用创新综合类'){
        this.form.subType = null
      }
    },
    // 导出数据
    exportData(){
      // this.$Message.info('功能正在开发中，请耐心等待...')
      let data = Object.assign({}, this.form)
      if(data.pushCompany == "其他"){
        data.pushCompany = null;
        data.pushCode = "无";
      }
      let serveData = {
        "name.contains": data.name || null,
        "type.eq": data.type || null,
        "subType.eq": data.subType || null,
        "pushCompany.eq": data.pushCompany || null,
        "pushCode.eq" : data.pushCode || null,
        "regName.contains": data.regName || null,
        "finalStatus.eq": data.finalStatus || null,
        "columns": data.key.join(",")
      }
      // console.log(serveData)
      this.loading = true;
      ExportService.exportProjects(serveData).then(res => {
        this.loading = false;
        this.readFileDownload(res, "评分信息_" + this.getShortDate(new Date()) + ".xlsx");
      })
      .catch(() => {
        this.loading = false;
        this.$Message.error("连接服务器失败，请稍后再试");
      });
    },
    getShortDate(date, key){
      if (date && typeof date != "string") {
        let sYear = date.getFullYear() + "";
        let sMonth =
          date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1 + "";
        let sDate = date.getDate() < 10 ? "0" + date.getDate() : date.getDate() + "";
        if(key=='m'){
          return sYear + "-" + sMonth
        }
        return sYear + "-" + sMonth + "-" + sDate;
      }
      return null
    }
  }
}
</script>

<style lang="scss" scoped>
.export{
  .note{
    padding: 5px 0 10px;
  }
  .boxWrap{
    padding-left: 30px;
    line-height: 2em;
  }
  .btnWrap{
    text-align: center;
    padding: 40px 0 20px;
  }
}
</style>