<template>
  <div>
    <TopTitle />
    <div class="hrefWrap center">
      <router-link class="href" to="/admin/check_basic" v-if="getPermissions('check')">预审管理</router-link>
      <router-link class="href" to="/admin/preliminary_distribution" v-if="getPermissions('review')">评审管理</router-link>
      <router-link class="href" to="/admin/judge_list" v-if="getPermissions('judge')">评委管理</router-link>
      <router-link class="href" to="/admin/user_list" v-if="getPermissions('user')">信息管理</router-link>
      <!-- <router-link class="href" to="/admin/award_service" v-if="getPermissions('after')">赛后服务</router-link> -->
      <router-link class="href" to="/admin/news_list" v-if="getPermissions('news')">新闻管理</router-link>
    </div>
  </div>
</template>

<script>
import TopTitle from "@/components/TopTitle.vue";
export default {
  components: {
    TopTitle
  },
  data() {
    return {
      userInfo: this.$store.getters.userInfo || {},
    };
  },
  methods: {
    getPermissions(key){
      let userInfo = this.userInfo
      if(userInfo.accountType=='CONTESTSUPERADMIN'){
        return true
      }
      if(userInfo.accountType=='CONTESTADMIN' && userInfo.competitionUser && userInfo.competitionUser.permissions && userInfo.competitionUser.permissions.indexOf(key)>=0){
        return true
      }
      if(userInfo.competitionUser && userInfo.competitionUser.permissions && userInfo.competitionUser.permissions.indexOf('super')>=0){
        return true
      }
      return false
    }
  },
  mounted(){
    if(this.userInfo.accountType=='CONTESTADMIN' && !this.userInfo.competitionUser.permissions){
      this.$Message.info({
        content: '您没有权限访问该页面，请联系超级管理员为您分配权限。',
        closable: true,
        duration: 0
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.hrefWrap {
  padding: 100px 0 50px;
  .href {
    display: inline-block;
    padding: 50px 30px;
    border-radius: 12px;
    border: 1px solid #eeeeee;
    font-size: 18px;
    margin: 0 30px 30px;
    color: #3f4641;

    &:hover {
      background: #277a44;
      color: #ffffff;
      border-color: #277a44;
    }
  }
}
</style>
