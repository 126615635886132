<template>
  <div class="infoWrap">
    <p class="pageTitle">参评项目信息</p>
    <div class="itemWrap">
      <div class="label"><span class="colorGreen">*</span> 项目名称</div>
      <div class="info">{{info.name || '-'}}</div>
    </div>
    <div class="itemWrap">
      <div class="label"><span class="colorGreen">*</span> 参评类别</div>
      <div class="info">{{info.type || '-'}}<template v-if="info.subType"> - {{info.subType}}</template></div>
    </div>
    <div class="itemWrap">
      <div class="label"><span class="colorGreen">*</span> 参评单位</div>
      <div class="info"><router-link class="colorGreen" :to="`/admin/basic_info?id=${info.regId}`">{{info.regInfo.name || '-'}}</router-link></div>
    </div>
    <div class="itemWrap">
      <div class="label"><span class="colorGreen">*</span> 完成时间</div>
      <div class="info">{{info.completionDate || '-'}}</div>
    </div>
    <div class="itemWrap">
      <div class="label">竣工验收文件</div>
      <div class="info">
        <template v-if="info.acceptanceFile&&info.acceptanceFile.length>0">
          <div class="imgWrap" v-for="p in info.acceptanceFile" :key="p.id">
            <a v-if="p.fileType=='FILE'" :href="getFileUrl(p.path)" target="_blank" class="colorGreen">{{p.name}}{{p.format}}</a>
            <img v-else-if="p.size<20480" class="img" :src="getImageUrl(p.path, 'large')" alt="">
            <img v-else class="img" :src="getImageUrl(p.path)" alt="">
          </div>
        </template>
        <template v-else>-</template>
      </div>
    </div>
    <div class="itemWrap">
      <div class="label"><span class="colorGreen">*</span> 项目所在地</div>
      <div class="info">{{info.address}}</div>
    </div>
    <div class="itemWrap">
      <div class="label">项目规模</div>
      <div class="info">{{info.scale || '-'}}</div>
    </div>
    <div class="itemWrap">
      <div class="label">功能类型</div>
      <div class="info">{{info.functionType || '-'}}</div>
    </div>
    <div class="itemWrap">
      <div class="label"><span class="colorGreen">*</span> 项目综述</div>
      <div class="info">{{info.description || '-'}}</div>
    </div>
    <div class="itemWrap">
      <div class="label">项目综述图片</div>
      <div class="info">
        <template v-if="info.descriptionFile&&info.descriptionFile.length>0">
          <div class="imgWrap" v-for="p in info.descriptionFile" :key="p.id">
            <img v-if="p.size<20480" class="img" :src="getImageUrl(p.path, 'large')" alt="">
            <img v-else class="img" :src="getImageUrl(p.path)" alt="">
          </div>
        </template>
        <template v-else>-</template>
      </div>
    </div>
    <div class="itemWrap">
      <div class="label"><span class="colorGreen">*</span> 创新性</div>
      <div class="info">{{info.creativity || '-'}}</div>
    </div>
    <div class="itemWrap">
      <div class="label">创新性图片</div>
      <div class="info">
        <template v-if="info.creativityFile&&info.creativityFile.length>0">
          <div class="imgWrap" v-for="p in info.creativityFile" :key="p.id">
            <img v-if="p.size<20480" class="img" :src="getImageUrl(p.path, 'large')" alt="">
            <img v-else class="img" :src="getImageUrl(p.path)" alt="">
          </div>
        </template>
        <template v-else>-</template>
      </div>
    </div>
    <div class="itemWrap">
      <div class="label"><span class="colorGreen">*</span> 行业引领</div>
      <div class="info">{{info.industryLeading || '-'}}</div>
    </div>
    <div class="itemWrap">
      <div class="label">行业引领图片</div>
      <div class="info">
        <template v-if="info.industryLeadingFile&&info.industryLeadingFile.length>0">
          <div class="imgWrap" v-for="p in info.industryLeadingFile" :key="p.id">
            <img v-if="p.size<20480" class="img" :src="getImageUrl(p.path, 'large')" alt="">
            <img v-else class="img" :src="getImageUrl(p.path)" alt="">
          </div>
        </template>
        <template v-else>-</template>
      </div>
    </div>
    <div class="itemWrap">
      <div class="label"><span class="colorGreen">*</span> 健康环保</div>
      <div class="info">{{info.healthyProtection || '-'}}</div>
    </div>
    <div class="itemWrap">
      <div class="label">健康环保图片</div>
      <div class="info">
        <template v-if="info.healthyProtectionFile&&info.healthyProtectionFile.length>0">
          <div class="imgWrap" v-for="p in info.healthyProtectionFile" :key="p.id">
            <img v-if="p.size<20480" class="img" :src="getImageUrl(p.path, 'large')" alt="">
            <img v-else class="img" :src="getImageUrl(p.path)" alt="">
          </div>
        </template>
        <template v-else>-</template>
      </div>
    </div>
    <div class="itemWrap">
      <div class="label"><span class="colorGreen">*</span> 综合效益</div>
      <div class="info">{{info.benefits || '-'}}</div>
    </div>
    <div class="itemWrap">
      <div class="label">综合效益图片</div>
      <div class="info">
        <template v-if="info.benefitsFile&&info.benefitsFile.length>0">
          <div class="imgWrap" v-for="p in info.benefitsFile" :key="p.id">
            <img v-if="p.size<20480" class="img" :src="getImageUrl(p.path, 'large')" alt="">
            <img v-else class="img" :src="getImageUrl(p.path)" alt="">
          </div>
        </template>
        <template v-else>-</template>
      </div>
    </div>
    <div class="itemWrap">
      <div class="label">宣传视频</div>
      <div class="info">
        <a v-if="info.video" :href="info.video" class="colorGreen" target="_blank">{{info.video}}</a>
        <template v-else>-</template>
        <!-- <video v-if="info.video" class="video" :src="info.video" controls></video> -->
      </div>
    </div>
    <div class="itemWrap">
      <div class="label">设计图</div>
      <div class="info">
        <template v-if="info.designPictures&&info.designPictures.length>0">
          <div class="imgWrap" v-for="p in info.designPictures" :key="p.id">
            <img v-if="p.size<20480" class="img" :src="getImageUrl(p.path, 'large')" alt="">
            <img v-else class="img" :src="getImageUrl(p.path)" alt="">
          </div>
        </template>
        <template v-else>-</template>
      </div>
    </div>
    <div class="itemWrap">
      <div class="label"><span class="colorGreen">*</span> 实景/实物图</div>
      <div class="info">
        <template v-if="info.realPictures&&info.realPictures.length>0">
          <div class="imgWrap" v-for="p in info.realPictures" :key="p.id">
            <img v-if="p.size<20480" class="img" :src="getImageUrl(p.path, 'large')" alt="">
            <img v-else class="img" :src="getImageUrl(p.path)" alt="">
          </div>
        </template>
        <template v-else>-</template>
      </div>
    </div>
    <div class="itemWrap">
      <div class="label"><span class="colorGreen">*</span> 重点突出创新内容的图片</div>
      <div class="info">
        <template v-if="info.innovatePictures&&info.innovatePictures.length>0">
          <div class="imgWrap" v-for="p in info.innovatePictures" :key="p.id">
            <img v-if="p.size<20480" class="img" :src="getImageUrl(p.path, 'large')" alt="">
            <img v-else class="img" :src="getImageUrl(p.path)" alt="">
          </div>
        </template>
        <template v-else>-</template>
      </div>
    </div>
    <div class="itemWrap">
      <div class="label">产品执行标准</div>
      <div class="info">
        <template v-if="info.standardFile&&info.standardFile.length>0">
          <div class="imgWrap" v-for="p in info.standardFile" :key="p.id">
            <a v-if="p.fileType=='FILE'" :href="getFileUrl(p.path)" target="_blank" class="colorGreen">{{p.name}}{{p.format}}</a>
            <img v-else-if="p.size<20480" class="img" :src="getImageUrl(p.path, 'large')" alt="">
            <img v-else class="img" :src="getImageUrl(p.path)" alt="">
          </div>
        </template>
        <template v-else>-</template>
      </div>
    </div>
    <div class="itemWrap">
      <div class="label">专利技术</div>
      <div class="info">
        <template v-if="info.patentFile&&info.patentFile.length>0">
          <div class="imgWrap" v-for="p in info.patentFile" :key="p.id">
            <a v-if="p.fileType=='FILE'" :href="getFileUrl(p.path)" target="_blank" class="colorGreen">{{p.name}}{{p.format}}</a>
            <img v-else-if="p.size<20480" class="img" :src="getImageUrl(p.path, 'large')" alt="">
            <img v-else class="img" :src="getImageUrl(p.path)" alt="">
          </div>
        </template>
        <template v-else>-</template>
      </div>
    </div>
    <div class="itemWrap">
      <div class="label">科研成果</div>
      <div class="info">
        <template v-if="info.achievementsFile&&info.achievementsFile.length>0">
          <div class="imgWrap" v-for="p in info.achievementsFile" :key="p.id">
            <a v-if="p.fileType=='FILE'" :href="getFileUrl(p.path)" target="_blank" class="colorGreen">{{p.name}}{{p.format}}</a>
            <img v-else-if="p.size<20480" class="img" :src="getImageUrl(p.path, 'large')" alt="">
            <img v-else class="img" :src="getImageUrl(p.path)" alt="">
          </div>
        </template>
        <template v-else>-</template>
      </div>
    </div>
    <div class="itemWrap">
      <div class="label">项目所获奖励</div>
      <div class="info">
        <template v-if="info.rewardFile&&info.rewardFile.length>0">
          <div class="imgWrap" v-for="p in info.rewardFile" :key="p.id">
            <a v-if="p.fileType=='FILE'" :href="getFileUrl(p.path)" target="_blank" class="colorGreen">{{p.name}}{{p.format}}</a>
            <img v-else-if="p.size<20480" class="img" :src="getImageUrl(p.path, 'large')" alt="">
            <img v-else class="img" :src="getImageUrl(p.path)" alt="">
          </div>
        </template>
        <template v-else>-</template>
      </div>
    </div>
    <div class="itemWrap">
      <div class="label">用户评价报告</div>
      <div class="info">
        <template v-if="info.evaluateFile&&info.evaluateFile.length>0">
          <div class="imgWrap" v-for="p in info.evaluateFile" :key="p.id">
            <a v-if="p.fileType=='FILE'" :href="getFileUrl(p.path)" target="_blank" class="colorGreen">{{p.name}}{{p.format}}</a>
            <img v-else-if="p.size<20480" class="img" :src="getImageUrl(p.path, 'large')" alt="">
            <img v-else class="img" :src="getImageUrl(p.path)" alt="">
          </div>
        </template>
        <template v-else>-</template>
      </div>
    </div>
    <div class="itemWrap">
      <div class="label">推荐单位意见</div>
      <div class="info">
        <template v-if="info.recommendFile&&info.recommendFile.length>0">
          <div class="imgWrap" v-for="p in info.recommendFile" :key="p.id">
            <a v-if="p.fileType=='FILE'" :href="getFileUrl(p.path)" target="_blank" class="colorGreen">{{p.name}}{{p.format}}</a>
            <img v-else-if="p.size<20480" class="img" :src="getImageUrl(p.path, 'large')" alt="">
            <img v-else class="img" :src="getImageUrl(p.path)" alt="">
          </div>
        </template>
        <template v-else>-</template>
      </div>
    </div>
    <div class="itemWrap">
      <div class="label">行业内类似奖项</div>
      <div class="info">
        <template v-if="info.prizeFile&&info.prizeFile.length>0">
          <div class="imgWrap" v-for="p in info.prizeFile" :key="p.id">
            <a v-if="p.fileType=='FILE'" :href="getFileUrl(p.path)" target="_blank" class="colorGreen">{{p.name}}{{p.format}}</a>
            <img v-else-if="p.size<20480" class="img" :src="getImageUrl(p.path, 'large')" alt="">
            <img v-else class="img" :src="getImageUrl(p.path)" alt="">
          </div>
        </template>
        <template v-else>-</template>
      </div>
    </div>
    <div class="itemWrap">
      <div class="label">其它证明材料</div>
      <div class="info">
        <template v-if="info.methodFile&&info.methodFile.length>0">
          <div class="imgWrap" v-for="p in info.methodFile" :key="p.id">
            <a v-if="p.fileType=='FILE'" :href="getFileUrl(p.path)" target="_blank" class="colorGreen">{{p.name}}{{p.format}}</a>
            <img v-else-if="p.size<20480" class="img" :src="getImageUrl(p.path, 'large')" alt="">
            <img v-else class="img" :src="getImageUrl(p.path)" alt="">
          </div>
        </template>
        <template v-else>-</template>
      </div>
    </div>
    <div class="itemWrap">
      <div class="label">审核状态</div>
      <div class="info">{{setStatusText}}</div>
    </div>
    <div class="btnWrap">
      <span class="colorGreen oneBtn" @click="goBack">返回</span>
      <Button v-if="info.checkStatus!='SUCCESS'" class="succBtn statusBtn" @click="checkProject('SUCCESS')">通过审核</Button>
      <Button v-if="info.checkStatus!='FAILED'" class="failedBtn statusBtn" @click="checkProject('FAILED')">不通过审核</Button>
      <Button v-if="info.checkStatus!='CHECKING'" class="checkBtn statusBtn" @click="checkProject('CHECKING')">待审核</Button>
    </div>
  </div>
</template>

<script>
import CheckService from "@/api/check";
export default {
  data() {
    return {
      info: {regInfo:{}}
    };
  },
  mounted() {
    this.getInfo();
  },
  computed: {
    // 根据参评类别赋值证明类型
    setCertType() {
      let val = this.info.applicantType,
        certType;
      if (val == "个人") {
        certType = "个人身份证件";
      } else if (val == "院校或科研机构") {
        certType = "院校或科研机构盖章的《参评申报表》";
      } else if (val == "企业") {
        certType = "营业执照复印件或企业盖章的《参评申报表》";
      } else {
        certType = "-";
      }
      return certType;
    },
    // 根据参评类别赋值证明类型
    setStatusText() {
      let status = this.info.checkStatus,
        statusText;
      if (status == "CHECKING") {
        statusText = "待审核";
      } else if (status == "SUCCESS") {
        statusText = "已通过";
      } else if (status == "FAILED") {
        statusText = "未通过";
      }
      return statusText;
    }
  },
  methods: {
    // 返回
    goBack(){
      this.$router.go(-1);
    },
    // 审核
    checkProject(status) {
      CheckService.checkProject({
        projectId: this.info.id,
        checkStatus: status
      })
        .then(res => {
          if (res.errorNo == 0) {
            this.info.checkStatus = status;
            this.$Message.success("操作成功");
          } else {
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    },
    // 获取参评项目信息
    getInfo() {
      let id = this.$route.query.id;
      CheckService.getProjectInfo(id)
        .then(res => {
          if (res.errorNo == 0) {
            this.info = res.data;
          } else {
            this.info = {};
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.infoWrap {
  font-size: 14px;
  line-height: 1.8em;
  .itemWrap {
    padding-bottom: 10px;
    .label {
      float: left;
      width: 90px;
      text-align: right;
    }
    .info {
      margin-left: 110px;
      color: #777;
      white-space: pre-wrap;
      .imgWrap {
        padding-bottom: 10px;
        .img {
          max-width: 80%;
        }
      }
      // .video {
      //   width: 80%;
      // }
    }
    &::after {
      content: "";
      display: block;
      clear: both;
    }
  }
  .btnWrap {
    text-align: center;
    padding: 30px 0;
    .oneBtn {
      margin-right: 15px !important;
      cursor: pointer;
    }
    .failedBtn {
      background: #ed0606;
    }
    .succBtn{
      background: #FFAC00;
    }
    .checkBtn{
      background: #277a44;
    }
    .statusBtn{
      margin: 10px;
      color: #fff;
    }
  }
}
</style>