<template>
  <div class="basic">
    <p class="pageTitle">报名审核管理</p>
    <div class="pageContent">
      <div class="formBox">
        <Form :model="form" label-position="right" :label-width="100">
          <Row :gutter="20">
            <Col span="8">
              <FormItem label="单位类型">
                <Select v-model="form.applicantType" @on-change="setCertType" clearable>
                  <Option value="个人">个人</Option>
                  <Option value="院校或科研机构">院校或科研机构</Option>
                  <Option value="企业">企业</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem :label="nameLabel">
                <Input v-model="form.name" clearable @on-enter="search"></Input>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="推荐单位">
                <Select v-model="form.pushCompany" clearable :disabled="isDisabled">
                  <Option :value="p.value" v-for="p in getPushCompany()" :key='p.id'>{{p.label}}</Option>
                  <Option value="其他">其他</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="7">
              <FormItem label="报名日期">
                <DatePicker v-model="dateList[0]" format="yyyy-MM-dd" placeholder="选择日期" style="width:100%"></DatePicker>
              </FormItem>
            </Col>
            <Col span="6">
              <FormItem label="至" :label-width="26">
                <DatePicker v-model="dateList[1]" format="yyyy-MM-dd" placeholder="选择日期" style="width:100%"></DatePicker>
              </FormItem>
            </Col>
            <Col span="8">
              <FormItem label="审核状态">
                <Select v-model="form.status" clearable>
                  <Option value="CHECKING">待审核</Option>
                  <Option value="SUCCESS">通过审核</Option>
                  <Option value="FAILED">未通过审核</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="3" align="right">
              <Button class="checkBtn" type="primary" @click="search">查询</Button>
            </Col>
          </Row>  
        </Form> 
      </div>
      <div class="right">
        <Button class="defaultBtn" @click="checkTogether">批量审核</Button>
        <!-- <Button class="dataBtn" @click="showExport" v-if="userInfo.accountType=='CONTESTSUPERADMIN'">导出数据</Button> -->
      </div>
      <Table class="line" :data="list" :columns="columns" @on-selection-change="selectionChange" :loading="loading" stripe></Table>
      <div class="pageWrap">
        <Page :total="total" :page-size="30" :current="currentPage" show-total @on-change="changePage"></Page>
      </div>
      <Modal v-model="isTogether" title="批量审核" footer-hide :mask-closable="false">
        <p>请对您所选择的{{selection.length}}条数据进行以下批量操作：</p>
        <div v-if="selection.length>0" class="btnWrap">
          <Button v-if="selection[0].status!='SUCCESS'" class="modalBtn succBtn" :loading="submitting" @click="checkBasic('SUCCESS')">通过审核</Button>
          <Button v-if="selection[0].status!='FAILED'" class="modalBtn failedBtn" :loading="submitting" @click="checkBasic('FAILED')">不通过审核</Button>
          <Button v-if="selection[0].status!='CHECKING'" class="modalBtn checkBtn" :loading="submitting" @click="checkBasic('CHECKING')">待审核</Button>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import CheckService from "@/api/check";
// import { baseUrl } from '@/config/env';
export default {
  data() {
    return {
      userInfo: this.$store.getters.userInfo || {},
      isDisabled: false,
      form: {},
      nameLabel: "参评单位名称",
      dateList: [],
      isAllow: true,

      selection: [],
      isTogether: false,
      submitting: false,

      loading: false,
      currentPage: 1,
      list: [],
      total: 0,
      columns: [
        {
          type: "selection",
          width: 60,
          align: "center"
        },
        {
          title: "序号",
          align: "center",
          key: "id"
        },
        {
          title: "参评单位类型",
          align: "center",
          key: "applicantType"
        },
        {
          title: "参评单位/人名称",
          align: "center",
          key: "name",
          render: (h, params) => {
            return h("router-link", {
              props: {
                to: `/admin/basic_info?id=${params.row.id}`,
              },
              class: 'colorGreen'
            }, params.row.name||'-');
          }
        },
        {
          title: "联系人",
          align: "center",
          key: "contactName"
        },
        {
          title: "联系电话",
          align: "center",
          key: "phone"
        },
        {
          title: "报名时间",
          align: "center",
          key: "createdDate",
          render: (h, params) => {
            return h("div", params.row.createdDate.split("T")[0]);
          }
        },
        {
          title: "项目数量",
          align: "center",
          key: "projectCount",
          render: (h, params) => {
            return h("router-link", {
              props: {
                to: `/admin/check_project?regId=${params.row.id}`,
              },
              class: 'colorGreen'
            }, params.row.projectCount);
          }
        },
        {
          title: "审核状态",
          key: "status",
          align: "center",
          render: (h, params) => {
            let status = params.row.status,
              statusText, color;
            if (status == "CHECKING") {
              statusText = "待审核";
              color = ''
            } else if (status == "SUCCESS") {
              statusText = "已通过";
              color = '#FFAC00'
            } else if (status == "FAILED") {
              statusText = "未通过";
              color = '#ed0606'
            }
            return h("div", {
              style: {color}
            }, statusText);
          }
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          width: 100,
          render: (h, params) => {
            let btn1 = h(
                "Button",
                {
                  props: {
                    type: "text",
                    size: "small"
                  },
                  class: "textBtn",
                  on: {
                    click: () => {
                      this.$router.push(
                        `/admin/basic_info?id=${params.row.id}`
                      );
                    }
                  }
                },
                "查看详情"
              ),
              btn2 = h(
                "Button",
                {
                  props: {
                    type: "text",
                    size: "small"
                  },
                  class: "textBtn",
                  on: {
                    click: () => {
                      this.checkRegistration(params.index, "SUCCESS");
                    }
                  }
                },
                "通过审核"
              ),
              btn3 = h(
                "Button",
                {
                  props: {
                    type: "text",
                    size: "small"
                  },
                  class: "textBtn",
                  on: {
                    click: () => {
                      this.checkRegistration(params.index, "FAILED");
                    }
                  }
                },
                "不通过审核"
              ),
              btn4 = h(
                "Button",
                {
                  props: {
                    type: "text",
                    size: "small"
                  },
                  class: "textBtn",
                  on: {
                    click: () => {
                      this.checkRegistration(params.index, "CHECKING");
                    }
                  }
                },
                "待审核"
              );
            let status = params.row.status;
            if (status == "CHECKING") {
              return h("div", [btn1, btn2, btn3]);
            }else if(status=='SUCCESS'){
              return h("div", [btn1, btn3, btn4]);
            } else if(status=='FAILED'){
              return h("div", [btn1, btn2, btn4]);
            } else {
              return h("div", [btn1]);
            }
          }
        }
      ]
    };
  },
  beforeRouteLeave(to, from, next) {
    from.meta.scrollTop = document.getElementsByClassName(
      "pageWrap"
    )[0].scrollTop;
    next();
  },
  mounted() {
    if(this.userInfo.accountType != "CONTESTSUPERADMIN" && this.userInfo.competitionUser.pushCompany){
      this.form.pushCompany = this.userInfo.competitionUser.pushCompany
      this.isDisabled = true
    }
    this.getList();
  },
  methods: {
    // 导出数据
    // showExport() {
    //   let downloadUrl = baseUrl + "competition-projects/export"
    //   window.open(downloadUrl)
    // },
    // 批量审核
    checkBasic(status) {
      let ids = [];
      for (let i = 0; i < this.selection.length; i++) {
        ids[i] = this.selection[i].id;
      }
      let condition = {ids, checkStatus:status}
      this.submitting = true;
      CheckService.checkAllRegistration(condition)
        .then(res => {
          if (res.errorNo == 0) {
            this.getList()
            this.submitting = false;
            this.isTogether = false
            this.$Message.success("操作成功");
          } else {
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    },
    // 批量审核
    checkTogether() {
      if (this.selection.length > 0) {
        if(this.isAllow){
          this.isTogether = true;
        }else{
          this.$Modal.info({
            title: '提示',
            content: '您选择的数据中审核状态不一致，不能进行批量操作。请选择审核状态一致的数据进行批量审核。'
          })
        }
      } else {
        this.$Message.info("请先选择要操作的内容");
      }
    },
    // 监测表格中内容选中变化
    selectionChange(data) {
      this.selection = data;
      let status = null
      for(let i=0,l=data.length;i<l;i++){
        if(i==0){
          status = data[i].status
          this.isAllow = true
        }else{
          if(status==data[i].status){
            this.isAllow = true
          }else{
            this.isAllow = false
            return
          }
        }
      }
    },
    // 修改报名状态
    checkRegistration(i, status) {
      if (status == "FAILED") {
        let info = this.list[i],
          _this = this;
        this.$Modal.confirm({
          title: "提示",
          content: `您确定要进行该操作，取消 “${info.name}” 的参评报名吗？`,
          loading: true,
          onOk: function() {
            _this.submitCheck(i, status);
          }
        });
      } else {
        this.submitCheck(i, status);
      }
    },
    // 发送请求到服务器修改状态
    submitCheck(i, status) {
      let info = this.list[i];
      CheckService.checkRegistration({ id: info.id, checkStatus: status })
        .then(res => {
          if (res.errorNo == 0) {
            this.list[i].status = status;
            this.$Message.success("操作成功");
            this.$Modal.remove();
          } else {
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    },
    // 根据参评单位类型赋值证明类型
    setCertType(val) {
      if (val == "个人") {
        this.nameLabel = "参评个人姓名";
      } else if (val == "院校或科研机构") {
        this.nameLabel = "参评单位名称";
      } else if (val == "企业") {
        this.nameLabel = "参评单位名称";
      } else {
        this.nameLabel = "参评单位名称";
      }
    },
    // 获取列表
    getList() {
      this.loading = true;
      if(this.form.pushCompany == "其他"){
        this.form.pushCompany = null;
        this.form.pushCode = "无";
      }
      let condition = {
        page: this.currentPage - 1,
        size: 30,
        sort: "id,desc",
        "applicantType.eq": this.form.applicantType || null,
        "name.contains": this.form.name || null,
        "pushCompany.eq": this.form.pushCompany || null,
        "pushCode.eq" : this.form.pushCode || null,
        "status.eq": this.form.status || null,
        "createdDate.ge": this.dateList[0] || null,
        "createdDate.le": this.dateList[1] || null
      };
      this.selection = [];
      CheckService.getCompetitionRegistration(condition)
        .then(res => {
          if (res.errorNo == 0) {
            this.currentPage == 1 && (this.total = res.totalSize);
            this.list = res.data;
          } else {
            this.$Message.error(res.errorMessage);
          }
          this.loading = false;
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
          this.loading = false;
        });
    },
    // 查询
    search() {
      this.currentPage = 1;

      if (this.dateList[0] && typeof this.dateList[0] != "string") {
        let dt = this.dateList[0];
        // console.log(dt)
        // dt.setHours(dt.getHours() + 8)
        let sYear = dt.getFullYear() + "";
        let sMonth =
          dt.getMonth() + 1 < 10
            ? "0" + (dt.getMonth() + 1)
            : dt.getMonth() + 1 + "";
        let sDate = dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate() + "";
        let dNew = sYear + "-" + sMonth + "-" + sDate;
        // let dNew = sYear + '-' + sMonth
        // this.info.completionDate = dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate()
        this.dateList[0] = dNew;
      }
      if (this.dateList[1] && typeof this.dateList[1] != "string") {
        let dt = this.dateList[1];
        // console.log(dt)
        // dt.setHours(dt.getHours() + 8)
        let sYear = dt.getFullYear() + "";
        let sMonth =
          dt.getMonth() + 1 < 10
            ? "0" + (dt.getMonth() + 1)
            : dt.getMonth() + 1 + "";
        let sDate = dt.getDate() + 1 < 10 ? "0" + (dt.getDate() + 1) : (dt.getDate() + 1) + "";
        let dNew = sYear + "-" + sMonth + "-" + sDate;
        // let dNew = sYear + '-' + sMonth
        // this.info.completionDate = dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate()
        this.dateList[1] = dNew;
      }

      this.getList();
    },
    changePage(p) {
      this.currentPage = p;
      this.getList();
      document.getElementsByClassName("pageWrap")[0].scrollTop = 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.basic {
  .dataBtn {
    background: #eeeeee;
    color: #3f4641;
    margin: 10px 0 10px 15px;
  }
}
.leftLabel {
  float: left;
  width: 130px;
  text-align: right;
}
.rightInfo {
  margin-left: 140px;
  color: #777;
}
.btnWrap {
  text-align: center;
  padding: 30px 0;
}
.modalBtn {
  color: #fff;
  margin: 0 10px;
}
.succBtn {
  background: #FFAC00;
}
.failedBtn {
  background: #ed0606;
}
.checkBtn{
  background: #277a44;
}
</style>
