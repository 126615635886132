import {
  imgBaseUrl,
  fileBaseUrl
} from '@/config/env'
import pushCompany from "@/assets/pushCompany";

export default {
  methods: {
    // 获取推荐单位列表
    getPushCompany(){
      return pushCompany
    },
    // 是否登录
    gIsLogin() {
      let loginTime = window.localStorage.getItem('login_time'),
        now = new Date().getTime();
      return loginTime && Number(loginTime) + 86400000 > now;
    },
    //图片居中显示
    gCenterImg(e, f) { //f: 1-按短边显示， 2-按长边显示
      let img = e.currentTarget,
        parent = img.parentNode,
        iw = img.width,
        ih = img.height,
        imgWH = iw / ih,
        pw = parent.clientWidth,
        ph = parent.clientHeight,
        parentWH = pw / ph,
        flag = f == 2 ? parentWH / imgWH : imgWH / parentWH;
      if (flag < 1) {
        img.style.height = 'auto';
        img.style.marginLeft = 0;
        img.style.width = '100%';
        img.style.marginTop = (ph - pw / imgWH) / 2 + 'px';
      } else {
        img.style.width = 'auto';
        img.style.marginTop = 0;
        img.style.height = '100%';
        img.style.marginLeft = (pw - ph * imgWH) / 2 + 'px';
      }
    },
    getImageUrl(path, style) {
      let url = imgBaseUrl + path;
      // if (!path) {
      //   path = 'IMG/123.jpg';
      // }

      if (!style || style == 'origin') {
        return url;
      } else {
        return url + "/" + style;
      }
    },
    getFileUrl(path){
      return fileBaseUrl+path;
    },
    readFileDownload(data, msg) {
      var res = data
      if (res.type === 'application/json' || res.type === 'application/json;charset=UTF-8') {     // 失败的时候，注意ie兼容问题
        let fileReader = new FileReader()
        fileReader.onload = function() {//event
          // console.log(event)
          let jsonData = JSON.parse(this.result) // this.result是根据event，读取文件后打印的
          // console.log(jsonData, '...............')
          if (jsonData.data === null && jsonData.code === 1) {
            // console.log("no")
          }
        }
        fileReader.readAsText(res)
      }
      if (res.type === 'application/octet-stream' || res.type === 'application/vnd.ms-excel' || res.type === 'application/vnd.ms-excel;charset=UTF-8') {
        // console.log('success...')         // 成功，注意ie兼容问题
        const blob = new Blob([res], { type: 'application/vnd.ms-excel;charset=utf-8' })
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, msg)
        } else {
          // console.log(blob)
          const url = window.URL.createObjectURL(blob)
          // console.log(url)
          const aLink = document.createElement('a')
          aLink.style.display = 'none'
          aLink.href = url
          aLink.setAttribute('download', msg)
          document.body.appendChild(aLink)
          aLink.click()
          document.body.removeChild(aLink)
          window.URL.revokeObjectURL(url)
        }
      }
    }
  }
}