  /*
   * Copyright(c) 2021 architectural award, All Rights Reserved.
   * Author: lxj
   */
  import fetch from '@/utils/fetch';

  export default {
    // 获取新闻列表
    getNews: params => {
      return fetch({
        url: 'inquiry/news',
        method: 'get',
        params
      })
    },
    // 获取新闻信息
    getNewsInfo: id => {
      return fetch({
        url: `inquiry/news/${id}`,
        method: 'get'
      })
    },
    // 添加新闻
    saveNews: data => {
      return fetch({
        url: 'news/save',
        method: 'post',
        data
      })
    },
    // 删除新闻
    deleteNews: id => {
      return fetch({
        url: `news/${id}`,
        method: 'delete'
      })
    },
    // 置顶新闻
    setTopNews: data => {
      return fetch({
        url: 'news/set-top',
        method: 'put',
        data
      })
    },
    // 显示/隐藏新闻
    changeShowNews: data => {
      return fetch({
        url: 'news/change-show',
        method: 'put',
        data
      })
    }
  }