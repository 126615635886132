<template>
  <div>
    <p class="pageTitle">终审结果管理</p>
    <div class="pageContent">
      <div class="formBox">
        <Form :model="form" label-position="right" :label-width="110">
          <Row :gutter="20">
            <Col span="6">
              <FormItem label="项目名称">
                <Input v-model="form.name" clearable @on-enter="search"></Input>
              </FormItem>
            </Col>
            <Col span="6">
              <FormItem :label="nameLabel">
                <Input v-model="form.regName" clearable @on-enter="search"></Input>
              </FormItem>
            </Col>
            <Col span="6">
              <FormItem label="推荐单位">
                <Select v-model="form.pushCompany" placeholder="请选择所属推荐单位" clearable>
                  <Option :value="p.value" v-for="p in getPushCompany()" :key='p.id'>{{p.label}}</Option>
                  <Option value="其他">其他</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="6">
              <FormItem label="排序方式">
                <Select v-model="form.sort" placeholder="请选择排序方式" clearable>
                  <Option value="finalScore,desc">最终得分降序</Option>
                  <Option value="finalScore,asc">最终得分升序</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="6">
              <FormItem label="参评类别">
                <Select v-model="form.type" clearable @on-change="changeType">
                  <Option value="建筑应用创新综合类">建筑应用创新综合类</Option>
                  <Option value="建筑应用创新单项类">建筑应用创新单项类</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="6" v-if="form.type=='建筑应用创新单项类'">
              <FormItem label="" :label-width="0">
                <Select v-model="form.subType" clearable>
                  <Option value="建筑材料应用创新类">建筑材料应用创新类</Option>
                  <Option value="装配式建筑应用创新类">装配式建筑应用创新类</Option>
                  <Option value="建筑部品部件应用创新类">建筑部品部件应用创新类</Option>
                  <Option value="设备设施应用创新类">设备设施应用创新类</Option>
                  <Option value="其它应用创新类">其它应用创新类</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="6">
              <FormItem label="核准结果">
                <Select v-model="form.finalStatus" clearable>
                  <Option value="CHECKING">待核准</Option>
                  <Option value="APPROVED">已核准</Option>
                  <!-- <Option value="FINAL">进入终审</Option> -->
                </Select>
              </FormItem>
            </Col>
            <Col :span="form.type=='建筑应用创新单项类'?6:12" align="right">
              <Button class="succBtn" type="primary" @click="search">查询</Button>
            </Col>
          </Row>  
        </Form>  
      </div>
      <Row>
        <Col span="14">
          <Button class="defaultBtn" @click="resetForm">显示全部数据</Button>
        </Col>
        <Col span="10" align="right">
          <Button v-if="show" class="defaultBtn" @click="showTogether">批量操作</Button>
          <!-- <Button v-if="userInfo.accountType=='CONTESTSUPERADMIN'" class="dataBtn" @click="showExport">导出数据</Button> -->
        </Col>
      </Row>
      <div class="line">
        <Table :data="list" :columns="columns" @on-selection-change="selectionChange" :loading="loading" stripe></Table>
        <div class="pageWrap">
          <Page :total="total" :page-size="30" :current="currentPage" show-total @on-change="changePage"></Page>
        </div>
      </div>
    </div>
    <Modal v-model="isExport" title="将数据导出成Excel" footer-hide :mask-closable="false">
      <p class="center">您将导出初评评审数据共{{selection.length}}条</p>
      <div v-if="selection.length>0" class="btnWrap">
        <Button class="modalBtn succBtn" :loading="submitting" @click="exportData">导出</Button>
        <Button @click="hideExport">取消</Button>
      </div>
    </Modal>
    <Modal v-model="isTogether" title="批量操作" footer-hide :mask-closable="false">
      <p>请问您确定对已选择的{{selection.length}}条数据进行批量操作：</p>
      <div v-if="selection.length>0" class="btnWrap">
        <Button v-if="selection[0].finalStatus!='CHECKING'" class="modalBtn succBtn" :loading="submitting" @click="changeStatusAll('CHECKING')">待核准</Button>
        <Button v-if="selection[0].finalStatus!='APPROVED'" class="modalBtn succBtn" :loading="submitting" @click="changeStatusAll('APPROVED')">核准</Button>
        <!-- <Button v-if="selection[0].finalStatus!='FINAL'" class="modalBtn failedBtn" :loading="submitting" @click="changeStatusAll('FINAL')">进入终审</Button> -->
      </div>
    </Modal>
    <Modal v-model="isEdit" title="修改得分" footer-hide :mask-closable="false">
      <p v-if="info.scoreList&&info.scoreList.length>0">输入评委 “{{info.scoreList[judgeIndex].userName}}” 为项目 “{{info.name}}” 的打分：</p>
      <InputNumber :min="0" :max="100" v-model="newScore" ></InputNumber>
      <div class="btnWrap">
        <Button class="modalBtn succBtn" :loading="submitting" @click="editScore">修改得分</Button>
        <Button class="modalBtn failedBtn" :loading="submitting" @click="hideEditScore">取消</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import ResultService from "@/api/result";
export default {
  data() {
    return {
      nameLabel: "参评单位名称",
      form: {},
      userInfo: this.$store.getters.userInfo || {},

      selection: [],
      isTogether: false,
      submitting: false,
      isExport: false,
      show: false,

      isEdit: false,
      newScore: null,
      index: null,
      info: {},
      judgeIndex: null,
      judgeId: null,

      loading: false,
      currentPage: 1,
      list: [],
      total: 0,
      columnsAdmin: [
        {
          type: "selection",
          width: 60,
          align: "center"
        },
        {
          title: "序号",
          align: "center",
          key: "id"
        },
        {
          title: "参评项目名称",
          align: "center",
          key: "name"
        },
        {
          title: "参评单位/人名称",
          align: "center",
          render: (h, params) => {
            let text = params.row.regInfo.name;
            return h("div", text);
          }
        },
        {
          title: "参评类别",
          align: "center",
          render: (h, params) => {
            let text = params.row.type;
            if (params.row.subType) {
              text += " - " + params.row.subType;
            }
            return h("div", text);
          }
        },
        {
          title: "最终得分",
          align: "center",
          key: "finalScore",
          width: 100
        },
        {
          title: "核准结果",
          align: "center",
          key: "finalStatus",
          render: (h, params) => {
            let s = params.row.finalStatus;
            let text = "待核准";
            switch(s){
              case "APPROVED":
                text = "已核准";
                break;
              // case "FINAL":
              //   text = "进入终审";
              //   break;
              case "DOUBT":
                text = "疑义";
                break;
              default:
                text = "待核准";
                break;
            }
            return h("div", text);
          }
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          width: 100,
          render: (h, params) => {
            let btn0 = h(
                "Button",
                {
                  props: {
                    type: "text",
                    size: "small"
                  },
                  class: "textBtn",
                  on: {
                    click: () => {
                      this.changeStatus(params.index, "CHECKING");
                    }
                  }
                },
                "待核准"
              ),
              btn1 = h(
                "Button",
                {
                  props: {
                    type: "text",
                    size: "small"
                  },
                  class: "textBtn",
                  on: {
                    click: () => {
                      this.changeStatus(params.index, "APPROVED");
                    }
                  }
                },
                "核准"
              );
              // btn2 = h(
              //   "Button",
              //   {
              //     props: {
              //       type: "text",
              //       size: "small"
              //     },
              //     class: "textBtn",
              //     on: {
              //       click: () => {
              //         this.changeStatus(params.index, "FINAL");
              //       }
              //     }
              //   },
              //   "进入终审"
              // );
              
            let status = params.row.finalStatus, score = params.row.finalScore
            if(score){
              if(status=='CHECKING'){
                return h("div", [btn1]);
              }else if(status=='APPROVED'){
                return h("div", [btn0]);
              }else if(status=='FINAL'){
                return h("div", [btn0, btn1]);
              }   
            }else{
              return h("div", '请等待评委打分')
            }
            // return h("div", [btn1]);
          }
        }
      ],
      columnsSuper: [
        {
          type: "selection",
          width: 60,
          align: "center",
          fixed: "left"
        },
        // {
        //   title: "序号",
        //   align: "center",
        //   key: "id",
        //   fixed: "left"
        // },
        {
          title: "参评项目名称",
          align: "center",
          key: "name",
          fixed: "left"
        },
        {
          title: "参评单位/人名称",
          align: "center",
          fixed: "left",
          render: (h, params) => {
            let text = params.row.regInfo.name;
            return h("div", text);
          }
        },
        {
          title: "参评类别",
          align: "center",
          fixed: "left",
          render: (h, params) => {
            let text = params.row.type;
            if (params.row.subType) {
              text += " - " + params.row.subType;
            }
            return h("div", text);
          }
        },
        {
          title: "已分配评审数",
          align: "center",
          width: 80,
          key: "judgeFinalCount"
        },
        {
          title: "专家评审打分",
          align: "center",
          render: (h, params) => {
            let list = params.row.scoreList,
              rowList = [];
            // list = [
            //   { name: "评委一", score: 96 },
            //   { name: "评委二", score: 90 },
            //   { name: "评委三", score: 96 },
            //   { name: "评委四", score: 96 },
            //   { name: "评委五", score: 96 },
            //   { name: "评委六", score: 96 },
            //   { name: "评委七", score: 96 }
            // ];
            for (let i = 0, l = list.length; i < l; i++) {
              let name = h("p", list[i].userName),
                score = h("p", list[i].totalScore);
              // if(params.row.finalStatus=='CHECKING'){
                score = h("p", {
                  class: "scoreBtn",
                  on: {
                    click: () => {
                      this.showNoteEdit(params.index, params.row, i);
                    }
                  }
                }, list[i].totalScore>1 ? list[i].totalScore : '-')
              // }
              let column = h("span", { class: "tableSpan" }, [name, score]);
              rowList.push(column);
            }
            return h("div", { class: "tableRow myBar" }, rowList);
          }
        },
        {
          title: "最终得分",
          align: "center",
          key: "finalScore",
          fixed: "right",
          width: 70
        },
        {
          title: "核准结果",
          align: "center",
          key: "finalStatus",
          fixed: "right",
          width: 100,
          render: (h, params) => {
            let s = params.row.finalStatus;
            let text = "待核准";
            switch(s){
              case "APPROVED":
                text = "已核准";
                break;
              // case "FINAL":
              //   text = "进入终审";
              //   break;
              case "DOUBT":
                text = "疑义";
                break;
              default:
                text = "待核准";
                break;
            }
            return h("div", text);
          }
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          fixed: "right",
          width: 100,
          render: (h, params) => {
            let btn0 = h(
                "Button",
                {
                  props: {
                    type: "text",
                    size: "small"
                  },
                  class: "textBtn",
                  on: {
                    click: () => {
                      this.changeStatus(params.index, "CHECKING");
                    }
                  }
                },
                "待核准"
              ),
              btn1 = h(
                "Button",
                {
                  props: {
                    type: "text",
                    size: "small"
                  },
                  class: "textBtn",
                  on: {
                    click: () => {
                      this.changeStatus(params.index, "APPROVED");
                    }
                  }
                },
                "核准"
              );
              // btn2 = h(
              //   "Button",
              //   {
              //     props: {
              //       type: "text",
              //       size: "small"
              //     },
              //     class: "textBtn",
              //     on: {
              //       click: () => {
              //         this.changeStatus(params.index, "FINAL");
              //       }
              //     }
              //   },
              //   "进入终审"
              // );
            let status = params.row.finalStatus, score = params.row.finalScore
            if(score){
              if(status=='CHECKING'){
                return h("div", [btn1]);
              }else if(status=='APPROVED'){
                return h("div", [btn0]);
              }else if(status=='FINAL'){
                return h("div", [btn0, btn1]);
              }   
            }else{
              return h("div", '请等待评委打分')
            }
            // return h("div", [btn0, btn1, btn3]);
          }
        }
      ],
      columns: []
    };
  },
  mounted() {
    if (this.userInfo.accountType == "CONTESTSUPERADMIN" || this.userInfo.competitionUser && this.userInfo.competitionUser.permissions.indexOf('super')>=0) {
      this.columns = this.columnsSuper;
    } else {
      this.columns = this.columnsAdmin;
    }
    this.getList();
  },
  methods: {
    // 选择参评类别
    changeType(val){
      if(val==undefined || val=='建筑应用创新综合类'){
        this.form.subType = null
      }
    },
    // 确认修改得分
    showNoteEdit(i, info, judgeIndex) {
      this.judgeIndex = judgeIndex
      this.index = i;
      this.info = info;
      this.judgeId = info.scoreList[judgeIndex].userId;
      let _this = this;
      this.$Modal.confirm({
        title: "修改得分",
        content: `您确定要修改评委 “${info.scoreList[judgeIndex].userName}” 为项目 “${info.name}” 的打分吗？`,
        onOk: function() {
          _this.isEdit = true;
        }
      });
    },
    // 修改得分
    editScore() {
      // this.$Message.info('功能正在开发中，请耐心等待...')
      if (this.newScore || this.newScore == 0) {
        this.submitting = true;
        ResultService.changeScoreProject({projectId: this.info.id, judgeId: this.judgeId, newScore: this.newScore, stage: "FINAL"})
          .then(res => {
            if (res.errorNo == 0) {
              // this.info.finalScore = this.newScore;
              this.getList();
              // 请求发送成功返回后执行
              this.submitting = false;
              this.hideEditScore();
              this.$Message.success("操作成功");
            } else {
              this.$Message.error(res.errorMessage);
            }
          })
          .catch(() => {
            this.$Message.error("连接服务器失败，请稍后再试");
          });
      } else {
        this.$Message.info("请输入项目得分");
      }
    },
    // 隐藏修改得分输入框
    hideEditScore() {
      this.index = null;
      this.info = {};
      this.isEdit = false;
      this.newScore = null;
    },
    // 批量操作
    showTogether() {
      if (this.selection.length > 0) {
        this.isTogether = true;
      } else {
        this.$Message.info("请先选择要操作的内容");
      }
    },
    changeStatus(i, status) {
      // console.log(status);
      ResultService.changeStatusProject({id: this.list[i].id, status, stage: "FINAL"})
        .then(res => {
          if (res.errorNo == 0) {
            this.list[i].finalStatus = status;
            this.$Message.success("操作成功");
          } else {
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    },
    changeStatusAll(status) {
      // console.log(status);
      let ids = [];
      for (let i = 0; i < this.selection.length; i++) {
        ids[i] = this.selection[i].id;
      }
      let condition = {ids, status, stage: "FINAL"}
      this.submitting = true;
      ResultService.changeStatusAllProject(condition)
        .then(res => {
          if (res.errorNo == 0) {
            this.getList();
            this.submitting = false;
            this.isTogether = false;
            this.$Message.success("操作成功");
          } else {
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    },
    // 导出数据
    showExport() {
      if (this.selection.length > 0) {
        this.isExport = true;
      } else {
        this.$Message.info("请先选择要导出的数据");
      }
    },
    hideExport() {
      this.isExport = false;
    },
    exportData() {
      this.$Message.info("相关功能正在加紧开发中...");
    },
    // 显示全部数据
    resetForm() {
      this.form = {};
      this.currentPage = 1;
      this.getList();
    },
    // 获取列表
    getList() {
      this.loading = true;
      let condition = {
        page: this.currentPage - 1,
        size: 30,
        sort: this.form.sort || "id,desc",
        "name.contains": this.form.name || null,
        "regName.contains": this.form.regName || null,
        "pushCompany.eq": this.form.pushCompany || null,
        "firstStatus.eq": "FINAL",
        "finalStatus.eq": this.form.finalStatus || null,
        "stage.eq": "FINAL",
        "checkStatus.eq": "SUCCESS",
        "type.eq": this.form.type || null,
        "subType.eq": this.form.subType || null
      };
      this.selection = [];
      ResultService.getProjectResult(condition)
        .then(res => {
          if (res.errorNo == 0) {
            this.currentPage == 1 && (this.total = res.totalSize);
            this.list = res.data;
            if (this.total > 0 && this.form.finalStatus) {
              this.show = true;
            } else {
              this.show = false;
            }
          } else {
            this.$Message.error(res.errorMessage);
          }
          this.loading = false;
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
          this.loading = false;
        });
    },
    // 查询
    search() {
      this.currentPage = 1;
      this.getList();
    },
    changePage(p) {
      this.currentPage = p;
      this.getList();
      document.getElementsByClassName("pageWrap")[0].scrollTop = 0;
    },
    // 监测表格中内容选中变化
    selectionChange(data) {
      this.selection = data;
    },
    // 根据参评单位类型赋值证明类型
    setCertType(val) {
      if (val == "个人") {
        this.nameLabel = "参评个人姓名";
      } else if (val == "院校或科研机构") {
        this.nameLabel = "参评单位名称";
      } else if (val == "企业") {
        this.nameLabel = "参评单位名称";
      } else {
        this.nameLabel = "参评单位名称";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.ivu-input-number {
  width: 100%;
}
.succBtn {
  background: #277a44;
}
.dataBtn {
  background: #eeeeee;
  color: #3f4641;
  margin: 10px 0 10px 15px;
}
.center {
  text-align: center;
}
.btnWrap {
  text-align: center;
  padding: 30px 0;
}
.modalBtn {
  color: #fff;
  margin: 0 10px;
}
.failedBtn {
  background: #ed0606;
}
</style>