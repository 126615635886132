let baseUrl = '',
	imgBaseUrl = '',
	fileBaseUrl = '',
	// websiteBaseUrl = '',
	officialWebsiteUrl = ''

if (process.env.NODE_ENV == 'development') {
	baseUrl = 'http://localhost:8080/api/'
	// baseUrl = 'https://www.cbmea.com/api/'
	imgBaseUrl = 'https://files.cbmea.com/'
	fileBaseUrl = 'https://files.cbmea.com/'
	// websiteBaseUrl = 'http://localhost:8083/#/'
	officialWebsiteUrl = 'http://localhost:8081/#/'
} else if (process.env.NODE_ENV == 'production') {
	baseUrl = 'https://www.cbmea.com/api/'
	imgBaseUrl = 'https://files.cbmea.com/'
	fileBaseUrl = 'https://files.cbmea.com/'
	// websiteBaseUrl = 'http://dstar.ddfchina.com/#/'
	officialWebsiteUrl = 'https://www.cbmea.com/#/'
}

export {
	baseUrl,
	imgBaseUrl,
	fileBaseUrl,
	// websiteBaseUrl,
	officialWebsiteUrl
}