  /*
   * Copyright(c) 2021 architectural award, All Rights Reserved.
   * Author: lxj
   */
  import fetch from '@/utils/fetch';

  export default {
    // 获取报名信息
    getCompetitionRegistration: params => {
      return fetch({
        url: 'inquiry/competition-registration',
        method: 'get',
        params
      })
    },
    // 获取报名信息
    getRegistrationInfo: id => {
      return fetch({
        url: `inquiry/competition-registration/${id}`,
        method: 'get'
      })
    },
    // 报名审核
    checkRegistration: data =>{
      return fetch({
        url: 'competition-registration/check',
        method: 'put',
        data
      })
    },
    // 批量报名审核
    checkAllRegistration: data =>{
      return fetch({
        url: 'competition-registration/check-all',
        method: 'put',
        data
      })
    },
    // 获取参赛作品列表
    getProjects: params => {
      return fetch({
        url: 'inquiry/competition-projects',
        method: 'get',
        params
      })
    },
    // 获取项目信息
    getProjectInfo: id => {
      return fetch({
        url: `inquiry/competition-projects/${id}`,
        method: 'get'
      })
    },
    // 项目审核
    checkProject: data =>{
      return fetch({
        url: 'competition-projects/check',
        method: 'put',
        data
      })
    },
    // 项目审核
    checkAllProject: data =>{
      return fetch({
        url: 'competition-projects/check-all',
        method: 'put',
        data
      })
    },
    // // 删除单个参赛作品
    // deleteOneProject: id => {
    //   return fetch({
    //     url: `competition-projects/${id}`,
    //     method: 'delete'
    //   })
    // },
  }