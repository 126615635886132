  /*
   * Copyright(c) 2021 architectural award, All Rights Reserved.
   * Author: lxj
   */
  import fetch from '@/utils/fetch';

  export default {
    // 获取项目评分列表
    getProjectResult: params => {
      return fetch({
        url: 'inquiry/competition-projects',
        method: 'get',
        params
      })
    },
    // 项目发布
    publishProject: id =>{
      return fetch({
        url: `competition-projects/publish/${id}`,
        method: 'put'
      })
    },
    // 项目批量发布
    publishAllProject: data =>{
      return fetch({
        url: 'competition-projects/publish-all',
        method: 'put',
        data
      })
    },
    // 项目核准
    changeStatusProject: data =>{
      return fetch({
        url: `competition-projects/stage-status-change`,
        method: 'put',
        data
      })
    },
    // 项目批量核准
    changeStatusAllProject: data =>{
      return fetch({
        url: 'competition-projects/stage-status-change-all',
        method: 'put',
        data
      })
    },
    // 修改项目得分
    changeScoreProject: data =>{
      return fetch({
        url: `competition-score/stage-score-change`,
        method: 'post',
        data
      })
    },
  }