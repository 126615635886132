  /*
   * Copyright(c) 2021 architectural award, All Rights Reserved.
   * Author: lxj
   */
  import fetch from '@/utils/fetch';

  export default {
    // 登录
    login: data => {
      return fetch({
        url: 'manage/login',
        method: 'post',
        data
      })
    },
    // 获取后台用户信息
    getCompetitionUser: params => {
      return fetch({
        url: 'inquiry/competition/user',
        method: 'get',
        params
      })
    },
    // 添加/修改后台用户信息
    saveCompetitionUser: data => {
      return fetch({
        url: 'competition/account-user/save',
        method: 'post',
        data
      })
    },
    // 删除后台用户
    deleteCompetitionUser: id => {
      return fetch({
        url: `competition/user/${id}`,
        method: 'delete'
      })
    },
    // 设置后台用户权限
    setCompetitionUserPermissions: data => {
      return fetch({
        url: '/competition/user/permissions',
        method: 'post',
        data
      })
    },
    // 重置密码
    resetPassword: data =>{
      return fetch({
        url: 'users/reset-password-admin',
        method: 'put',
        data
      })
    },
  }