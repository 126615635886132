<template>
  <div>
    <p v-if="getRouteName!='Result'" class="pageTitle">{{getStage=='FINAL'?'终审':'初审'}}项目分配结果</p>
    <div class="pageContent">
      <p v-if="getRouteName!='Result'" class="right">
        <Button @click="goBack" class="defaultBtn">返回项目分配</Button>
      </p>
      <div class="formWrap">
        <Form :model="form" :label-width="80">
          <Row :gutter="30">
            <Col span="7">
              <FormItem label="项目名称">
                <Input v-model="form.name" clearable @on-enter="searchProject"></Input>
              </FormItem>
            </Col>
            <Col span="7">
              <FormItem label="参评类别">
                <Select v-model="form.type" clearable @on-change="changeType">
                  <Option value="建筑应用创新综合类">建筑应用创新综合类</Option>
                  <Option value="建筑应用创新单项类">建筑应用创新单项类</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="7" v-show="form.type=='建筑应用创新单项类'">
              <FormItem label="" :label-width="0">
                <Select v-model="form.subType" clearable>
                  <Option value="建筑材料应用创新类">建筑材料应用创新类</Option>
                  <Option value="装配式建筑应用创新类">装配式建筑应用创新类</Option>
                  <Option value="建筑部品部件应用创新类">建筑部品部件应用创新类</Option>
                  <Option value="设备设施应用创新类">设备设施应用创新类</Option>
                  <Option value="其它应用创新类">其它应用创新类</Option>
                </Select>
              </FormItem>
            </Col>
            <Col span="3" align="right">
              <Button class="defaultBtn" :loading="loading" @click="searchProject">筛选项目</Button>
            </Col>
          </Row>
        </Form>
      </div>
      <div class="line">
        <Table :data="list" :columns="columns" :loading="loading" stripe></Table>
        <div class="pageWrap">
          <Page :total="total" :page-size="30" :current="currentPage" show-total @on-change="changePage"></Page>
        </div>
      </div>
    </div>
    <Modal v-model="isShow" title="评委分配" :width="70" footer-hide :mask-closable="false">
      <JudgeDistribution v-if="isShow" :hide="showJudgeList" :level="getStage" :project="projectInfo" @Update="getList" />
    </Modal>
  </div>
</template>

<script>
import ResultService from "@/api/result";
import JudgeDistribution from '@/components/result/Judge.vue'
export default {
  components: { JudgeDistribution },
  data() {
    return {
      form: {},
      loading: false,
      currentPage: 1,
      list: [],
      total: 0,
      columns: [
        {
          title: "项目编号",
          align: "center",
          key: "projectSn",
          width: 100
        },
        {
          title: "参评项目名称",
          align: "center",
          key: "name"
        },
        {
          title: "参评类别",
          align: "center",
          render: (h, params) => {
            let text = params.row.type;
            if (params.row.subType) {
              text += " - " + params.row.subType;
            }
            return h("div", text);
          }
        },
        {
          title: "已分配评委",
          align: "center",
          render: (h, params) => {
            let list = params.row.judgeList || [],
              rowList = [];
            if(list.length==0){
              return h("div", '-')
            }
            for (let i = 0, l = list.length; i < l; i++) {
              let name = h("p", list[i].judgeName),
                // score = h("p", list[i].totalScore),
                // column = h("span", { class: "tableSpan" }, [name, score]);
                column = h("span", { class: "tableSpan" }, [name]);
              rowList.push(column);
            }
            return h("div", { class: "tableRow myBar" }, rowList);
          }
        },
        {
          title: "操作",
          key: "action",
          align: "center",
          width: 80,
          render: (h, params) => {
            let btn1 = h(
                "Button",
                {
                  props: {
                    type: "text",
                    size: "small"
                  },
                  class: "textBtn",
                  on: {
                    click: () => {
                      this.showJudgeList(params.index);
                    }
                  }
                },
                "编辑"
              )
              return h("div", [btn1]);
          }
        }
      ],

      isShow: false,
      projectInfo: {}
    };
  },
  mounted() {
    this.getList();
  },
  computed:{
    getStage(){
      return this.$route.query.stage || 'FIRST'
    },
    getRouteName(){
      return this.$route.name
    }
  },
  methods: {
    // 选择参评类别
    changeType(val){
      if(val==undefined || val=='建筑应用创新综合类'){
        this.form.subType = null
      }
    },
    // 搜索项目
    searchProject(){
      this.currentPage = 1
      this.getList()
    },
    // 显示评委列表
    showJudgeList(index){
      if(this.isShow){
        this.projectInfo = {}
        this.isShow = false
      }else{
        this.projectInfo = this.list[index]
        this.isShow = true
      }
    },
    // 返回上一页
    goBack(){
      this.$router.go(-1);
    },
    // 获取列表
    getList() {
      this.loading = true;
      let firstStatus = null;
      if(this.getStage == "FINAL"){
        firstStatus = "FINAL";
      }
      let condition = {
        page: this.currentPage - 1,
        size: 30,
        sort: "id,desc",
        "name.contains": this.form.name || null,
        "type.eq": this.form.type,
        "subType.eq": this.form.subType,
        "firstStatus.eq": firstStatus,
        "stage.eq": this.getStage,
        "checkStatus.eq": "SUCCESS",
      };
      ResultService.getProjectResult(condition)
        .then(res => {
          if (res.errorNo == 0) {
            this.currentPage == 1 && (this.total = res.totalSize);
            this.list = res.data;
          } else {
            this.$Message.error(res.errorMessage);
          }
          this.loading = false;
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
          this.loading = false;
        });
    },
    changePage(p) {
      this.currentPage = p;
      this.getList();
      document.getElementsByClassName("pageWrap")[0].scrollTop = 0;
    }
  },
  watch:{
    $route(){
      this.getList()
    }
  }
};
</script>

<style lang="scss" scoped>
.formWrap{
  border-top: 1px solid #cccccc;
  padding-top: 24px;
}
.defaultBtn{
  margin-top: 0 !important;
}
</style>