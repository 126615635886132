  /*
   * Copyright(c) 2021 architectural award, All Rights Reserved.
   * Author: lxj
   */
  import fetch from '@/utils/fetch';

  export default {
    // 获取评委列表
    getJudges: params => {
      return fetch({
        url: 'inquiry/competition/user',
        method: 'get',
        params
      })
    },
    // 删除评委
    deleteJudge: id => {
      return fetch({
        url: `competition-judges/${id}`,
        method: 'delete'
      })
    },
    // 分配项目
    distributionProjectAll: data =>{
      return fetch({
        url: 'competition-judge-project/save-all',
        method: 'post',
        data
      })
    },
    // 给一个项目分配评委
    distributionProjectOneProject: data =>{
      return fetch({
        url: 'competition-judge-project/save-one-project',
        method: 'post',
        data
      })
    },
    // 给一个评委分配项目
    distributionProjectOneJudge: data =>{
      return fetch({
        url: 'competition-judge-project/save-one-judge',
        method: 'post',
        data
      })
    },
  }