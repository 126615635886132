<template>
<div class="judgeDistribution">
  <p class="mgBtm">为项目 “{{project.name}}” 分配评委</p>
  <Transfer
    :data="judgeList"
    :target-keys="judgeListTarget"
    :list-style="listStyle"
    :titles="['可分配评委', '选中评委']"
    :operations="['To left','To right']"
    reverse-operation
    not-found-text="暂无数据"
    @on-change="handleJudge">
    <div class="moreBtnWrap">
      <template v-if="judgeTotal==judgeList.length">
        <span class="text">评委加载完成</span>
      </template>
      <template v-else>
        <span class="text">未加载评委数：{{judgeTotal - judgeList.length}}</span>
        <Button class="moreBtn" :loading="loadingJudge" @click="loadMoreJudge">加载更多可分配评委</Button>  
      </template>
    </div>
  </Transfer>
  <div class="btnWrap">
    <Button class="moreBtn" @click="submit" :loading="submitting">提交</Button>
  </div>
</div>
</template>

<script>
import JudgeService from "@/api/judge";
export default {
  props:['level', 'project', 'hide'],
  data(){
    return{
      listStyle: {
        width: '40%',
        height: '300px'
      },

      loadingJudge: false,
      currentPageJudge: 1,
      judgeTotal: 0,
      judgeList: [],
      judgeListTarget: [],  //要分配的评委id列表

      submitting: false
    }
  },
  mounted(){
    this.getJudgeList()
    let list = this.project.judgeList || []
    for(let i=0,l=list.length;i<l;i++){
      this.judgeListTarget.push(list[i].judgeId)
    }
  },
  methods:{
    submit(){
      // if(this.judgeListTarget.length==0){
      //   this.$Message.error('请选择要分配的评委。')
      //   return
      // }
      let condition = {
        projectId: this.project.id, 
        judgeIds: this.judgeListTarget, 
        stage: this.level
      }
      this.submitting = true;
      JudgeService.distributionProjectOneProject(condition)
        .then(res => {
          if (res.errorNo == 0) {
            // 请求成功执行
            this.submitting = false
            this.$Message.success("修改成功");
            this.hide()
            this.$emit('Update')
          } else {
            this.submitting = false
            this.$Message.error(res.errorMessage);
          }
        })
        .catch(() => {
          this.submitting = false
          this.$Message.error("连接服务器失败，请稍后再试");
        });
    },
    // 选中评委（to left / to right）
    handleJudge(target){
      this.judgeListTarget = target
    },
    // 加载更多评委
    loadMoreJudge(){
      this.currentPageJudge += 1
      this.getJudgeList()
    },
    // 获取评委列表
    getJudgeList(){
      this.loadingJudge = true;
      let condition = {
        "userType.eq": "JUDGE",
        'competitionId.eq': 0,
        page: this.currentPageJudge - 1,
        size: 60,
        sort: "id,desc",
        "level.eq": this.level
      }
      JudgeService.getJudges(condition)
        .then(res => {
          if (res.errorNo == 0) {
            let data = res.data, list = []
            for(let i=0,l=data.length;i<l;i++){
              list[i] = {key: data[i].userId, label: data[i].trueName}
            }
            if(this.currentPageJudge==1){
              this.judgeList = list
              this.judgeTotal = res.totalSize
            }else{
              this.judgeList = this.judgeList.concat(list)
            }
          } else {
            this.$Message.error(res.errorMessage)
          }
          this.loadingJudge = false
        })
        .catch(() => {
          this.$Message.error("连接服务器失败，请稍后再试");
          this.loadingJudge = false;
        });
    },
  }
}
</script>


<style lang="scss">
.judgeDistribution{
  .ivu-transfer-list-with-footer{
    padding-bottom: 45px;
  }
  .moreBtn{
    background: #277a44;
    color: #fff;
  }
  .moreBtnWrap{
    text-align: right;
    padding: 5px 10px;
    .text{
      display: inline-block;
      padding: 7px 15px 7px 0;
      color: #ccc;
    }
  }
  .btnWrap{
    text-align: center;
    padding: 50px 0 30px;
  }
  .mgBtm{
    margin-bottom: 15px;
    font-weight: bold;
  }
}
</style>
